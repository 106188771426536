import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Tooltip, UncontrolledTooltip} from "reactstrap";
import {FATTURE_FILTRO_STATO_ENUM} from "../redux/fatture_redux_types";
import moment from "moment";

const FatturaListItem = ({
                                onRowClick,
                                starred,
                                fattura: {
                                    contatto,
                                    // spedizione_tipo,
                                    // indirizzo_to,
                                    ...fattura
                                },
                                active,
                                ...props
                            }) => {


    let nome = (contatto.azienda) ? contatto.rag_sociale : `${contatto.cognome} ${contatto.nome}`

    let stato = FATTURE_FILTRO_STATO_ENUM.getStato(fattura.stato);
    let color = stato.color;
    let tooltip_stato = `${stato.label.toUpperCase()}`;

    if (fattura.stato === 0 && !!!fattura.pagata) {
        color = "warning";
        tooltip_stato+=" Non pagata"
    }
    else if (fattura.stato === 0 && fattura.pagata) {
        color = "success";
        tooltip_stato+=" e pagata"
    }

    return (
        <tr className={((active === fattura.id) ? "bg-light" : "")}>

            <td onClick={()=> {
                fattura.contatto = contatto;
                onRowClick(fattura)
            }} style={{width: 50 + 'px'}}>
                <a className={`btn-circle btn btn-${color}`} id={`btn-stato-${fattura.id}`} href="#">{stato.code}</a>
                <UncontrolledTooltip placement="right" target={`btn-stato-${fattura.id}`}>
                    {tooltip_stato}
                </UncontrolledTooltip>
            </td>
            <td onClick={()=> {
                fattura.contatto = contatto;

                onRowClick(fattura)
            }}>
                <p className="mb-0">
                    <b>{moment(fattura.createdAt).format("DD/MM/YYYY")}</b> - #{fattura.id} - {(fattura.tipo===0)?'Addebito':'Accredito'}<br/>
                    <b>{fattura.importo}€</b> - {nome}
                </p>
            </td>
            <td className="text-center" style={{width: 70 + 'px'}}>

            </td>
        </tr>
    );
}


FatturaListItem.propTypes = {
    fattura: PropTypes.object.isRequired,
    onRowClick: PropTypes.func.isRequired
};

export default FatturaListItem;
