import React, {Component, Fragment, useState} from "react";

import {connect} from "react-redux";
import {fetching} from "../../../redux/session/action";
import FiltroFattureDashboard from "../../fatture/filtri/FiltroFattureDashboard";
import SettingsMenu from "./SettingsMenu";
import SettingsMailer from "./SettingsMailer";


const SettingsHome = ({...props}) => {

    const [showLeftPart, setShowLeftPart] = useState(true);
    const [showRightPart, setShowRightPart] = useState(false);
    const [sezione, setSezione] = useState(null);


    const onChangeMenu = (sezione) => {
        if(sezione){
            console.log("sezione",sezione);
            setSezione(sezione);
            setShowRightPart(true);

        }else
            setShowRightPart(false);
    }
    return (<Fragment>
        <div className="contact-app">
            <div className={"left-part bg-white " + (showLeftPart === true ? "show-panel" : "")}>
                <SettingsMenu onChangeMenu={onChangeMenu}/>
            </div>
            <div className="right-part contact-list bg-white p-3">

                {(showRightPart) ? <Fragment>
                    {(sezione==="mailer")?<SettingsMailer/>:''}
                </Fragment> : <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                    <i className="far fa-address-card display-5"></i>
                    <h4 className="mt-2">Clicca sul menu per caricare le impostazioni .</h4>
                </div>}


            </div>
        </div>

    </Fragment>);

}


const mapStateToProps = (state, ownProps) => ({});


const mapDispatchToProps = {}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsHome);
