import React, {Fragment, useEffect, useState} from "react";
import {deleteContatto, getDettagliContatto, getAcquisti} from "../../redux/action";
import {connect} from "react-redux";
import {Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import img1 from "../../../../assets/images/users/1.jpg";
import Iframe from "react-iframe";
import {searchSpedizioni, setFiltroContattoPerSpedizioni} from "../../../spedizioni/redux/action";
import SpedizioniHome from "../../../spedizioni/views/SpedizioniHome/SpedizioniHome";


const ContattoSpedizioni = ({ contatto,filtro,getDettagliContatto,  setFiltroContattoPerSpedizioni, searchSpedizioni, ...props}) => {
    const contatto_id=props.match.params.contatto_id;
    const filtrojson = JSON.stringify(filtro);
    console.log("contatto",contatto)

    useEffect(()=>{
        setFiltroContattoPerSpedizioni(contatto_id);
        getDettagliContatto(contatto_id);


    },[]);
    useEffect(()=>{
        searchSpedizioni();

    },[filtro]);


    return <Fragment>
        <div>{contatto.cognome} {contatto.nome} {contatto.rag_sociale} {contatto.citta}</div>
        <SpedizioniHome/>
    </Fragment>



}



const mapStateToProps = (state, ownProps) => ({
    // acquisti: state.contactReducer.acquisti
    contatto: state.contactReducer.contactsDetails,
    spedizioni: state.spedizioni.spedizioni,
    filtro: state.spedizioni.filtro
});

const mapDispatchToProps = {
    // onEditClick: () => dispatch(setEditContactFlag()),
    getDettagliContatto,
    setFiltroContattoPerSpedizioni,
    searchSpedizioni
    // deleteContatto
    // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContattoSpedizioni);
