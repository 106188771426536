import {listiniVisiblityFilter} from "./actions";
import {SET_VISIBILITY_FILTER_LISTINI} from "./_constant";

const ListiniVisiblityFilter = (state = listiniVisiblityFilter.SHOW_ALL, action) => {
	switch (action.type) {
		case SET_VISIBILITY_FILTER_LISTINI:
			return action.filter
		default:
			return state
	}
}

export default ListiniVisiblityFilter;
