import {connect} from "react-redux";
import React from "react";
import {Table} from "reactstrap";
import PropTypes from "prop-types";
import PaginationCustom from "../../commons/Pagination";
import {aggiornaFattura, setFiltroFattureLoadPage} from "../redux/action";
import FatturaListItem from "./FatturaListItem";


const FattureList = ({
                          active, fatture, filtro, setFiltroFattureLoadPage,
    aggiornaFattura,
                          onSelectFattura,
                          ...state
                      }) => {
    const a = (value) => {
        setFiltroFattureLoadPage(value)
        console.log("a", value)
    }

    return (
        <div className="table-responsive contact-list">
            {
                filtro.pagination_count > 0 ? <div>
                    <PaginationCustom
                        handleClick={a}
                        pagesCount={Math.ceil(filtro.pagination_count / filtro.pagination_limit)}
                        currentPage={filtro.pagination_page}
                        totali={filtro.pagination_count}
                        limite={filtro.pagination_limit}
                    />
                </div> : <div className="bg-light p-2">"Nessuna fattura trovato"</div>
            }

            <Table className="table contact-table no-wrap table-hover v-middle mb-0">
                <tbody>
                {fatture.map(fattura => {
                        return <FatturaListItem
                            key={fattura.id}
                            active={active}
                            fattura={fattura}

                            onStarredClick={() => {
                                fattura.starred=!fattura.starred
                                aggiornaFattura(fattura);
                            }}

                            onRowClick={onSelectFattura}
                        />
                    }
                )}
                </tbody>
            </Table>

            <hr/>
            {
                filtro.pagination_count > 0 ? <div>
                    <PaginationCustom
                        handleClick={a}
                        pagesCount={Math.ceil(filtro.pagination_count / filtro.pagination_limit)}
                        currentPage={filtro.pagination_page}
                        totali={filtro.pagination_count}
                        limite={filtro.pagination_limit}
                    />
                </div> : ''
            }


        </div>
    )
};

FattureList.prototype = {
    fatture: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            nome: PropTypes.string.isRequired,
            cognome: PropTypes.string,
            image: PropTypes.string,
            phone: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            address: PropTypes.string,
            notes: PropTypes.string,
            frequentlycontacted: PropTypes.bool.isRequired,
            starred: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired,
    toggleStarredContact: PropTypes.func.isRequired,
    deleteContact: PropTypes.func.isRequired,
    onSelectFattura: PropTypes.func.isRequired
};


const mapStateToProps = (state, ownProps) => ({
    fatture: state.fatture.fatture,
    active: state.contactReducer.contactsDetails,
    filtro: state.fatture.filtro,
});


const mapDispatchToProps = {
    setFiltroFattureLoadPage,
    aggiornaFattura
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FattureList);
