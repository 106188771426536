import { connect } from "react-redux";
// import Search from "../components/Search";
import React from "react";
import {listExtraSearch, listiniExtraFetch} from "../../redux/actions";



const FilterSearchContainer = ({ value, onChange , listExtraSearch, listiniExtraFetch}) => (
    <input type="text" className="form-control" placeholder="Search listini" onChange={(e) => {
        console.log("eeeeee", e.target.value)
        listExtraSearch(e.target.value);
        listiniExtraFetch()

    }} defaultValue={value} />
);

FilterSearchContainer.propTypes = {
   // onChange: PropTypes.func.isRequired
};



const mapStateToProps = (state) => ({
   value: state.listextra.listiniSearch
});

// const mapDispatchToProps = (dispatch) => ({
//    onChange: value => dispatch(contactsSearch(value)),
// });
const mapDispatchToProps = {
    listExtraSearch,
    listiniExtraFetch
   // onChange: value => {
   //    contactsSearch(value);
   //
   //
   //
   //
   //
   // },
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(FilterSearchContainer);
