// import defaul_profile_img from "../../assets/images/users/1.jpg";
import {
    DELETE_LISTINO, EDIT_LISTINO,
    FILTER_LISTINI,
    LISTINI_RELOADED,
    LISTINO_DETAILS, RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO,
    SET_VISIBILITY_FILTER_LISTINI, TIPO_SPEDIZIONI_PER_LISTINI_RELOADED,
    TOGGLE_STARRED_LISTINO, UPDATE_LISTINO
} from "./_constant";
import {CONTATTI_LAZY_LOADING, SAGA_CONTATTI_SEARCH} from "../../contatti/redux/contatti_redux_types";
import {
    LISTINI_LAZY_LOADING,
    SAGA_LISTINI_PACK_ADD,
    SAGA_LISTINI_PACK_JSON,
    SAGA_LISTINI_PACK_SEARCH,
    SAGA_LISTINI_PACK_TIPOLOGIA_ADD,
    SAGA_LISTINI_PACK_TIPOLOGIA_LIST,
    SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA,
    SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE
} from "./listini-redux-types";
import listiniVisibilityFilter from "./listiniVisibilityFilter";
import {SAGA_LISTINI_EXTRA_JSON} from "../../listini_extra/redux/listini-extra-redux-types";

export const listiniVisiblityFilter = {
    SHOW_ALL: "SHOW_ALL",
    TIPOLOGIA_LISTINI_POSTPAGATI: "TIPOLOGIA_LISTINI_POSTPAGATI",
    TIPOLOGIA_LISTINI_PREPAGATI: "TIPOLOGIA_LISTINI_PREPAGATI",
    FREQUENT_CONTACT: "FREQUENT_CONTACT",
    STARRED_PACK: "STARRED_PACK",
    ENGINEERING_DEPARTMENT_CONTACT: "ENGINEERING_DEPARTMENT_CONTACT",
    SALES_DEPARTMENT_CONTACT: "Sales",
    SUPPORT_DEPARTMENT_CONTACT: "SUPPORT_DEPARTMENT_CONTACT",
    SEARCH_CONTACT: "SEARCH_CONTACT",
    DELETED_CONTACT: "DELETED_CONTACT"
};


export const getTipoSpedizioniPerListino = (id_listino) => {
    return (dispatch, getState, {getFirestore}) => {
        console.log("estraggo le spedizioni per listino:", id_listino);

        const state = getState();
        //BECCATO DA SAGA
        dispatch({
            type: SAGA_LISTINI_PACK_TIPOLOGIA_LIST,
            payload: {
                listino_pack_ref: id_listino,
            }
        })


    }
}

export const listiniFetch = (lazy = false) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        if (lazy) {
            dispatch({
                type: LISTINI_LAZY_LOADING,
            });
        } else {
            dispatch({
                type: LISTINI_LAZY_LOADING,
                reset: true
            })
        }
        let l = (lazy) ? state.listini.lazy + 1 : state.listini.lazy;

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_LISTINI_PACK_SEARCH,
            payload: {
                search: state.listini.listiniSearch,
                filtro: state.listini.listiniVisibilityFilter,
                lazy: l,
            }
        })


    }
}

export const listiniPackGetJson = () => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: SAGA_LISTINI_PACK_JSON,
        });
    }
};

export const getListini = () => {
    return (dispatch, getState, {getFirestore}) => {
        let state = getState();
        let fstore = getFirestore();


        // devo far in modo che ci sia una action che viene richiamata quando c'e' un evento tipo:
        // - cambio search string
        // - cambio link search
        //
        // gli eventi sono:
        // - ricarica lista contatti
        // - aggiungi alla lista contatti
        //
        // ricarica lista contatti deve essere richiamata all'avvio della pagina o quando cambia un parametro nella ricerca
        //     ricarica lista  prendera' come parametri il valore di search e del link di ricerca


        //TODOGP recuperare eventuali filtri sui contatti
        // console.log("carico i contatti")
        // console.log("state.contactReducer.contact")


        let _listini = [];

        let query = fstore.collectionGroup('listini')
            // .where('azienda_ref', '==', state.session.azienda.id)
        ;
        if (state.listini.listiniSearch !== "") {
            query = query.where("nome", '>=', state.listini.listiniSearch.toLowerCase())
                .where("nome", '<=', state.listini.listiniSearch + "z")
        }

        if (state.listini.listiniVisibilityFilter == listiniVisiblityFilter.STARRED_PACK) {
            query = query.where("starred", '==', true)
        }


        // console.info("state.contactReducer.contactsSearch", state.contactReducer.contactsSearch);

        query
            .orderBy("nome", "asc")
            .get().then((r) => {
            r.docs.forEach(doc => {
                // console.log(doc.id)
                let d = doc.data();

                _listini.push({
                    id: doc.id,
                    nome: d.nome,
                });
            });

// console.log(_contacts)
            dispatch({
                type: LISTINI_RELOADED,
                listini: _listini
            });


        }).catch(e => {
            console.error(e)
            dispatch({
                type: LISTINI_RELOADED,
                listini: []
            });

        });


    }
}


export const riordinaTipoSpedizioni = (tipoSpedizioni) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        let tipologie_pack_riordinate = []
        let tipologie_pack_da_riordinare = [];
        tipoSpedizioni.forEach((o, i) => {
            let ordinamento = i + 1;
            o.ordinamento = ordinamento;
            tipologie_pack_riordinate.push(o);

            tipologie_pack_da_riordinare.push({
                id: o.id,
                ordinamento: o.ordinamento
            })
        });


        dispatch({
            type: SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA,
            payload: {tipologie_pack: tipologie_pack_da_riordinare}
        })

        dispatch({type: RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO, tipologie_pack: tipologie_pack_riordinate})


    }
}


export const addListino = (listino) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_LISTINI_PACK_ADD,
            payload: {
                listino_pack: listino,
            }
        });

    }


}


export const addTipologiaPack = (tipologia_pack) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_LISTINI_PACK_TIPOLOGIA_ADD,
            payload: {
                tipologia_pack,
            }
        });

    }


}

export const modificaTipologiaPack = (tipologia_pack) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE,
            payload: {
                tipologia_pack,
            }
        });

    }


}

export const aggiornaListino = (id, values, flag) => {
    // console.log(values)

    return (dispatch, getState, {getFirestore}) => {


        let state = getState();
        let fstore = getFirestore();


        let key = state.listini.listini.findIndex((e) => e.id == id);


        let contatto_in_edit = Object.assign({}, state.listini.listini[key]);


        delete contatto_in_edit.id;
        delete contatto_in_edit.image;
        console.log("contatto_in_edit", contatto_in_edit);


        // console.log("contatto", state.contactReducer.contact)
        fstore.collection(
            // "/aziende/" + state.session.azienda.id +
            "/listini").doc(id).update(contatto_in_edit).then(r => {
            console.log(r)

        });


        dispatch({
            type: EDIT_LISTINO,
            flag
        });

    }

};

export const setEditListinoFlag = flag => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: EDIT_LISTINO,
            flag
        })
    }
};
export const updateListino = (id, field, value) => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: UPDATE_LISTINO,
            id: id,
            field: field,
            value: value
        });

    };
}


export const listiniSearch = searchTerm => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: FILTER_LISTINI,
            payload: searchTerm
        })

    }


};

export const setListiniVisibilityFilter = (filter) => {
    return (dispatch, getState, {getFirestore}) => {
        // console.log("camgiato", filter);


        dispatch({
            type: SET_VISIBILITY_FILTER_LISTINI,
            filter
        })


    }
}

export const listinoDetails = id => ({
    type: LISTINO_DETAILS,
    id
});

export const toggleStarredListino = id => ({
    type: TOGGLE_STARRED_LISTINO,
    id
});

export const deleteListino = id => ({
    type: DELETE_LISTINO,
    id
});
