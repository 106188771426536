export const LIST_EXTRAS_RELOADED = 'LIST_EXTRAS_RELOADED';
export const LIST_EXTRA_DETAILS = 'LIST_EXTRA_DETAILS';
export const TOGGLE_STARRED_LISTEXTRA = 'TOGGLE_STARRED_LISTEXTRA';
export const DELETE_LIST_EXTRA = 'DELETE_LIST_EXTRA';
export const SET_VISIBILITY_FILTER_LIST_EXTRAS = 'SET_VISIBILITY_FILTER_LIST_EXTRAS';
export const FILTER_LIST_EXTRAS = 'FILTER_LIST_EXTRAS';
export const ADD_LIST_EXTRA = 'ADD_LIST_EXTRA';
export const EDIT_LIST_EXTRA = 'EDIT_LIST_EXTRA';
export const UPDATE_LIST_EXTRA = 'UPDATE_LIST_EXTRA';
export const TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED = 'TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED';
export const ADD_TIPO_EXTRA_A_LIST_EXTRA = 'ADD_TIPO_EXTRA_A_LIST_EXTRA';
export const UPDATE_TIPO_EXTRA_A_LIST_EXTRA = 'UPDATE_TIPO_EXTRA_A_LIST_EXTRA';
export const RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA = 'RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA';
