import { connect } from "react-redux";
import {
   getAcquisti,
   getIndirizzi,
   resetContatto,
   setEditContactFlag2,
   updateContact2
} from "../../redux/action";
import creaSpedComp from "../components/creaSpedizioneComponent";
import {getTipoSpedizioniPerListino} from "../../../listini_pack/redux/actions";
import {getTipoExtraPerListExtra} from "../../../listini_extra/redux/actions";
import {fetchCorrieri} from "../../../corrieri/redux/action";
import {creaSpedizione} from "../../../spedizioni/redux/action";


const mapStateToProps = (state, ownProps) => ({
   editContactFlag: state.contactReducer.editContact,
   acquisti: state.contactReducer.acquisti,
   indirizzi: state.contactReducer.indirizzi,
   indirizzo: state.contactReducer.indirizzo,
   corrieri: state.corrieri.corrieri,
   tipoSpedizioniPerListino: state.listini.tipoSpedizioniPerListino,
   tipoExtraPerListExtra: state.listextra.tipoExtraPerListExtra


});

const mapDispatchToProps = {
   // onEditClick: () => dispatch(setEditContactFlag()),
   onEditClick: setEditContactFlag2,
   onChange: updateContact2,
   fetchCorrieri,
   getAcquisti,
   getIndirizzi,
   getTipoSpedizioniPerListino,
   getTipoExtraPerListExtra,
   creaSpedizione,
   resetContatto
   // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(creaSpedComp    );
