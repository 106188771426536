import {FILTER_LIST_EXTRAS, SET_VISIBILITY_FILTER_LIST_EXTRAS} from "./_constant";

const ListextraSearch = (state = '', action) => {
	switch (action.type) {
		case FILTER_LIST_EXTRAS:
			return action.payload
		// case SET_VISIBILITY_FILTER_LIST_EXTRAS:
		// 	return state = ''
		default:
			return state
	}
}

export default ListextraSearch;
