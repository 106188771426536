import React, {Component} from 'react';
import Select from "react-select";
import user1 from '../../../../assets/images/users/1.jpg';

import {Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";

const stateOptions = [
    {value: 0, label: 'Privato'},
    {value: 1, label: 'Azienda'},
];


export default class Step1 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            azienda: props.getStore().azienda,
            nome: props.getStore().nome,
            cognome: props.getStore().cognome,
            codfisc: props.getStore().codfisc,
            rag_sociale: props.getStore().rag_sociale,
            piva: props.getStore().piva,
            citta: props.getStore().citta,
            indirizzo: props.getStore().indirizzo,
            phone: props.getStore().phone,
            email: props.getStore().email,
            image: "",
            sourceImg: user1

        };
        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms

        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;


        console.log("validate inpute", validateNewInput)

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {
            console.log(k)
            return validateNewInput[k] === true
        })) {
            if (
                this.props.getStore().azienda !== userInput.azienda,
                this.props.getStore().nome !== userInput.nome ||
                this.props.getStore().cognome !== userInput.cognome ||
                this.props.getStore().codfisc !== userInput.codfisc ||
                this.props.getStore().rag_sociale !== userInput.rag_sociale ||
                this.props.getStore().piva !== userInput.piva ||
                this.props.getStore().citta !== userInput.citta ||
                this.props.getStore().indirizzo !== userInput.indirizzo ||
                this.props.getStore().phone !== userInput.phone ||
                this.props.getStore().email !== userInput.email
            ) { // only update store of something changed
                this.props.updateStore({
                    ...userInput,
                    image: this.state.image,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }

            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }
        return isDataValid;
    }

    validationCheck() {
        if (!this._validateOnDemand) return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }

    _validateData(data) {

        // if (data.email !== "") {
        //     let a = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email)
        //     console.log("validazione email:", a)
        // }

        return {
            nomeVal: (!(this.state.azienda.value === 0 && data.nome === "")),
            cognomeVal: (!(this.state.azienda.value === 0 && data.cognome === "")),
            rag_socialeVal: (!(this.state.azienda.value === 1 && data.rag_sociale === "")),
            pivaVal: (!(this.state.azienda.value === 1 && data.piva === "")),
            cittaVal: !(data.citta === ""),
            emailVal: !(data.email !== "" && !(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(data.email))), // required: regex w3c uses in html5
        }
    }

    _validationErrors(val) {
        const errMsgs = {
            emailValMsg: val.emailVal ? '' : 'Email non valida!'
        }
        return errMsgs;
    }

    _grabUserInput() {


        return {
            azienda: this.state.azienda,
            nome: this.nome.value,
            cognome: this.cognome.value,
            codfisc: this.codfisc.value,
            rag_sociale: this.rag_sociale.value,
            piva: this.piva.value,
            citta: this.citta.value,
            indirizzo: this.indirizzo.value,
            email: this.email.value,
            phone: this.phone.value
        };
    }


    handleChange = (e) => {
        if (e.target.files[0]) {
            this.setState({
                image: e.target.files[0]
            });

            let file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                this.setState({sourceImg: reader.result})
            }.bind(this);
        }
    };


    render() {
        return (
            <div className="step step1 mt-5 ">
                <h4>Dati Generali</h4>
                {/*<form id="Form" className="form-horizontal mt-2">*/}

                <Form
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <Row>
                        <Col md={6}>
                            <FormGroup className="">
                                <Select
                                    defaultValue={this.state.azienda}
                                    placeholder={"tipologia"}
                                    name="azienda"
                                    id="azienda"
                                    ref={node => (this.azienda = node)}
                                    options={stateOptions}
                                    onChange={(e => {
                                        this.setState({azienda: e})
                                        // setPostpagato(e)
                                    })}
                                />
                                <small className="mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Tipologia</small>
                            </FormGroup>
                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            {/*nome*/}
                            <FormGroup className={this.state.azienda.value === 1 ? "hide" : ""}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        defaultValue={this.state.nome}
                                        ref={node => (this.nome = node)}
                                        placeholder="Nome"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center mb-3 ">
                                    <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Nome</small>
                                    {(this.state.nomeVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                                </p>
                            </FormGroup>
                            {/*cognome*/}
                            <FormGroup className={this.state.azienda.value === 1 ? "hide" : ""}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="cognome"
                                        id="cognome"
                                        defaultValue={this.state.cognome}
                                        ref={node => (this.cognome = node)}
                                        onBlur={this.validationCheck}
                                        placeholder="Cognome"
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Cognome</small>
                                    {(this.state.cognomeVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                                </p>
                            </FormGroup>


                            {/*rag_sociale*/}
                            <FormGroup className={this.state.azienda.value === 0 ? "hide" : ""}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="rag_sociale"
                                        id="rag_sociale"
                                        ref={node => (this.rag_sociale = node)}
                                        placeholder="Ragione Sociale"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Ragione Sociale</small>
                                    {(this.state.rag_socialeVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                                </p>
                            </FormGroup>
                            {/*piva*/}
                            <FormGroup className={this.state.azienda.value === 0 ? "hide" : ""}>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="piva"
                                        id="piva"
                                        ref={node => (this.piva = node)}
                                        placeholder="Partita iva"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Partita Iva</small>
                                    {(this.state.pivaVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                                </p>
                            </FormGroup>


                        </Col>
                        {/*immagine*/}
                        <Col md={6} className="text-center">
                            <img
                                onClick={(e) => {
                                    this.fileUploader.click()
                                }}
                                src={this.state.sourceImg}
                                className="rounded-circle"
                                // alt={props.id}
                                width="100"
                            />
                            <input
                                accept="image/*"
                                ref={comp => this.fileUploader = comp}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={this.handleChange}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <p className="text-center mt-0 mb-3 " onClick={(e) => {
                                this.fileUploader.click()
                            }}>
                                <small className=" badge badge-default badge-light text-dark">clicca per carica un'immagine</small>
                            </p>

                        </Col>
                    </Row>


                    <Row className={this.state.azienda.value === 1 ? "hide" : ""}>
                        {/*codice fiscale*/}
                        <Col md={6}>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="codfisc"
                                        id="codfisc"
                                        ref={node => (this.codfisc = node)}
                                        placeholder="Codice Fiscale"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Codice Fiscale</small>
                                </p>
                            </FormGroup>


                        </Col>
                    </Row>


                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="citta"
                                        id="citta"
                                        ref={node => (this.citta = node)}
                                        placeholder="Città"
                                        required
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Città</small>
                                    {(this.state.cittaVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                                </p>

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="indirizzo"
                                        id="indirizzo"
                                        ref={node => (this.indirizzo = node)}
                                        placeholder="Indirizzo"
                                        required
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Indirizzo</small>
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="citta"
                                        id="citta"
                                        ref={node => (this.email = node)}
                                        placeholder="Email"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Email</small>
                                    {(this.state.emailVal === false) ?
                                        <small className=" badge badge-default badge-danger text-white">{this.state.emailValMsg}</small> : ""}
                                    <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small></p>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="phone"
                                        id="phone"
                                        ref={node => (this.phone = node)}
                                        placeholder="Telefono"
                                        onBlur={this.validationCheck}
                                    /> </InputGroup>
                                <p className="text-center ">
                                    <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Telefono</small>
                                </p>
                            </FormGroup>
                        </Col>
                    </Row>

                </Form>

                {/*</form>*/}

            </div>
        )
    }
}
