import React, {Fragment, useEffect, useState} from "react";
import {deleteContatto, getDettagliContatto, getAcquisti} from "../../redux/action";
import {connect} from "react-redux";
import {Button, Card, CardBody, CardSubtitle, CardTitle, Col, Row} from "reactstrap";
import img1 from "../../../../assets/images/users/1.jpg";
import Iframe from "react-iframe";
import {resetFiltroSpedizioni} from "../../../spedizioni/redux/action";


const ContattoDettaglio = ({ contatto,getDettagliContatto, resetFiltroSpedizioni, ...props}) => {
    const contatto_id=props.match.params.contatto_id;
    console.log("contatto",contatto)

    useEffect(()=>{
        getDettagliContatto(contatto_id);
        resetFiltroSpedizioni();

    },[])


    return (<Fragment>
        <div>
            <Row>
                <Col xs="12" md="12" lg="4">
                    <Card>
                        <CardBody>
                            <div className="text-center mt-4">
                                <img src={img1} className="rounded-circle" width="150" alt="" />
                                <CardTitle className="mt-2">Hanna Gover</CardTitle>
                                <CardSubtitle>Accounts Manager Amix corp</CardSubtitle>
                                <Row className="text-center justify-content-md-center">
                                    <Col xs="4">
                                        <a href="/" className="link">
                                            <i className="icon-people"></i>
                                            <span className="font-medium ml-2">254</span>
                                        </a>
                                    </Col>
                                    <Col xs="4">
                                        <a href="/" className="link">
                                            <i className="icon-picture"></i>
                                            <span className="font-medium ml-2">54</span>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                        <CardBody className="border-top">
                            <div>
                                <small className="text-muted">Email address </small>
                                <h6>hannagover@gmail.com</h6>
                                <small className="text-muted pt-4 db">Phone</small>
                                <h6>+91 654 784 547</h6>
                                <small className="text-muted pt-4 db">Address</small>
                                <h6>71 Pilgrim Avenue Chevy Chase, MD 20815</h6>
                                <div>
                                    <Iframe className="position-relative" url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d470029.1604841957!2d72.29955005258641!3d23.019996818380896!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e848aba5bd449%3A0x4fcedd11614f6516!2sAhmedabad%2C+Gujarat!5e0!3m2!1sen!2sin!4v1493204785508" width="280" height="150" frameborder="0" allowfullscreen />
                                </div>
                                <small className="text-muted pt-4 db">Social Profile</small>
                                <br />
                                <Button className="btn-circle" color="info"><i className="fab fa-facebook-f"></i></Button>{' '}
                                <Button className="btn-circle" color="success"><i className="fab fa-twitter"></i></Button>{' '}
                                <Button className="btn-circle" color="danger"><i className="fab fa-youtube"></i></Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col xs="12" md="12" lg="8">


                </Col>
            </Row>
        </div>
    </Fragment>)



}



const mapStateToProps = (state, ownProps) => ({
    // acquisti: state.contactReducer.acquisti
    contatto: state.contactReducer.contactsDetails
});

const mapDispatchToProps = {
    // onEditClick: () => dispatch(setEditContactFlag()),
    getAcquisti,
    getDettagliContatto,
    resetFiltroSpedizioni
    // deleteContatto
    // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContattoDettaglio);
