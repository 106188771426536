import React, {useEffect, useState} from "react";
import {connect} from "react-redux";


const SettingsMenuItem = ({stato, sezione, onClick, children, ...props}) => {
    let attivo = sezione === stato

    useEffect(() => {
    }, [])


    return (
        <li className="list-group-item" onClick={onClick}>
		<span className={"list-group-item-action " + (attivo ? "active" : "")}>
			{children}
		</span>
        </li>
    );


}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsMenuItem)
