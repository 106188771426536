import React, {Component, Fragment, useEffect, useState} from "react";

import {connect} from "react-redux";
import { listiniExtraFetch} from "../redux/actions";
import ListExtraListContainer from "./containers/ListExtraListContainer";
import ListExtraSearchComponent from "./components/listExtraSearchComponent";
import ListExtraFilterComponent from "./components/listextraFilter";
import ListExtraDetailsContainer from "./containers/ListExtraDetailsContainer";

const Listextra = (props) => {

	const [showLeftPart, setShowLeftPart] = useState(false);
	const [showRightPart, setShowRightPart] = useState(false);

	useEffect(() => {
		if(!props.fetched) {
			props.listiniExtraFetch();
		}
	}, []); // passing an empty array as



	// props.getListini();

	let showLeftPartFx = () => {
		setShowLeftPart(!setShowLeftPart);
	}
	let showRightPartFx = () => {
		setShowRightPart(!setShowRightPart);
	}

		return (
			<Fragment>
				<div className="contact-app">
					<div className={"left-part bg-white " + (showLeftPart === true ? "show-panel" : "")}>
						<ListExtraFilterComponent />
					</div>
					<div className="right-part contact-list bg-white">
						<div className={"right-left-part " + (showRightPart === true ? "show-right-left-panel" : "")}>
							<span onClick={() => { showLeftPartFx(); }} className={"bg-primary show-left-part text-white d-block d-lg-none " + (showLeftPart === true ? "left-part-open" : "")}><i className={(showLeftPart === true ? "fas fa-chevron-left" : "fas fa-chevron-right")}></i></span>
							<ListExtraSearchComponent />
							<ListExtraListContainer showRightPart={() => { showRightPartFx(); }} />
						</div>
						<div className={"right-right-part " + (showRightPart === true ? "show-right-right-panel" : "")}>
							<span onClick={() => { showRightPartFx(); }} className={"hide-right-right-part d-block d-md-none " + (showRightPart === true ? "right-right-part-open" : "")}><i className="fas fa-times"></i></span>
							<ListExtraDetailsContainer />
						</div>
					</div>
				</div>
			</Fragment>
		);

}

const mapDispatchToProps={
	listiniExtraFetch
}
export default connect(null,mapDispatchToProps)(Listextra);
