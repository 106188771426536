import React, {Component, Fragment} from "react";

import {connect} from "react-redux";
import FiltroFattureDashboard from "../filtri/FiltroFattureDashboard";
import FattureList from "./FattureList";
import FatturaDettaglioPreview from "./FatturaDettaglioPreview";
import {resetFiltroFatture} from "../redux/action";
import {resetFiltroContatti} from "../../contatti/redux/action";


class FattureHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fattura: null,
            showLeftPart: false,
            showRightPart: false,
            showDettagliFattura: false,
        };
    }

    componentDidMount() {
        this.props.resetFiltroContatti();
        this.props.resetFiltroFatture();

    }

    onContattoClick = (fattura) => {
        console.log("contatto", fattura)
        this.setState({
            fattura,
            showRightPart: true,
            showDettagliFattura: true,
        });

    }



    render() {
        return (
            <Fragment>
                <div className="contact-app">
                    <div className={"left-part bg-white " + (this.state.showLeftPart === true ? "show-panel" : "")}>
                        <FiltroFattureDashboard/>
                    </div>
                    <div className="right-part contact-list bg-white ">
                        <div className={"right-left-part bg-whites " + (this.state.showRightPart === true ? "show-right-left-panel" : "")}>
                            <span onClick={() => {
                                this.setState({showLeftPart:!this.state.showLeftPart});
                            }}
                                  className={"bg-primary show-left-part text-white d-block d-lg-none " + (this.state.showLeftPart === true ? "left-part-open" : "")}><i
                                className={(this.state.showLeftPart === true ? "fas fa-chevron-left" : "fas fa-chevron-right")}></i></span>
                            <FattureList
                                onSelectFattura={this.onContattoClick}
                            />
                        </div>
                        <div className={"right-right-part " + (this.state.showRightPart === true ? "show-right-right-panel" : "")}>
                            <span onClick={() => {
                                this.setState({showRightPart:false})
                                // this.showRightPart(false);
                            }} className={"hide-right-right-part d-block d-md-none " + (this.state.showRightPart === true ? "right-right-part-open" : "")}>
                                <i className="fas fa-times"></i>
                            </span>

                            {(!this.state.showDettagliFattura) ?
                                <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                                    <i className="far fa-address-card display-5"></i>
                                    <h4 className="mt-2">Clicca su una fattura per visualizzarne i dettagli.</h4>
                                </div> : ""}
                            {this.state.showDettagliFattura ? <FatturaDettaglioPreview
                                fattura={this.state.fattura}
                                onCloseClick={()=>{
                                this.setState({showDettagliFattura:false,fattura:null,showRightPart:false})
                            }}/> : ""}

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    fatture: state.fatture.fatture,
    active: state.contactReducer.contactsDetails,
});


const mapDispatchToProps = {
    resetFiltroContatti,
    resetFiltroFatture
}
export default connect(mapStateToProps, mapDispatchToProps)(FattureHome);
