import {ADD_FATTURA, DELETE_FATTURA, FATTURE_AGGIUNGIA_ALLA_LISTA, FATTURE_RESET_LISTA, UPDATE_FATTURA} from "./fatture_redux_types";


const fatture = (state = [], action) => {
    switch (action.type) {
        case FATTURE_RESET_LISTA:
            return action.fatture;
        case FATTURE_AGGIUNGIA_ALLA_LISTA:
            return [...state.concat(action.fatture)];
        case ADD_FATTURA:
            return [
                ...state,
                {
                    id: action.fattura.id,
                }
            ];

        case UPDATE_FATTURA:
            console.log("action",action)
            return state.map(fattura => (fattura.id === action.fattura.id ?action.fattura : fattura));


        case DELETE_FATTURA:
            let fatture = [];
            state.map(fattura=>{
                if(fattura.id !== action.fattura_id)fatture.push(fattura)
            })
            return fatture;

        default:
            return state;
    }
};


export {fatture};
