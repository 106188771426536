import {
    CONTACTS_RELOADED,
    ADD_CONTACT,
    UPDATE_CONTACT,
    CONTACT_DETAILS,
    EDIT_CONTACT,
    SET_VISIBILITY_FILTER,
    FILTER_CONTACT,
    TOGGLE_STARRED_CONTACT,
    DELETE_CONTACT
} from '../../../redux/constants';
import {
    CONTATTI_FILTRO_ELIMINATO,
    CONTATTI_FILTRO_LOAD_PAGE, CONTATTI_FILTRO_PREFERITO, CONTATTI_FILTRO_RESET,
    CONTATTI_FILTRO_SEARCH, CONTATTI_FILTRO_TIPO,
    CONTATTI_LAZY_LOADING,
    SAGA_CONTATTI_ADD_ACQUISTI, SAGA_CONTATTI_ADD_INDIRIZZO,
    SAGA_CONTATTI_CREATE, SAGA_CONTATTI_DELETE, SAGA_CONTATTI_DETTAGLIO,
    SAGA_CONTATTI_GET_ACQUISTI, SAGA_CONTATTI_GET_INDIRIZZI,
    SAGA_CONTATTI_SEARCH, SAGA_CONTATTI_UPDATE
} from "./contatti_redux_types";
import {SPEDIZIONI_FILTRO_LOAD_PAGE} from "../../spedizioni/redux/spedizioni_redux_types";


export const searchContatti = () => {
    return (dispatch, getState, {getFirestore}) => {

        const state = getState();
        const filtro = state.contactReducer.filtro;

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_CONTATTI_SEARCH,
            payload: {
                filtro
            }
        })

    }

}

export const getDettagliContatto = (contatto_id) => {
    return (dispatch, getState, {getFirestore}) => {


        //BECCATO DA SAGA
        dispatch({
            type: SAGA_CONTATTI_DETTAGLIO,
            payload: {
                contatto_id
            }
        })

    }

}


export const setFiltroSearch = (testo) => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: CONTATTI_FILTRO_SEARCH,
            payload: {
                search: testo
            }
        })

    }

}

export const resetFiltroContatti = () => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: CONTATTI_FILTRO_RESET,
        })

    }

}

export const setFiltroLoadPage = (page) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: CONTATTI_FILTRO_LOAD_PAGE,
            payload: {
                page,
            }
        });
    }
}

export const setFiltroTipo = (tipo) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: CONTATTI_FILTRO_TIPO,
            payload: {
                tipo,
            }
        });
    }
}
export const setFiltroEliminato = (eliminato) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: CONTATTI_FILTRO_ELIMINATO,
            payload: {
                eliminato,
            }
        });
    }
}
export const setFiltroPreferito = (preferito) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: CONTATTI_FILTRO_PREFERITO,
            payload: {
                preferito,
            }
        });
    }
}








export const resetContatto = () => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: CONTACT_DETAILS,
            id: ""
        })

    }

}

export const addAcquisto = (acquisto) => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: SAGA_CONTATTI_ADD_ACQUISTI,
            payload: {
                acquisto
            }
        })
    }
}

export const addIndirizzo = (indirizzo, seleziona = false) => {
    return (dispatch, getState) => {
        dispatch({
            type: SAGA_CONTATTI_ADD_INDIRIZZO,
            payload: {
                indirizzo,
                seleziona
            }
        })
    }
}


export const getAcquisti = (contatto_ref,attivi) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();


        // const contatto_ref = state.contactReducer.contactsDetails;

        dispatch({
            type: SAGA_CONTATTI_GET_ACQUISTI,
            payload: {
                contatto_ref,
                attivi
            }
        })


    }


}

export const getIndirizzi = (contatto_id) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        // const contatto_ref = contatto_id;

        dispatch({
            type: SAGA_CONTATTI_GET_INDIRIZZI,
            payload: {
                contatto_ref: contatto_id
            }
        })


    }


}



export const fetchContatti = (lazy = false) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        //SE lazy e' tru devo aumentare l'offset per la queri e fare il dispatch dell'aggiunta e non il repalce dei contatti
        if (lazy) {
            dispatch({
                type: CONTATTI_LAZY_LOADING,
            });
        } else {
            dispatch({
                type: CONTATTI_LAZY_LOADING,
                reset: true
            })
        }
        let l = (lazy) ? state.contactReducer.lazy + 1 : state.contactReducer.lazy;

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_CONTATTI_SEARCH,
            payload: {
                search: state.contactReducer.contactsSearch,
                filtro: state.contactReducer.contactsVisibilityFilter,
                lazy: l,
            }
        })

    }
}



export const creaContatto = (contatto) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SAGA_CONTATTI_CREATE,
            payload: {
                contatto: contatto,
            }
        });
    }
}
export const updateContatto = (contatto) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SAGA_CONTATTI_UPDATE,
            payload: {
                contatto,
            }
        });
    }
}

export const deleteContatto = (contatto) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SAGA_CONTATTI_DELETE,
            payload: {
                contatto,
            }
        });
    }
}


// export const aggiornaContatto = (id, values, flag) => {
//     // console.log(values)
//
//     return (dispatch, getState, {getFirestore}) => {
//
//
//         let state = getState();
//         let fstore = getFirestore();
//
//
//         let key = state.contactReducer.contacts.findIndex((e) => e.id == id);
//
//
//         let contatto_in_edit = Object.assign({}, state.contactReducer.contacts[key]);
//
//
//         delete contatto_in_edit.id;
//         delete contatto_in_edit.image;
//         console.log("contatto_in_edit", contatto_in_edit);
//
//
//         // console.log("contatto", state.contactReducer.contact)
//         fstore.collection(
//             // "/aziende/" + state.session.azienda.id +
//             "/clienti").doc(id).update(contatto_in_edit).then(r => {
//             console.log(r)
//
//         });
//
//
//         dispatch({
//             type: EDIT_CONTACT,
//             flag
//         });
//
//     }
//
// };


export const updateContact = (id, field, value) => ({
    type: UPDATE_CONTACT,
    id: id,
    field: field,
    value: value
});
export const updateContact2 = (id, field, value) => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: UPDATE_CONTACT,
            id: id,
            field: field,
            value: value
        });

    };
}

export const contactDetails = id => ({
    type: CONTACT_DETAILS,
    id
});

export const setEditContactFlag = flag => ({
    type: EDIT_CONTACT,
    flag
});
export const setEditContactFlag2 = flag => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: EDIT_CONTACT,
            flag
        })
    }
};


export const setVisibilityFilter = (filter) => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: SET_VISIBILITY_FILTER,
            filter
        });
    }
}


export const contactsSearch = searchTerm => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: FILTER_CONTACT,
            payload: searchTerm
        })
    }
};

export const toggleStarredContact = id => ({
    type: TOGGLE_STARRED_CONTACT,
    id
});

export const deleteContact = id => ({
    type: DELETE_CONTACT,
    id
});

export const contactVisiblityFilter = {
    SHOW_ALL: "SHOW_ALL",
    FREQUENT_CONTACT: "FREQUENT_CONTACT",
    STARRED_CONTACT: "STARRED_CONTACT",
    ENGINEERING_DEPARTMENT_CONTACT: "ENGINEERING_DEPARTMENT_CONTACT",
    SALES_DEPARTMENT_CONTACT: "Sales",
    SUPPORT_DEPARTMENT_CONTACT: "SUPPORT_DEPARTMENT_CONTACT",
    SEARCH_CONTACT: "SEARCH_CONTACT",
    DELETED_CONTACT: "DELETED_CONTACT",
    TIPOLOGIA_CONTATTI: "TIPOLOGIA_CONTATTI",
    TIPOLOGIA_AZIENDE: "TIPOLOGIA_AZIENDE"
};
