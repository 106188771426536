import Dashboard from '../views/dashboard/dashboard';
import Contacts from "../moduli/contatti/views/contacts";
import Listini from "../moduli/listini_pack/views/listini_pack";
import Listextra from "../moduli/listini_extra/views/listextra";
import SpedizioniHome from "../moduli/spedizioni/views/SpedizioniHome/SpedizioniHome";
import FattureHome from "../moduli/fatture/FattureHome/FattureHome";
import SettingsHome from "../moduli/settings/SettingsHome/SettingsHome";

var ThemeRoutes = [
	{
		navlabel: true,
		name: "Personal",
		icon: "mdi mdi-dots-horizontal",
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		icon: 'ti-dashboard',
		component: Dashboard
	},
	// { path: '/calendar', name: 'Calendario', icon: 'mdi mdi-calendar', component: Calendar },
	{ path: '/clienti', name: 'Clienti', icon: 'mdi mdi-contacts', component: Contacts },
	{ path: '/spedizioni/dashboard', name: 'Spedizioni',state:'spedizioni', icon: 'fas fa-box-open', component: SpedizioniHome },
	{ path: '/fatture/dashboard', name: 'Fatture',state:'fatture', icon: 'fas fa-file-invoice-dollar', component: FattureHome },


	// {
	// 	collapse: true,
	// 	path: "/spedizioni",
	// 	name: "Spedizioni",
	// 	state: "spedizioni",
	// 	icon: "fas fa-box-open",
	// 	child: [
	// 		{
	// 			path: '/spedizioni/dashboard',
	// 			name: 'Lista',
	// 			icon: 'mdi mdi-buffer',
	// 			component: SpedizioniHome
	// 		},
	// 		{
	// 			path: '/impostazioni/ricerca',
	// 			name: 'Ricerca',
	// 			icon: 'mdi mdi-buffer',
	// 			component: Listextra
	// 		},
	// 		{
	// 			path: '/impostazioni/estrazione',
	// 			name: 'Download',
	// 			icon: 'mdi mdi-arrange-send-backward',
	// 			component: Dashboard
	// 		},
	//
	// 	]
	// },
	// {
	// 	collapse: true,
	// 	path: "/fatture",
	// 	name: "Fatture",
	// 	state: "fatture",
	// 	icon: "fas fa-box-open",
	// 	child: [
	// 		{
	// 			path: '/fatture/dashboard',
	// 			name: 'Lista',
	// 			icon: 'mdi mdi-buffer',
	// 			component: FattureHome
	// 		},
	// 		{
	// 			path: '/impostazioni/ricerca',
	// 			name: 'Ricerca',
	// 			icon: 'mdi mdi-buffer',
	// 			component: Listextra
	// 		},
	// 		{
	// 			path: '/impostazioni/estrazione',
	// 			name: 'Download',
	// 			icon: 'mdi mdi-arrange-send-backward',
	// 			component: Dashboard
	// 		},
	//
	// 	]
	// },
	{
		collapse: true,
		path: "/impostazioni",
		name: "Impostazioni",
		state: "impostazioni",
		icon: "mdi mdi-buffer",
		child: [
			{
				path: '/impostazioni/listini-pack',
				name: 'Listini Pack',
				icon: 'mdi mdi-buffer',
				component: Listini
			},
			{
				path: '/impostazioni/listini-extra',
				name: 'Listini Extras',
				icon: 'mdi mdi-buffer',
				component: Listextra
			},
			{
				path: '/impostazioni/corrieri',
				name: 'Corrieri',
				icon: 'mdi mdi-arrange-send-backward',
				component: Dashboard
			},
			{
				path: '/impostazioni/settings',
				name: 'Settings',
				icon: 'mdi mdi-arrange-send-backward',
				component: SettingsHome
			},

		]
	},





	{ path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true }
];
export default ThemeRoutes;
