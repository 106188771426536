import {combineReducers} from "redux";
import spedizioneDetails from "./spedizioneDetails";
import {spedizioni} from "./spedizioni";
import {filtro} from "./filtro";

export default combineReducers({
    filtro,
    spedizioni,
    spedizioneDetails,
});
