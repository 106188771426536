import {
    SPEDIZIONI_FILTRO_DATA_ENUM,
    SPEDIZIONI_FILTRO_DATA,
    SPEDIZIONI_FILTRO_RESET,
    SPEDIZIONI_FILTRO_STATO,
    SPEDIZIONI_FILTRO_STATO_ENUM,
    SPEDIZIONI_FILTRO_LOAD_PAGE,
    SPEDIZIONI_FILTRO_LIMIT,
    SPEDIZIONI_FILTRO_PAGINATION_COUNT,
    SPEDIZIONI_FILTRO_CONTATTO,
    SPEDIZIONI_FILTRO_CORRIERE,
    SPEDIZIONI_FILTRO_TIPOLOGIA,
    SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM,
    SPEDIZIONI_SET_FILTRO,
    SPEDIZIONI_FILTRO_PAGAMENTO_ENUM,
    SPEDIZIONI_FILTRO_CONTRASSEGNO
} from "./spedizioni_redux_types";
import moment from "moment";

const defaults = {
    pagamento: SPEDIZIONI_FILTRO_PAGAMENTO_ENUM.ALL,
    stato: SPEDIZIONI_FILTRO_STATO_ENUM.ALL,
    tipologia: SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.ALL,
    data_filtro: SPEDIZIONI_FILTRO_DATA_ENUM.ODIERNE,
    data_from: moment().startOf('day').toDate(),
    data_to: 0,
    corriere_ref: null,
    contatto_ref: null,
    contrassegno: false,
    pagination_count: 0,
    pagination_page: 1,
    pagination_limit: 50
}

const filtro = (state = defaults, action) => {
    switch (action.type) {
        case SPEDIZIONI_SET_FILTRO:
            return action.payload.filtro;
        case SPEDIZIONI_FILTRO_RESET:
            return defaults;
        case SPEDIZIONI_FILTRO_LOAD_PAGE:
            return {...state, pagination_page: action.payload.page};
        case SPEDIZIONI_FILTRO_PAGINATION_COUNT:
            return {...state, pagination_count: action.count};
        case SPEDIZIONI_FILTRO_LIMIT:
            let limite = action.limite;
            return {...state, pagination_page: 1, pagination_limit: limite};
        case SPEDIZIONI_FILTRO_DATA:
            let date_from = 0;
            let date_to = 0;
            let filtro = action.payload.filtro;

            if (filtro === SPEDIZIONI_FILTRO_DATA_ENUM.ODIERNE) {
                //calcolare le 00.00 e le 23.59
                date_from = moment().startOf('day').toDate();

            } else if (filtro === SPEDIZIONI_FILTRO_DATA_ENUM.ALL) {
                date_from = 0;
                date_to = 0;
            } else if (filtro === SPEDIZIONI_FILTRO_DATA_ENUM.RANGE) {
                date_from = moment(action.payload.from,"YYYY-MM-DD").startOf('day').toDate();
                date_to = moment(action.payload.to,"YYYY-MM-DD").endOf('day').toDate();
                // date_to = action.payload.to;
                // console.log("date frommmmm", date_from)
                // console.log("date tooooooo", date_to)
            }

            return {...state, data_filtro: filtro, data_from: date_from, data_to: date_to, pagination_page: 1};
        case SPEDIZIONI_FILTRO_STATO:
            let stato = action.payload.stato;
            return {...state, stato, pagination_page: 1};
        case SPEDIZIONI_FILTRO_CONTRASSEGNO:
            return {...state, contrassegno: action.payload.contrassegno, pagination_page: 1};
        case SPEDIZIONI_FILTRO_TIPOLOGIA:
            return {...state, tipologia: action.payload.tipologia, pagination_page: 1};
        case SPEDIZIONI_FILTRO_PAGAMENTO_ENUM:
            return {...state, pagamento: action.payload.pagamento, pagination_page: 1};
        case SPEDIZIONI_FILTRO_CONTATTO:
            let contatto_ref = action.payload.contatto;
            return {...state, contatto_ref, pagination_page: 1};
        case SPEDIZIONI_FILTRO_CORRIERE:
            let corriere_ref = action.payload.corriere;
            return {...state, corriere_ref, pagination_page: 1};


        default:
            return state;
    }
};


export {filtro};
