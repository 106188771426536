export const SAGA_LISTINI_PACK_SEARCH = "SAGA_LISTINI_PACK_SEARCH";
export const SAGA_LISTINI_PACK_JSON = "SAGA_LISTINI_PACK_JSON";
export const SAGA_LISTINI_PACK_ADD = "SAGA_LISTINI_PACK_ADD";

export const SAGA_LISTINI_PACK_TIPOLOGIA_ADD = "SAGA_LISTINI_PACK_TIPOLOGIA_ADD";
export const SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE = "SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE";
export const SAGA_LISTINI_PACK_TIPOLOGIA_LIST = "SAGA_LISTINI_PACK_TIPOLOGIA_LIST";
export const SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA = "SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA";


export const LISTINI_LAZY_LOADING = "LISTINI_LAZY_LOADING";
export const LISTINI_RESET_LISTA = "LISTINI_RESET_LISTA";
export const LISTINI_PACK_JSON = "LISTINI_PACK_JSON";
export const LISTINI_AGGIUNGIA_ALLA_LISTA = "LISTINI_AGGIUNGIA_ALLA_LISTA";


