import {connect} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Badge, Button, UncontrolledTooltip} from "reactstrap";
import moment from "moment";
import {FATTURE_FILTRO_STATO_ENUM} from "../redux/fatture_redux_types";
import {eliminaFattura, aggiornaFattura, dettaglioFattura} from "../redux/action";
import getAxios from "../../../rest/myaxios";
import {REST_FATTURE_DOWNLOAD} from "../../../rest/rest-constants";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import UtilsFatture, {getNomeFileFattura} from "../utils/fatture-utils";
import BottoneDownloadFattura from "../FatturaDettaglio/BottoneDownloadFattura";
const axios = getAxios()


const FatturaDettaglioPreview = ({fattura, fatture, dettaglioFattura, aggiornaFattura, eliminaFattura, onCloseClick, ...props}) => {
    const [stato, setStato] = useState({label: '', color: '', val: '', code: ''});
    const [color, setColor] = useState('primary');
    const [pagata, setPagata] = useState(false);
    const [fatt, setFatt] = useState(fattura);
    const [spedizioni, setSpedizioni] = useState([]);


    useEffect(() => {
        // setColor(stato.color)
        if (fattura !== null) {
            setFatt(fattura)
            let st = FATTURE_FILTRO_STATO_ENUM.getStato(fattura.stato)
            setStato(st);
            setPagata(!!fattura.pagata);
            dettaglioFattura(fattura.id);
            setSpedizioni((fattura.spedizioni)?fattura.spedizioni:[]);
        }

    }, [fattura]);

    useEffect(() => {
        // setColor(stato.color)
        if (fatt !== null) {
            setSpedizioni((fatt.spedizioni)?fatt.spedizioni:[]);
        }
    }, [JSON.stringify(fatt)]);





    useEffect(() => {
        console.log("sono cambiate le fatture");
        fatture.map(_fatt=>{
            if(fatt.id === _fatt.id){
                console.log("fattura ricaricata" , _fatt);
                setFatt(_fatt)


            }


        })

    }, [JSON.stringify(fatture)])


// console.log("fattura",fattura)
    const nome_completo = `${fattura.contatto.nome} ${fattura.contatto.cognome} ${fattura.contatto.citta}`;
    // const nome_completo = `sssssssssss`;
    // const n = nome_completo.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"").trim().toLowerCase().replace(/[ ]/gim,"-");
    return <Fragment>
        {fatt ? (
            <div className="p-4">
                <div className="d-flex flex-row mb-4">
                    <div className="d-flex w-75  justify-content-start">
                        <h3 className="font-bold mb-0">
                            {(fatt.stato === 0) ? 'Addebito' : 'Accredito'} #{fatt.id} del {moment(fatt.createdAt).format("DD/MM/YYYY")}
                        </h3>
                    </div>
                    <div className="w-25  justify-content-end">
                        <Button className="btn-circle close p-0 m-0" onClick={onCloseClick} aria-label="Close"> <span
                            aria-hidden="true">×</span> </Button>
                    </div>


                </div>


                <div className={"mb-4"}><BottoneDownloadFattura fattura={fatt} contatto={fatt.contatto}/></div>

                <div className="d-flex w-100 justify-content-start mb-0">
                    <div className="d-flex w-25 justify-content-start">
                        <h3>{fatt.importo}€</h3>
                    </div>
                    <div className="d-flex w-75 justify-content-start">
                        {(!!!fatt.pagata) ?
                            <h3><Badge color={'danger'}>NON PAGATA</Badge>
                                <a className={"btn  btn-circle btn-success"}
                                   onClick={() => {
                                       setPagata(true)
                                       fatt.pagata = true;
                                       aggiornaFattura(fatt)

                                   }} href={"#"} id={"btn-pagata-ok"}>
                                    <i className={"fas fa-hand-holding-usd"}></i>
                                </a><UncontrolledTooltip placement="right" target={"btn-pagata-ok"}>Imposta come
                                    pagata</UncontrolledTooltip></h3>

                            : <h3><Badge color={'success'}>PAGATA</Badge> <a className={"btn  btn-circle btn-danger"}
                                                                             onClick={() => {
                                                                                 setPagata(false)
                                                                                 fatt.pagata = false;
                                                                                 aggiornaFattura(fatt)

                                                                             }} href={"#"} id={"btn-pagata-ok"}>
                                <i className={"fas fa-hand-holding-usd"}></i>
                            </a><UncontrolledTooltip placement="right" target={"btn-pagata-ok"}>Imposta come non
                                pagata</UncontrolledTooltip></h3>
                        }
                    </div>
                </div>


                <div className="d-flex w-100 justify-content-start mb-0">
                    <div className="d-flex w-25 justify-content-start">
                        azioni:
                    </div>
                    <div className="d-flex w-75 justify-content-start">
                        stato:
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-start mb-4">
                    <h3 className="d-flex  w-100">
                        <div className="d-flex w-25 justify-content-start">
                            {(stato.val !== 0) ? <div><a className={"btn btn-circle btn-light"} onClick={() => {
                                setStato(FATTURE_FILTRO_STATO_ENUM.getStato(0))
                                fatt.stato = 0;
                                aggiornaFattura(fatt)
                            }} href={"#"} id={"btn-stato-ap"}>OK</a><UncontrolledTooltip placement="bottom"
                                                                                         target={"btn-stato-ap"}>Imposta
                                come Nuova</UncontrolledTooltip></div> : ''}

                            {(stato.val !== 1) ? <div><a className={"btn btn-circle btn-secondary"} onClick={() => {
                                setStato(FATTURE_FILTRO_STATO_ENUM.getStato(1))
                                fatt.stato = 1;
                                aggiornaFattura(fatt)

                            }} href={"#"} id={"btn-stato-co"}>AN</a><UncontrolledTooltip placement="bottom"
                                                                                         target={"btn-stato-co"}>Imposta
                                come Annullata</UncontrolledTooltip></div> : ''}

                            {(stato.val === 1) ? <div><a className={"btn  btn-circle btn-danger"} onClick={() => {
                                // setStato(FATTURE_FILTRO_STATO_ENUM.getStato(2))
                                // fatt.stato = 2;
                                eliminaFattura(fatt.id);

                            }} href={"#"} id={"btn-stato-an"}>EL</a>
                                <UncontrolledTooltip placement="bottom"
                                                     target={"btn-stato-an"}>Elimina fattura</UncontrolledTooltip></div> : ''}
                        </div>
                        <div className="d-flex w-75 justify-content-start">
                            <Badge color={color}>{stato.label.toUpperCase()}</Badge>
                        </div>
                    </h3>
                </div>
                <hr/>


                <div>
                    <h4>Note</h4>
                    {(fatt.note && fatt.note !== '') ? fatt.note : 'nessuna nota'}
                </div>
                <hr/>
                <div>
                    <h4>Spedizioni associate</h4>
                    {(spedizioni && spedizioni.length > 0) ? <div>

                        <BootstrapTable className="spedizioni-in-fattura-table no-wrap  v-middle mb-0" data={spedizioni} striped hover
                            // pagination
                                        options={{
                                            defaultSortName: 'data_spedizione',  // default sort column name
                                            defaultSortOrder: 'asc'  // default sort order
                                        }}
                                        tableHeaderClass='mb-0'

                        >

                            <TableHeaderColumn isKey={true} width='100' dataSort={true} dataField='data_spedizione'
                                               caretRender={(direction) => (direction === 'asc') ? <i className={"fas fa-arrow-up"}></i> :
                                                   <i className={"fas fa-arrow-down"}></i>}
                                               dataFormat={(c, r) => {
                                                   return `${moment(c).format("DD/MM/YYYY H:mm:ss")} | #${r.id}`
                                               }}>
                                Data
                            </TableHeaderColumn>
                            <TableHeaderColumn width='250'
                                               dataField='spedizione'
                                               dataFormat={(c, r) => {
                                                   // console.log("C",r);
                                                   const indirizzo = (r.indirizzo_to)?`<br/>To: ${r.indirizzo_to.comune} - ${r.indirizzo_to.indirizzo}`:'';
                                                   return `#${r.id} | ${r.quantita}x | ${r.spedizione_tipo.nome} - ${r.costo_totale}€ ${indirizzo}`


                                                   // return `${r.spedizione_tipo.nome} (${r.costo_spedizione}€)`
                                               }}>
                                Spedizione
                            </TableHeaderColumn>
                            <TableHeaderColumn width='250'
                                               dataField='extra'
                                               dataFormat={(c, r) => {
                                                   // console.log("C",r.extra);
                                                   const extras = (r.extra) ? Object.entries(r.extra) : [];

                                                   let extra_text="";
                                                   if (extras.length > 0) {
                                                           extras.map((key) => {
                                                               if(key[1]!=null) extra_text += `${key[1].qt}x ${key[1].nome} (pu:${key[1].prezzo}€ - tot:${key[1].prezzo * key[1].qt}€)<br/> `;
                                                           })
                                                   }


                                                   if (extra_text!=='') extra_text+=`tot. extra: ${r.costo_extra}€`;
                                                   return extra_text
                                               }}>
                                Extra
                            </TableHeaderColumn>
                            <TableHeaderColumn width='100'
                                               dataField='costo_totale'
                                               dataFormat={(c, r) => {
                                                   // console.log("C",r);
                                                   return `${r.costo_totale}€`
                                               }}>
                                Totale
                            </TableHeaderColumn>

                        </BootstrapTable>


                    </div> : 'nessuna spedizione'}
                </div>


            </div>
        ) : (
            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                <i className="far fa-address-card display-5"></i>
                <h4 className="mt-2">Clicca su una fattura per visualizzarne i dettagli.</h4>
            </div>
        )}
    </Fragment>
        ;
}

FatturaDettaglioPreview.prototype = {
    fattura: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            image: PropTypes.string,
            codfisc: PropTypes.string,
            citta: PropTypes.string,
            phone: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            address: PropTypes.string,
            notes: PropTypes.string,
            frequentlycontacted: PropTypes.bool.isRequired,
            starred: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired
};


const mapStateToProps = (state, ownProps) => ({
    fatture: state.fatture.fatture
});

const mapDispatchToProps = {
    aggiornaFattura,
    eliminaFattura,
    dettaglioFattura,
    // onEditClick: () => dispatch(setEditContactFlag()),
    // getAcquisti,
    // deleteContatto
    // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FatturaDettaglioPreview);
