import React, {useEffect, useState} from "react";
import {Col, Row, Form, FormGroup, Label, Button, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import user1 from '../../../../assets/images/default_thumbs/listino_pack_tipo_default.jpg';
import {addListino, addTipologiaPack, modificaTipologiaPack} from "../../redux/actions";


const AddTipoSpedizione = ({spedizione,onModificaClick,onSalvataggioComplete,addTipologiaPack,modificaTipologiaPack,...props}) => {
    let fileUploader;
    const [image, setImage] = useState("");
    const [sourceImg, setSourceImg] = useState(user1);
    const [nome, setNome] = useState("");
    const [costo, setCosto] = useState(0);
    const [descrizione, setDescrizione] = useState("");

    useEffect(()=>{
        if(spedizione!==undefined){
            setNome(spedizione.nome);
            setCosto(parseFloat(spedizione.prezzo));
            setDescrizione(spedizione.descrizione);
        }
    },[JSON.stringify(spedizione)])


    let handleChange = e => {
        console.log(e.target.files)
        if (e.target.files[0]) {
            setImage(e.target.files[0]);


            let file = e.target.files[0];

            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSourceImg(reader.result)
            }.bind(this);

        }


    };


    return (
        <React.Fragment>
            <Form
                onSubmit={e => {
                    e.preventDefault();

                    if (!nome.trim()) {
                        return;
                    }


                    // props.addContact(firstname.value, props)

                    const tipologia_pack = {
                        nome: nome,
                        listino_pack_ref: props.listini.listinoDetails,
                        descrizione: descrizione,
                        prezzo: costo,
                        image
                    }

                    if(spedizione){
                        tipologia_pack.id=spedizione.id;
                        modificaTipologiaPack(tipologia_pack)
                    }else{
                        addTipologiaPack(tipologia_pack);
                    }



                    onSalvataggioComplete()

                }}
            >
                <ModalBody>
                    <Row>
                        <Col md={6} className="text-center">
                            <img
                                onClick={(e) => {
                                    fileUploader.click()
                                }}
                                src={sourceImg}
                                className="rounded-circle"
                                // alt={props.id}
                                width="100"
                            />
                            <input
                                accept="image/*"
                                ref={comp => fileUploader = comp}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChange}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <p className="text-center mt-0 mb-3 " onClick={(e) => {
                                fileUploader.click()
                            }}>
                                <small className=" badge badge-default badge-light text-dark">clicca per carica un'immagine</small>
                            </p>

                        </Col>
                        <Col md={6}>

                            <FormGroup className="mb-3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        value={nome}
                                        onChange={(e)=>{
                                            setNome(e.target.value)
                                        }}
                                        placeholder="Tipo spedizione"
                                        required
                                    /> </InputGroup>


                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Nome</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>


                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="far euro"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="costo"
                                        id="costo"
                                        value={costo}
                                        onChange={(e)=>{
                                            setCosto(e.target.value)
                                        }}
                                        required
                                    />
                                </InputGroup>
                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Prezzo</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>

                        </Col>
                    </Row>



                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="descrizione">Descrizione</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="descrizione"
                                    id="descrizione"
                                    value={descrizione}
                                    onChange={(e)=>{
                                        setDescrizione(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        {(spedizione===undefined)?'Aggiungi Spedizione':"Modifica Spedizione"}
                    </Button>
                </ModalFooter>
            </Form>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    ...state,
    id: state.listini.listini.length
});

const mapDispatchToProps = {
    addTipologiaPack,
    modificaTipologiaPack
}


export default connect(mapStateToProps, mapDispatchToProps)(AddTipoSpedizione);
