import {
    SESSION_LOGGED,
    SESSION_LOADING, SESSION_RESTORED, SESSION_LOGGED_OUT, SESSION_FETCHING, SESSION_FETCHING_ERROR,
} from '../constants/';
import * as rest from '../../rest/rest-constants';
import axios from 'axios';
import getAxios from '../../rest/myaxios'

export const prova = () => {
    return (dispatch, getState, {getFirebase, getFirestore}) => {
        dispatch({
            type: 'CARICA_CONTATTI',
            payload: "contenuto"
        })
    }

};

export const login = (user, psw) => {

    return (dispatch, getState, {getFirebase, getFirestore}) => {

        dispatch({
            type: SESSION_LOADING,
            loading: true
        });


        axios.post(rest.LOGIN, {
            email: user,
            password: psw
        }).then((r) => {

            //salvo in localStorage
            localStorage.setItem('token', r.data.accessToken);

            dispatch({
                type: SESSION_LOGGED,
                user: r.data.user,
                token: r.data.accessToken
            });
            dispatch({
                type: SESSION_LOADING,
                loading: false
            });


        }).catch(err => {
            dispatch({
                type: SESSION_LOADING,
                loading: false
            });

        });


    }
}

export const logout = () => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        localStorage.removeItem('token');
        dispatch({
            type: SESSION_LOGGED_OUT
        });
    }
}


export const fetching = (fetching, error=false, msg='') => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        if(error){
            dispatch({
                type: SESSION_FETCHING_ERROR,
                error: {msg}
            });

        }else{
            dispatch({
                type: SESSION_FETCHING,
                fetching: fetching
            });

        }
    }
}


export const restore = () => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SESSION_LOADING,
            loading: true
        });

        const token = localStorage.getItem('token');

        if (token) {
            //chiedo se il token e' valido
            //se e' valido lo rinnovo
            //se non e' valido 401 e richiedo nuovamente login

            axios.post(rest.CHECKTOKEN, {
                token: token,
            })
                .then((r) => {

                //salvo in localStorage
                localStorage.setItem('token', r.data.accessToken)

                dispatch({
                    type: SESSION_LOGGED,
                    user: r.data.user,
                    token: r.data.accessToken
                });

                setTimeout(()=>{
                    dispatch({
                        type: SESSION_LOADING,
                        loading: false
                    });

                },1)


            })
                .catch(err => {
                localStorage.removeItem('token');

                dispatch({
                    type: SESSION_LOGGED_OUT,
                });

                dispatch({
                    type: SESSION_LOADING,
                    loading: false
                });

            });


        } else {


            localStorage.removeItem('token');

            dispatch({
                type: SESSION_LOADING,
                loading: false
            });

            dispatch({
                type: SESSION_LOGGED_OUT,
            });



        }


    }

}


