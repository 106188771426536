export const SAGA_CONTATTI_DETTAGLIO = "SAGA_CONTATTI_DETTAGLIO";
export const SAGA_CONTATTI_SEARCH = "SAGA_CONTATTI_SEARCH";
export const SAGA_CONTATTI_CREATE = "SAGA_CONTATTI_CREATE";
export const SAGA_CONTATTI_UPDATE = "SAGA_CONTATTI_UPDATE";
export const SAGA_CONTATTI_DELETE = "SAGA_CONTATTI_DELETE";
export const SAGA_CONTATTI_GET_ACQUISTI = "SAGA_CONTATTI_GET_ACQUISTI";
export const SAGA_CONTATTI_ADD_ACQUISTI = "SAGA_CONTATTI_ADD_ACQUISTI";

export const SAGA_CONTATTI_ADD_INDIRIZZO = "SAGA_CONTATTI_ADD_INDIRIZZO";
export const SAGA_CONTATTI_GET_INDIRIZZI = "SAGA_CONTATTI_GET_INDIRIZZI";


export const CONTATTI_LAZY_LOADING = "CONTATTI_LAZY_LOADING";
export const CONTATTI_RESET_LISTA = "CONTATTI_RESET_LISTA";
export const CONTATTI_AGGIUNGIA_ALLA_LISTA = "CONTATTI_AGGIUNGIA_ALLA_LISTA";


export const CONTATTI_GET_LISTA_ACQUISTI = "CONTATTI_GET_LISTA_ACQUISTI";
export const CONTATTI_AGGIUNGI_LISTA_ACQUISTI = "CONTATTI_AGGIUNGI_LISTA_ACQUISTI";

export const CONTATTI_INDIRIZZI_AGGIUNGI = "CONTATTI_INDIRIZZI_AGGIUNGI";
export const CONTATTI_GET_INDIRIZZI = "CONTATTI_GET_INDIRIZZI";

export const CONTATTI_INDIRIZZO_SELEZIONATO = "CONTATTI_INDIRIZZO_SELEZIONATO";



export const CONTATTI_FILTRO_RESET = "CONTATTI_FILTRO_RESET";
export const CONTATTI_FILTRO_LOAD_PAGE = "CONTATTI_FILTRO_LOAD_PAGE";
export const CONTATTI_FILTRO_PAGINATION_COUNT = "CONTATTI_FILTRO_PAGINATION_COUNT";
export const CONTATTI_FILTRO_LIMIT = "CONTATTI_FILTRO_LIMIT";
export const CONTATTI_FILTRO_SEARCH = "CONTATTI_FILTRO_SEARCH";
export const CONTATTI_FILTRO_TIPO = "CONTATTI_FILTRO_TIPO";
export const CONTATTI_FILTRO_ELIMINATO = "CONTATTI_FILTRO_ELIMINATO";
export const CONTATTI_FILTRO_PREFERITO = "CONTATTI_FILTRO_PREFERITO";






