import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {SPEDIZIONI_FILTRO_STATO_ENUM} from "../../redux/spedizioni_redux_types";
import {Tooltip, UncontrolledTooltip} from "reactstrap";
import moment from "moment";

const SpedizioneListItem = ({
                                onRowClick,
                                starred,
                                spedizione: {
                                    contatto,
                                    spedizione_tipo,
                                    indirizzo_to,
                                    ...spedizione
                                },
                                active,
                                ...props
                            }) => {


    let nome = (contatto.azienda) ? contatto.rag_sociale : `${contatto.cognome} ${contatto.nome}`
    let extra = (spedizione.extra && spedizione.extra.length > 0) ? `, ${spedizione.extra.length} extra.` : '';

    let stato = SPEDIZIONI_FILTRO_STATO_ENUM.getStato(spedizione.stato);
    let color = stato.color;
    let tooltip_stato = `${stato.label.toUpperCase()}`;

    if (spedizione.stato === 1 && spedizione.contrassegno_importo > 0 && !!!spedizione.contrassegno_riconsegnato) {
        color = "warning";
        tooltip_stato+=" con contrassegno non riconsegnato!"
    }

    let indirizzo = (indirizzo_to !== null) ? `to: ${indirizzo_to.comune} - ${indirizzo_to.indirizzo}` : 'no indirizzo'
    return (
        <tr className={((active === spedizione.id) ? "bg-light" : "")}>

            <td onClick={onRowClick} style={{width: 50 + 'px'}}>
                <a className={`btn-circle btn btn-${color}`} id={`btn-stato-${spedizione.id}`} href="#">{stato.code}</a>
                <UncontrolledTooltip placement="right" target={`btn-stato-${spedizione.id}`}>
                    {tooltip_stato}
                </UncontrolledTooltip>
            </td>
            <td onClick={onRowClick}>
                <p className="mb-0 text-truncate">
                    #{spedizione.id} - {nome} | {indirizzo}
                </p>
                <p className="mb-0 text-muted">{moment(spedizione.data_spedizione).format('DD/MM/YYYY')} | {spedizione_tipo.nome}{extra}, tot.{spedizione.costo_totale}€</p>

            </td>
            <td className="text-center" style={{width: 70 + 'px'}}>
                {
                    (spedizione.contrassegno_importo > 0) ? <span><i
                        id={`btn-contrassegno-${spedizione.id}`}
                        onClick={() => {
                        }}
                        className="fas fa-dollar-sign"
                        style={{color: (!!spedizione.contrassegno_riconsegnato) ? "#28a745" : "#f62d51"}}
                    ></i><UncontrolledTooltip placement="top" target={`btn-contrassegno-${spedizione.id}`}>
                    contrassegno di {spedizione.contrassegno_importo}€
                </UncontrolledTooltip>
</span> : ''
                }

                <i onClick={() => {
                }} className="fas fa-box-open ml-3"></i>
            </td>
        </tr>
    );
}


SpedizioneListItem.propTypes = {
    spedizione: PropTypes.object.isRequired,
    onRowClick: PropTypes.func.isRequired
};

export default SpedizioneListItem;
