import {all, fork, put, takeLatest} from 'redux-saga/effects';
import {provaSaga} from './provaSaga';
import {contattiSaga} from "../moduli/contatti/saga/contattiSaga";
import {spedizioniSaga} from "../moduli/spedizioni/saga/spedizioniSaga";
import {fattureSaga} from "../moduli/fatture/rest/fattureSaga";
import {corrieriSaga} from "../moduli/corrieri/saga/corrieriSaga";
import {listiniPackSaga} from "../moduli/listini_pack/saga/listino-saga";
import {listiniExtraPackSaga} from "../moduli/listini_extra/saga/listino-extra-saga";
// import * as types from 'actions/actionTypes';
export default function* rootSaga() {
    yield all([
        listiniPackSaga(),
        listiniExtraPackSaga(),
        contattiSaga(),
        spedizioniSaga(),
        fattureSaga(),
        corrieriSaga(),
        provaSaga(),
    ]);
}
