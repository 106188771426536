import React, {Component} from "react";
import logo_prod from "../../assets/images/bmlogo.png";
import logo_staging from "../../assets/images/bmlogo-staging.png";
let logo = (window.ENVIRONMENT==='production' || process.env.REACT_APP_ENVIRONMENT==='production' ) ? logo_prod : logo_staging;

class Splashscreen extends Component {
    render() {
        return (
            <div  style={{height: '100vh',background:''}}>
                <div id={"logo-splashscreen"} className="logo">
                    <span className="db"><img src={logo} alt="logo"/></span>
                </div>
            </div>
        );
    }
}


export default Splashscreen;
