import React, {Fragment} from "react";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import PropTypes from "prop-types";


const PaginationCustom = ({

                              currentPage,
                              handleClick,
                              totali,
                              limite,
                              ...state
                          }) => {


    // console.log("pagecount", pagesCount)
    // console.log("current", currentPage)

    const pagesCount=Math.ceil(totali / limite);
    let pagine=`Pag ${currentPage}/${pagesCount}`;
    let primo = (limite)?((limite*currentPage-limite)+1):0;
    let ultimo = (limite)?limite*currentPage:0;
    ultimo=(totali && totali>ultimo)?ultimo:totali;

    const recors = ` - Records ${primo}-${ultimo} di ${totali}`;


    return <div>
        <p className={"mb-0"}>{pagine} {(ultimo && totali)?`${recors}`:''}</p>

        <Pagination aria-label="Page navigation example" size={"sm"}>
            <PaginationItem disabled={(currentPage <= 2)}>
                <PaginationLink onClick={() => {handleClick(1); }} first href="#"/>
            </PaginationItem>

            <PaginationItem disabled={(currentPage <= 1)}>
                <PaginationLink previous onClick={() => {handleClick(currentPage - 1);}} href="#"/>
            </PaginationItem>

            {(currentPage > 2) ? <PaginationItem>
                <PaginationLink onClick={() => {handleClick(currentPage - 2);}}href="#"> {currentPage - 2}</PaginationLink>
            </PaginationItem> : ''}

            {(currentPage > 1) ? <PaginationItem>
                <PaginationLink onClick={() => {handleClick(currentPage - 1);}}href="#"> {currentPage - 1}</PaginationLink>
            </PaginationItem> : ''}

            <PaginationItem active={true}>
                <PaginationLink href="#">
                    {currentPage}
                </PaginationLink>
            </PaginationItem>
            {(currentPage + 1 <= pagesCount) ? <PaginationItem>
                <PaginationLink
                    onClick={() => {
                        handleClick(currentPage + 1)
                    }}
                    href="#">
                    {currentPage + 1}
                </PaginationLink>
            </PaginationItem> : ''}

            {(currentPage + 2 <= pagesCount) ? <PaginationItem>
                <PaginationLink onClick={() => { handleClick(currentPage + 2)}} href="#">
                    {currentPage + 2}
                </PaginationLink>
            </PaginationItem> : ''}
            <PaginationItem disabled={currentPage===pagesCount}>
                <PaginationLink next onClick={() => { handleClick(currentPage + 1)}} href="#"/>
            </PaginationItem>
            <PaginationItem disabled={currentPage===pagesCount-1}>
                <PaginationLink last onClick={() => { handleClick(pagesCount)}} href="#"/>
            </PaginationItem>
        </Pagination>
    </div>


}
PaginationCustom.propTypes = {
    limite: PropTypes.number.isRequired,
    totali: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handleClick: PropTypes.func.isRequired,
};

export default PaginationCustom;
