import {combineReducers} from "redux";
import {filtro} from "./filtro";
import {fatture} from "./fatture";

export default combineReducers({
    filtro,
    fatture
    // spedizioni,
    // spedizioneDetails,
});
