import { connect } from "react-redux";
// import Search from "../components/Search";
import PropTypes from "prop-types";
import React from "react";
import {getListini, listiniFetch, listiniSearch} from "../../redux/actions";



const Search = ({ value, onChange , _listiniSearch, listiniFetch}) => (
    <input type="text" className="form-control" placeholder="Search listini" onChange={(e) => {
        console.log("eeeeee", e.target.value)
        _listiniSearch(e.target.value);
        listiniFetch()

    }} defaultValue={value} />
);

Search.propTypes = {
   // onChange: PropTypes.func.isRequired
};



const mapStateToProps = (state) => ({
   value: state.listini.listiniSearch
});

// const mapDispatchToProps = (dispatch) => ({
//    onChange: value => dispatch(contactsSearch(value)),
// });
const mapDispatchToProps = {
   _listiniSearch: listiniSearch,
    listiniFetch

   // onChange: value => {
   //    contactsSearch(value);
   //
   //
   //
   //
   //
   // },
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(Search);
