import {all, call, put, takeEvery,takeLatest} from 'redux-saga/effects'
import Api from "../../../rest";
import {
    CONTATTI_AGGIUNGI_LISTA_ACQUISTI,
    CONTATTI_INDIRIZZI_AGGIUNGI,
    CONTATTI_AGGIUNGIA_ALLA_LISTA,
    CONTATTI_GET_LISTA_ACQUISTI,
    CONTATTI_INDIRIZZO_SELEZIONATO,
    CONTATTI_LISTA_AGGIORNATA,
    CONTATTI_RESET_LISTA,
    SAGA_CONTATTI_ADD_ACQUISTI,
    SAGA_CONTATTI_ADD_INDIRIZZO,
    SAGA_CONTATTI_CREATE,
    SAGA_CONTATTI_GET_ACQUISTI,
    SAGA_CONTATTI_SEARCH,
    SAGA_CONTATTI_GET_INDIRIZZI,
    CONTATTI_GET_INDIRIZZI,
    CONTATTI_FILTRO_PAGINATION_COUNT,
    SAGA_CONTATTI_UPDATE,
    SAGA_CONTATTI_DELETE,
    SAGA_CONTATTI_DETTAGLIO
} from "../redux/contatti_redux_types";
import {CONTACT_DETAILS, DELETE_CONTACT, SESSION_FETCHING, SESSION_FETCHING_ERROR, UPDATE_CONTACT} from "../../../redux/constants";


export function* caricaDettaglio(action) {
    let filtro = action.payload.contatto_id;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.contatti.dettaglio, action.payload.contatto_id)

        yield put({type: CONTACT_DETAILS, contatto: result.contatto});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* caricaContatti(action) {
    let filtro = action.payload.filtro;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.contatti.search, filtro)

        // console.log("reeeeeeeeeeeeeee", result)

        if(result.count!==filtro.pagination_count) yield put({type: CONTATTI_FILTRO_PAGINATION_COUNT, count: result.count});
        yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _creaContatto(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api creazione contatto", action.payload.contatto)
        const result = yield call(Api.contatti.create, action.payload.contatto);

        console.log("result api contatto", result)
        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: [result]});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})

    }
}

export function* _updateContatto(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api creazione contatto", action.payload.contatto)
        const result = yield call(Api.contatti.update, action.payload.contatto);

        yield put({type: UPDATE_CONTACT, contatto: action.payload.contatto});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})

    }
}

export function* _deleteContatto(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api delete contatto", action.payload.contatto)
        const result = yield call(Api.contatti.elimina, action.payload.contatto);

        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        yield put({type: DELETE_CONTACT, contatto: action.payload.contatto});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})

    }
}


export function* _getAcquisti(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        console.log("rest api get acquisti del contatto", action.payload.contatto)
        const result = yield call(Api.contatti.getAcquisti, action.payload.contatto_ref,action.payload.attivi);

        yield put({type: CONTATTI_GET_LISTA_ACQUISTI, acquisti: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _getIndirizzi(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        console.log("rest api get indirizzi per contatto", action.payload.indirizzo)
        const result = yield call(Api.contatti.getIndirizzi, action.payload.contatto_ref);

        yield put({type: CONTATTI_GET_INDIRIZZI, indirizzi: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _addAcquisto(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.contatti.createAcquisto, action.payload.acquisto);
        console.log("result", result)
        yield put({type: CONTATTI_AGGIUNGI_LISTA_ACQUISTI, acquisto: result});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}
export function* _addIndirizzo(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        let seleziona = action.payload.seleziona;

        console.log("payload rest crea indirizzo",action.payload)
        const result = yield call(Api.contatti.createIndirizzo, action.payload.indirizzo);
        console.log("result", result)
        yield put({type: CONTATTI_INDIRIZZI_AGGIUNGI, indirizzo: result});
        yield put({type: SESSION_FETCHING, fetching: false});

        if(seleziona){
            console.log("todoooo da propagare select indirizzo")
            yield put({type: CONTATTI_INDIRIZZO_SELEZIONATO, indirizzo: result});
        }



    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* contattiSaga() {
    yield all([
        takeEvery(SAGA_CONTATTI_DETTAGLIO, caricaDettaglio),
        takeEvery(SAGA_CONTATTI_SEARCH, caricaContatti),
        takeLatest(SAGA_CONTATTI_CREATE, _creaContatto),
        takeLatest(SAGA_CONTATTI_UPDATE, _updateContatto),
        takeLatest(SAGA_CONTATTI_DELETE, _deleteContatto),
        takeEvery(SAGA_CONTATTI_GET_ACQUISTI, _getAcquisti),
        takeLatest(SAGA_CONTATTI_ADD_ACQUISTI, _addAcquisto),
        takeLatest(SAGA_CONTATTI_ADD_INDIRIZZO, _addIndirizzo),
        takeEvery(SAGA_CONTATTI_GET_INDIRIZZI, _getIndirizzi)
    ])
}
