import {connect} from 'react-redux';
// import Link from '../components/Link';
import PropTypes from "prop-types";
import React from "react";


const FilterLinkSpedizioni = ({active, onClick, children, filtro, stato}) => {
    let attivo = filtro === stato

    return (
        <li className="list-group-item" onClick={() => {
            onClick(filtro)
        }}>
		<span className={"list-group-item-action " + (attivo ? "active" : "")}>
			{children}
		</span>
        </li>
    );
}

FilterLinkSpedizioni.propTypes = {
    filtro: PropTypes.string.isRequired,
    stato: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};



export default FilterLinkSpedizioni;

// const mapStateToProps = (state, ownProps) => ({
//     active: ownProps.filtro === ownProps.stato,
// })
//
// const mapDispatchToProps = {
//     // setVisibilityFilter,
//     // fetchContatti
// }
//
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(FilterLinkSpedizioni)
