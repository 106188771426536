import { connect } from "react-redux";
import listExtraDetails from "../components/ListExtraDetailsComponent";
import {aggiornaListino, getTipoExtraPerListExtra, riordinaTipoExtra, setEditListinoFlag, updateListino} from "../../redux/actions";


function getListinoSelezionato(_listini,_id_listino_scelto) {
   let _listino_scelto = undefined;
   _listini.forEach(_c=>{
      // console.log("x",_c)
      if(_c.id===_id_listino_scelto){
         _listino_scelto = _c;
      }
   });
   return _listino_scelto;
}



const mapStateToProps = (state, ownProps) => ({
   selectedListino: getListinoSelezionato(state.listextra.listini, state.listextra.listextraDetails),
   tipoExtras: state.listextra.tipoExtraPerListExtra,
   editListExtraFlag: state.listextra.editListExtra,
});

const mapDispatchToProps = {
   // onEditClick: () => dispatch(setEditContactFlag()),
   attivaModificaClick: setEditListinoFlag,
   aggiornaListino,
   onChange: updateListino,
   getTipoExtraPerListExtra,
   riordinaTipoExtra

   // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(listExtraDetails);
