import {connect} from "react-redux";
import listinoDetails from "../components/listinoDetails";
import {aggiornaListino, getTipoSpedizioniPerListino, riordinaTipoSpedizioni, setEditListinoFlag, updateListino} from "../../redux/actions";


function getListinoSelezionato(_contatti, _contact_id) {
    let _Contatto = undefined;
    _contatti.forEach(_c => {
        if (_c.id == _contact_id) _Contatto = _c;
    });
    return _Contatto;
}


const mapStateToProps = (state, ownProps) => ({
    selectedListino: getListinoSelezionato(state.listini.listini, state.listini.listinoDetails),
    tipoSpedizioni: state.listini.tipoSpedizioniPerListino,
    editListinoFlag: state.listini.editListino,
});

const mapDispatchToProps = {
    // onEditClick: () => dispatch(setEditContactFlag()),
    onEditClick: setEditListinoFlag,
    aggiornaListino: aggiornaListino,
    onChange: updateListino,
    getTipoSpedizioniPerListino,
    riordinaTipoSpedizioni

    // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(listinoDetails);
