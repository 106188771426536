import { EDIT_CORRIERE, CORRIERE_DETAILS } from '../../../redux/constants';
const EditCorriere = (state = false, action) => {
	switch (action.type) {
		case EDIT_CORRIERE:
			return !state ? true : false;
		case CORRIERE_DETAILS:
			return state = false;
		default:
			return state;
	}
};

export default EditCorriere;
