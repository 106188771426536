import React from "react";
import {
	Card,
	CardBody,

} from 'reactstrap';
import {connect} from "react-redux";

class Dashboard extends React.Component {

	componentDidMount() {

		// console.log(this.props)
	}


	render() {
		let stringa = "Start your project from here... ";
		return (
			<Card>
				<CardBody>
					{stringa}
				</CardBody>
			</Card>
		);
	}
}

const mapStateToProps = state=>({
	// ...state
});
const mapDispatchToProps = {
	// prova: prova
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
