import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import user1 from '../../../../assets/images/users/1.jpg';
import API from "../../../../rest";

const ListExtra = ({
                     onListinoClick,
                     onStarredClick,
                     onDeleteClick,
                     id,
                     nome,
                     image,
                     starred,
                     active,
                     ...state
                 }) => {

    const [immagineProfilo, setImmagineProfilo] = useState(user1);
    useEffect(() => {
        if (image != null) {
            API.listini_extra.getImage(id, image).then((r) => {
                if (r) setImmagineProfilo(r);
                else setImmagineProfilo(user1);
            });
        }
    }, [image]); // passing an empty array as

    return (
        <tr className={((active === id) ? "bg-light" : "")}>
            <td onClick={onListinoClick} style={{width: 70 + 'px'}}>
                <img src={immagineProfilo} className="rounded-circle" width="50" height="50" alt={""}/>
            </td>
            <td onClick={onListinoClick}>
                <p className="mb-0 text-truncate">
                    {nome}
                </p>
                <p className="mb-0 text-muted">DESCRIZIONE</p>
            </td>
            <td className="text-center" style={{width: 70 + 'px'}}>
                <i
                    onClick={onStarredClick}
                    className="fas fa-star"
                    style={{color: starred ? "#FFC107" : "#495057"}}
                ></i>
                <i onClick={onDeleteClick} className="fas fa-trash-alt ml-3"></i>
            </td>
        </tr>
    );
}


ListExtra.propTypes = {
    nome: PropTypes.string.isRequired,
    onStarredClick: PropTypes.func.isRequired,
    onDeleteClick: PropTypes.func.isRequired,
    onListinoClick: PropTypes.func.isRequired
};

export default ListExtra;
