import {ADD_SPEDIZIONE, DELETE_SPEDIZIONE, SPEDIZIONI_AGGIUNGIA_ALLA_LISTA, SPEDIZIONI_RESET_LISTA, UPDATE_SPEDIZIONE} from "./spedizioni_redux_types";


const spedizioni = (state = [], action) => {
    switch (action.type) {
        case SPEDIZIONI_RESET_LISTA:
            return action.spedizioni;
        case SPEDIZIONI_AGGIUNGIA_ALLA_LISTA:
            return [...state.concat(action.spedizioni)];
        case ADD_SPEDIZIONE:
            return [
                ...state,
                {
                    id: action.spedizione.id,
                }
            ];

        case UPDATE_SPEDIZIONE:
            console.log("action", action)
            return state.map(spedizione => (spedizione.id === action.spedizione.id ? action.spedizione : spedizione));


        case DELETE_SPEDIZIONE:
            return state.map(spedizione => (spedizione.id === action.id ? {...spedizione, deleted: !spedizione.deleted} : spedizione));

        default:
            return state;
    }
};


export {spedizioni};
