import React from "react";
import { Modal, ModalHeader, ListGroup, ListGroupItem } from "reactstrap";
import FilterLink from "../containers/listiniFilterLink";
import {listiniVisiblityFilter} from "../../redux/actions";
import AddListino from "../containers/addListino";

class ListiniFilter extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: false
		};

		this.toggle = this.toggle.bind(this);
	}

	toggle() {
		this.setState({
			modal: !this.state.modal
		});
	}
	render() {
		return (
			<div>
				<div className="p-3">
					<span onClick={this.toggle} className="btn btn-danger d-block text-white">Nuovo Listino</span>
				</div>
				<div className="divider"></div>
				<ListGroup className="contact-filters">
					<FilterLink filter={listiniVisiblityFilter.SHOW_ALL}>
						<i className="fas fa-money-check-alt mr-2"></i> All
                        </FilterLink>
					<FilterLink filter={listiniVisiblityFilter.STARRED_PACK}>
						<i className="fas fa-star mr-2"></i> Starred
                        </FilterLink>
					<ListGroupItem>
						<hr />
					</ListGroupItem>
					<FilterLink
						filter={listiniVisiblityFilter.TIPOLOGIA_LISTINI_PREPAGATI}
						departmentValue="PrePagati"
					>
						<i className="fas fa-tags mr-2"></i> PrePagati
                        </FilterLink>
					<FilterLink filter={listiniVisiblityFilter.TIPOLOGIA_LISTINI_POSTPAGATI} departmentValue="PostPagati">
						<i className="fas fa-tags mr-2"></i> PostPagati
                        </FilterLink>
				</ListGroup>
				<Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
					<ModalHeader toggle={this.toggle}>Aggiungi Listino</ModalHeader>
					<AddListino onListinoAggiunto={this.toggle}/>
				</Modal>
			</div>
		);
	}
}

export default ListiniFilter;
