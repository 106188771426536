import { connect } from 'react-redux';
// import {getContacts, setVisibilityFilter, setVisibilityFilter} from '../../../redux/contacts/action';
// import Link from '../components/Link';
import PropTypes from "prop-types";
import React from "react";
import {getListini, listiniFetch, setListiniVisibilityFilter} from "../../redux/actions";


const Link = ({ active, children, onClick , filter,listiniFetch,setFilt}) => {

  return (
      <li className="list-group-item" onClick={()=>{setFilt(filter);listiniFetch()}}>
		<span className={"list-group-item-action " + (active ? "active" : "")}>
			{children}
		</span>
      </li>
  );
}

Link.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};


const mapStateToProps = (state, ownProps) => ({
  active: ownProps.filter === state.listini.listiniVisibilityFilter,
  filter: ownProps.filter
})

const mapDispatchToProps = {
  setFilt: setListiniVisibilityFilter,
  listiniFetch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)
