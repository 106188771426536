import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import Splashscreen from "../splashscreen/splashscreen";

import {restore} from "../../redux/session/action";
import Fetching from "../splashscreen/fetching";
import {BrowserRouter as BrowsRouter, Redirect, Switch} from "react-router-dom";
import AllRoutes from "../../routes/routes_generiche";
import ProtectedRoute from "../../routes/ProtectedRoute";



class Home extends Component {
    componentDidMount() {
        setTimeout(()=>{
            this.props.restore()

        },0)
    }

    render() {
        return <Fragment>
            {(this.props.fetching_error)?<div>{this.props.fetching_error}</div>:''}
            {(this.props.loading)?<Splashscreen/>:<BrowsRouter forceRefresh={false}  basename="/" >
                <Switch>
                    {
                        AllRoutes.map((prop, key) => {
                            if (prop.navlabel) return null;
                            else if(prop.redirect) return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
                            else return <ProtectedRoute protected={prop.protected} path={prop.path} key={key} layout={prop.layout}  component={prop.component}/>;
                        })
                    }
                </Switch>
            </BrowsRouter>}

            {(this.props.fetching>0)?<Fetching/>:''}
        </Fragment>


    }
}


const mapStatToProps = (state) => {
    return {
        loading: state.session.loading,
        user: state.session.user,
        fetching: state.session.fetching_sessions,
        fetching_error: state.session.fetching_error,
    }
}


const mapDispatchToProps = {
    restore
}


export default connect(mapStatToProps, mapDispatchToProps)(Home);
