import getAxios from "../../../rest/myaxios";
import {
    REST_CONTATTI_DELETE,
    REST_FATTURE_BATCH_CONTATTI, REST_FATTURE_BATCH_CREATE,
    REST_FATTURE_CREATE, REST_FATTURE_DELETE, REST_FATTURE_DETTAGLIO,
    REST_FATTURE_SEARCH, REST_FATTURE_UPDATE,
    REST_SPEDIZIONI_CREATE, REST_SPEDIZIONI_SEARCH, REST_SPEDIZIONI_UPDATE
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _search(filtro) {

    //TODOGP gestire ricerca per filtro

    try {
        let request = await axios.get(REST_FATTURE_SEARCH, {
            params: {
                filtro,
                // limit,
                // page
            }
        });
        return request.data.fatture;
    } catch (e) {
        console.error("wwwwww",e)
        throw new Error('Error Fetchingddd Api Server')
    }

}

async function _create(fattura) {
    let data = new FormData();
    data.append('fattura', JSON.stringify(fattura));
    try {
        let request = await axios.post(REST_FATTURE_CREATE, data);
        return request.data;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}


async function _update(fattura) {


    // console.log("spedizione pre from", spedizione)

    // inserire formdata
    let data = new FormData();
    data.append('fattura', JSON.stringify(fattura));


    try {
        let request = await axios.post(REST_FATTURE_UPDATE, data);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        console.log("request",request);
        return request.data;
    } catch (e) {

        throw new Error('Error Fetching Api Server')
    }

}




async function _dettaglioRest(fattura) {

    try {
        let request = await axios.get(`${REST_FATTURE_DETTAGLIO}/${fattura}`);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        console.log("request dettaglio spedizione",request);
        return request.data;
    } catch (e) {

        throw new Error('Error Fetching Api Server')
    }

}

async function _batchSearchContatti(filtro) {

    console.log("filtro ",filtro)
    try {
        let request = await axios.get(REST_FATTURE_BATCH_CONTATTI, {
            params: {
                filtro,
                // limit,
                // page
            }
        });
        return request.data.contatti;
    } catch (e) {
        console.error("wwwwww",e)
        throw new Error('Error Fetchingddd Api Server')
    }

}


async function _batchCreate(filtro) {
    // console.log("spedizione pre from", spedizione)

    // inserire formdata
    let data = new FormData();
    data.append('dati', JSON.stringify(filtro));

    try {
        let request = await axios.post(REST_FATTURE_BATCH_CREATE, data);
        // console.log("request",request);
        return request.data;
    } catch (e) {

        throw new Error('Error Fetching Api Server')
    }
}

async function _elimina(fattura_id) {
    let data = new FormData();
    data.append('fattura', fattura_id);
    try {
        let request = await axios.delete(REST_FATTURE_DELETE, {data:{fattura_id:fattura_id}});
        return request.data.contatto;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}



export const search = _search;
export const create = _create;
export const update = _update;
export const elimina = _elimina;
export const dettaglio = _dettaglioRest;
export const batch_search = _batchSearchContatti;
export const batch_create = _batchCreate;

