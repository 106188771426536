export const SAGA_SPEDIZIONI_SEARCH = "SAGA_SPEDIZIONI_SEARCH";
export const SAGA_SPEDIZIONI_CREATE = "SAGA_SPEDIZIONI_CREATE";
export const SAGA_SPEDIZIONE_UPDATE = "SAGA_SPEDIZIONE_UPDATE";

export const ADD_SPEDIZIONE = "ADD_SPEDIZIONE";
export const DELETE_SPEDIZIONE = "DELETE_SPEDIZIONE";
export const SPEDIZIONE_SELEZIONATA = "SPEDIZIONE_SELEZIONATA";
export const UPDATE_SPEDIZIONE = "UPDATE_SPEDIZIONE";
export const SPEDIZIONI_LAZY_LOADING = "SPEDIZIONI_LAZY_LOADING";
export const SPEDIZIONI_RESET_LISTA = "SPEDIZIONI_RESET_LISTA";
export const SPEDIZIONI_AGGIUNGIA_ALLA_LISTA = "SPEDIZIONI_AGGIUNGIA_ALLA_LISTA";


export const SPEDIZIONI_SET_FILTRO = "SPEDIZIONI_SET_FILTRO";
export const SPEDIZIONI_FILTRO_RESET = "SPEDIZIONI_FILTRO_RESET";
export const SPEDIZIONI_FILTRO_DATA = "SPEDIZIONI_FILTRO_DATA";
export const SPEDIZIONI_FILTRO_STATO = "SPEDIZIONI_FILTRO_STATO";
export const SPEDIZIONI_FILTRO_CONTRASSEGNO = "SPEDIZIONI_FILTRO_CONTRASSEGNO";
export const SPEDIZIONI_FILTRO_TIPOLOGIA = "SPEDIZIONI_FILTRO_TIPOLOGIA";
export const SPEDIZIONI_FILTRO_PAGAMENTO = "SPEDIZIONI_FILTRO_PAGAMENTO";
export const SPEDIZIONI_FILTRO_CORRIERE = "SPEDIZIONI_FILTRO_CORRIERE";
export const SPEDIZIONI_FILTRO_CONTATTO = "SPEDIZIONI_FILTRO_CONTATTO";
export const SPEDIZIONI_FILTRO_LOAD_PAGE = "SPEDIZIONI_FILTRO_LOAD_PAGE";
export const SPEDIZIONI_FILTRO_LIMIT = "SPEDIZIONI_FILTRO_LIMIT";
export const SPEDIZIONI_FILTRO_PAGINATION_COUNT = "SPEDIZIONI_FILTRO_PAGINATION_COUNT";


export const SPEDIZIONI_FILTRO_STATO_ENUM = {
    ALL: "SPEDIZIONI_STATO_ENUM_ALL___999",
    APERTE: "SPEDIZIONI_STATO_ENUM_APERTE___0",
    CONSEGNATE: "SPEDIZIONI_STATO_ENUM_CONSEGNATE___1",
    ANNULLATE: "SPEDIZIONI_STATO_ENUM_ANNULLATE___2",
    DA_CONTROLLARE: "SPEDIZIONI_STATO_ENUM_DA_CONTROLLARE___3",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'aperta',
                    code: 'AP',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'consegnata',
                    code: 'CO',
                    color: 'success'
                };
            case 2:
                return {
                    val: 2,
                    label: 'annullata',
                    code: 'AN',
                    color: 'secondary'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};

export const SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM = {
    ALL: "SPEDIZIONI_TIPOLOGIA_ENUM_ALL___999",
    PREPAGATE: "SPEDIZIONI_TIPOLOGIA_ENUM_PREPAGATE___0",
    POSTPAGATE: "SPEDIZIONI_TIPOLOGIA_ENUM_POSTPAGATE___1",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'prepagate',
                    code: 'PO',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'postpagate',
                    code: 'PRE',
                    color: 'light'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};

export const SPEDIZIONI_FILTRO_PAGAMENTO_ENUM = {
    ALL: "SPEDIZIONI_PAGAMENTO_ENUM_ALL___999",
    NON_PAGATE: "SPEDIZIONI_PAGAMENTO_ENUM_NON_PAGATE___0",
    PAGATE: "SPEDIZIONI_PAGAMENTO_ENUM_PAGATE___1",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'non pagate',
                    code: 'NP',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'pagate',
                    code: 'PA',
                    color: 'light'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};



export const getStatoLabel = (stato) => {

}


export const SPEDIZIONI_FILTRO_DATA_ENUM = {
    ALL: "SPEDIZIONI_DATA_ENUM_ALL",
    ODIERNE: "SPEDIZIONI_DATA_ENUM_ODIERNE",
    RANGE: "SPEDIZIONI_DATA_ENUM_RANGE",
};
