import {combineReducers} from "redux";
import {listini, lazy, json} from "./listiniextras";
import listextraSearch from "./listextraSearch";
import listextraDetails from "./listextraDetails";
import listextraVisibilityFilter from "./listextraVisibilityFilter";
import editListExtra from "./editListino";
import tipoExtraPerListExtra from "./tipoExtraPerListExtra";

export default combineReducers({
    listini,
    lazy,
    json,
    listextraDetails,
    listextraVisibilityFilter,
    listextraSearch,
    editListExtra: editListExtra,
    tipoExtraPerListExtra
});
