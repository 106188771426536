import * as contatti from '../moduli/contatti/rest/contatti';
import * as corrieri from '../moduli/corrieri/rest/corrieri';
import * as spedizioni from '../moduli/spedizioni/rest/spedizioni';
import * as settings from '../moduli/settings/rest/settings-rest';
import * as fatture from '../moduli/fatture/rest/fattureRest';
import * as listini_pack from '../moduli/listini_pack/rest/listino-pack-rest';
import * as listini_extra from '../moduli/listini_extra/rest/listino-extra-rest';

const API = {
    contatti,
    corrieri,
    spedizioni,
    settings,
    fatture,
    listini_pack,
    listini_extra
}


export default API;
