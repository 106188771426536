import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {listiniPackGetJson} from "../../../listini_pack/redux/actions";
import {listiniExtraGetJson} from "../../../listini_extra/redux/actions";
import {Button, Col, CustomInput, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, ModalBody, ModalFooter, Row} from "reactstrap";
import Select from "react-select";
import {addAcquisto, getDettagliContatto} from "../../redux/action";

function getContattoSelezionato(_contatti, _contact_id) {
    let _Contatto = undefined;
    _contatti.forEach(_c => {
        if (_c.id == _contact_id) _Contatto = _c;
    });
    return _Contatto;
}


const AddPackToContact = ({contatto, dettagli_contatto, getDettagliContatto, _packs, _extras, loadPacks, loadExtras, addAcquisto, ...props}) => {

    const [packs, setPacks] = useState([{value: 0, label: "-----"}]);
    const [packScelto, setPackScelto] = useState({value: 0, label: "-----"});

    const [extras, setExtras] = useState([{value: 0, label: "-----"}]);
    const [extraScelto, setExtraScelto] = useState({value: 0, label: "-----"});

    //ERRORI
    const [errorPack, setErrorPack] = useState(false);
    const [errorExtra, setErrorExtra] = useState(false);
    const [errorCredito, setErrorCredito] = useState(false);
    const [errorAddebito, setErrorAddebito] = useState(false);
    const [errorSaldato, setErrorSaldato] = useState(false);

    const [creditoErogato, setCreditoErogato] = useState(0);
    const [importoDaPagare, setImportoDaPagare] = useState(0);
    const [importoSaldato, setImportoSaldato] = useState(0);
    const [postpagato, setPostpagato] = useState(false);

    const [pacchettiNegativi, setPacchettiNegativi] = useState([]);
    const [creditoAdjustment, setCreditoAdjustment] = useState(0)

    // console.log("contato pacchetti negativi", contatto.pacchetti_negativi)
    useEffect(() => {
        getDettagliContatto(contatto.id);
        loadPacks()
        loadExtras()
    }, [contatto]);


    useEffect(() => {
        if (dettagli_contatto.pacchetti_negativi) {

            let pacchetti = [];


            dettagli_contatto.pacchetti_negativi.map((o) => {
                pacchetti.push({
                    id: o.id,
                    pacchetto: `#${o.id} | ${o.listinoPack.nome} | saldo:${o.saldo}€`,
                    saldo: o.saldo,
                    checked: false
                })
            });

            setPacchettiNegativi(pacchetti);
        }

    }, [dettagli_contatto]);

    useEffect(() => {
        let tot = 0;
        pacchettiNegativi.map((o)=>{
            if(o.checked) tot+=Math.abs(o.saldo);
        })
        setCreditoAdjustment(tot);

    }, [JSON.stringify(pacchettiNegativi),creditoErogato]);

    useEffect(() => {
        const ext = [{value: 0, label: "-----"}].concat(_packs.map(o => ({value: o.id, label: o.nome})));
        setPacks([...ext])
    }, [_packs]);

    useEffect(() => {
        const ext = [{value: 0, label: "-----"}].concat(_extras.map(o => ({value: o.id, label: o.nome})));
        setExtras([...ext])
    }, [_extras]);


    useEffect(() => {
        _packs.forEach((o, i) => {
            if (o.id === contatto.listino_pack_pref && packScelto.value === 0) {
                setPackScelto({value: o.id, label: o.nome});
                setCreditoErogato(o.prezzo);
                setImportoDaPagare(o.prezzo);
                setPostpagato(o.postpagato);
            }
        });
    }, [packs]);
    useEffect(() => {
        extras.forEach((o, i) => {
            if (o.value === contatto.listino_extra_pref && extraScelto.value === 0) {
                setExtraScelto({...o})
            }
        });
    }, [extras]);


    const onSubmit = (e) => {
        e.preventDefault();

        setErrorPack((packScelto.value === 0))
        setErrorExtra((extraScelto.value === 0))

        setErrorCredito(creditoErogato === "");
        setErrorAddebito(importoDaPagare === "");
        setErrorSaldato(importoSaldato === "");

        //controllo i dati sul credito


        if (packScelto.value !== 0 && extraScelto.value !== 0) {
            const _pack = {
                contatto_ref: contatto.id,
                listino_pack_ref: packScelto.value,
                listino_extra_ref: extraScelto.value,
                postpagato,
                importo_accreditato: creditoErogato-creditoAdjustment,
                importo_pagato: importoDaPagare,
                importo_saldato: importoSaldato,
                note:''
            }
            // salvo i dati

            _pack.pacchetti_negativi=[];
            let note = "";
            pacchettiNegativi.map(o=>{
                if(o.checked) _pack.pacchetti_negativi.push(o.id);
                note += `#${o.id} ${o.saldo}€; `;
            })

            if(note!=='')
            _pack.note=`effettuato adjustment sui seguenti pacchetti: ${note} | totale: ${creditoAdjustment}€`;


            addAcquisto(_pack)
            console.log("acquisto", _pack)


        }

    }


    return <React.Fragment>

        <Form
            onSubmit={onSubmit}
        >
            <ModalBody>

                <Row>
                    <Col md={6}>
                        <FormGroup className="">
                            <Select
                                defaultValue={packScelto}
                                value={packScelto}
                                placeholder={"Scegli Listino Pack"}
                                name="listino_pack"
                                id="listino_pack"
                                options={packs}
                                onChange={(e => {
                                    setPackScelto(e);
                                    _packs.map((o) => {
                                        console.log(o, e)
                                        if (e.value === o.id) {
                                            console.log("pack scelto", o)
                                            setCreditoErogato(o.prezzo)
                                            setImportoDaPagare(o.prezzo)
                                            setPostpagato(o.postpagato)
                                        }
                                    })

                                })}
                            />
                            <p className="text-center mt-1 mb-3 ">
                                <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Listino Pack</small>
                                {(errorPack) ? <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>


                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Select
                                defaultValue={{...extraScelto}}
                                value={extraScelto}
                                placeholder={"Scegli Listino Extra"}
                                name="listino_extra"
                                id="listino_extra"
                                options={extras}
                                onChange={(e => {
                                    setExtraScelto(e)


                                })}
                            />
                            <p className="text-center mt-1 mb-3 ">
                                <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Listino Extra</small>
                                {(errorExtra) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>
                    </Col>
                </Row>


                {!postpagato && pacchettiNegativi.length > 0 ? <Fragment>
                        <h3 color={"red"}> Attenzione son presenti pacchetti in negativo:</h3>
                        {pacchettiNegativi.map((o) => {
                            return <Row key={o.id} className={"mb-1"}>
                                <Col md={12}>
                                    <CustomInput type="checkbox" checked={o.checked} id={`check${o.id}`} label={o.pacchetto}
                                                 onChange={(e) => {
                                                     console.log("e", e.target.checked)
                                                     let pacchetti = []

                                                     pacchettiNegativi.map((p) => {
                                                         if (p.id === o.id) p.checked = (e.target.checked)
                                                         pacchetti.push(p)
                                                     })

                                                     setPacchettiNegativi(pacchetti)

                                                     // console.log(e.target.value)

                                                 }}/>

                                </Col>
                            </Row>
                        })}

                        <Row className={"mt-4 mb-4"}>
                            <Col md={12}>N.B. Seleziona i pacchetti per cui vuoi fare l'adjustment - il credito verra scalato in automatico dal credito
                                erogato)</Col>
                            <Col md={12}>
                                <h4>il credito erogato sara' di {creditoErogato - creditoAdjustment}€.</h4>
                            </Col>
                        </Row>

                    </Fragment>
                    : ''}


                <Row className={postpagato ? "hide" : ""}>
                    <Col md={4}>
                        {/*credito erogato*/}
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-book"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="credito_erogato"
                                    id="credito_erogato"
                                    // defaultValue={creditoErogato}
                                    value={creditoErogato}
                                    // ref={node => (credito_erogato = node)}
                                    placeholder="Credito erogato"
                                    onChange={(e) => {
                                        setCreditoErogato(e.target.value)
                                    }}
                                    // onBlur={this.validationCheck}
                                /> </InputGroup>
                            <p className="text-center mb-3 ">
                                <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Credito erogato</small>
                                {(errorCredito) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>

                    </Col>
                    <Col md={4}>
                        {/*    quanto viene addebitato al cliente*/}
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-book"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="importo_da_pagare"
                                    id="importo_da_pagare"
                                    // defaultValue={creditoErogato}
                                    value={importoDaPagare}
                                    // ref={node => (credito_erogato = node)}
                                    placeholder="Importo da pagare"
                                    onChange={(e) => {
                                        setImportoDaPagare(e.target.value)
                                    }}
                                    // onBlur={this.validationCheck}
                                /> </InputGroup>
                            <p className="text-center mb-3 ">
                                <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Importo da pagare</small>
                                {(errorAddebito) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>

                    </Col>
                    <Col md={4}>
                        {/*importo saldato dal cliente */}
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-book"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <input
                                    className="form-control"
                                    type="number"
                                    min={0}
                                    step={0.01}
                                    name="importo_saldato"
                                    id="importo_saldato"
                                    // defaultValue={creditoErogato}
                                    value={importoSaldato}
                                    // ref={node => (credito_erogato = node)}
                                    placeholder="Importo dal cliente"
                                    onChange={(e) => {
                                        setImportoSaldato(e.target.value)
                                    }}
                                    // onBlur={this.validationCheck}
                                /> </InputGroup>
                            <p className="text-center mb-3 ">
                                <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Importo saldato dal cliente</small>
                                {(errorSaldato) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>


                    </Col>


                </Row>


            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="submit">
                    Aggiungi pacchetto
                </Button>
            </ModalFooter>
        </Form>


    </React.Fragment>
}

const mapStateToProps = state => ({
    _packs: state.listini.json,
    _extras: state.listextra.json,
    dettagli_contatto: state.contactReducer.contactsDetails,

});

const mapDispatchToProps = {
    loadPacks: listiniPackGetJson,
    loadExtras: listiniExtraGetJson,
    addAcquisto,
    getDettagliContatto

}

export default connect(mapStateToProps, mapDispatchToProps)(AddPackToContact);
