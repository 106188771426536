import React from "react";
import FilterSearchContainer from "../containers/FilterSearchContainer";

class ListExtraSearchComponent extends React.Component {
	render() {
		return (
			<div className="bg-light p-4">
				<FilterSearchContainer />
			</div>
		);
	}
}

export default ListExtraSearchComponent;
