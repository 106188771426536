import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import Api from "../../../rest";
import {
    LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA,
    LISTINI_EXTRA_JSON,
    LISTINI_EXTRA_RESET_LISTA,
    SAGA_LISTINI_EXTRA_ADD,
    SAGA_LISTINI_EXTRA_JSON,
    SAGA_LISTINI_EXTRA_SEARCH,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_GET,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE
} from "../redux/listini-extra-redux-types";
import {ADD_TIPO_EXTRA_A_LIST_EXTRA, TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED, UPDATE_LIST_EXTRA, UPDATE_TIPO_EXTRA_A_LIST_EXTRA} from "../redux/_constant";
import {SESSION_FETCHING, SESSION_FETCHING_ERROR} from "../../../redux/constants";


export function* fetchListiniExtraPack(action) {
    let search = action.payload.search;
    let filtro = action.payload.filtro;
    let lazy = action.payload.lazy;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_extra.search, search, filtro, lazy)

        if (lazy === 1) yield put({type: LISTINI_EXTRA_RESET_LISTA, listini: result.rows});
        else yield put({type: LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA, listini: result.rows});

        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* createListiniExtraPack(action) {
    let listino = action.payload.listino_extra;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_extra.create, listino)

        console.log(result)


        yield put({type: LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA, listini: [result]});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* getJsonListiniExtra(action) {
    yield put({type: SESSION_FETCHING, fetching: true});


    try {
        const result = yield call(Api.listini_extra.json)
        yield put({type: LISTINI_EXTRA_JSON, listini: result.rows});

        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* getTipologiaListiniExtra(action) {
    let listinoExtraRef = action.payload.listino_extra_ref;
    yield put({type: SESSION_FETCHING, fetching: true});

    console.log("listinoExtraRef", listinoExtraRef)
    try {
        const result = yield call(Api.listini_extra.getTipologie, listinoExtraRef)
        yield put({type: TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED, tipologie_extra: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* createTipologiaListiniExtra(action) {
    let tipologia_pack = action.payload.tipologia_extra;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const tipologia_creata = yield call(Api.listini_extra.creaTipologia, tipologia_pack)

        // console.log("tipologia_creata", tipologia_creata)
        yield put({type: ADD_TIPO_EXTRA_A_LIST_EXTRA, tipologia_extra: tipologia_creata});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* updateTipologiaListiniExtra(action) {
    let tipologia_extra = action.payload.tipologia_extra;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const tipologia_modificata = yield call(Api.listini_extra.updateTipologia, tipologia_extra)

        yield put({type: UPDATE_TIPO_EXTRA_A_LIST_EXTRA, tipologia: tipologia_extra});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* riordinaTipologiaListiniExtra(action) {

    let tipologie_extra = action.payload.tipologie_extra;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        yield call(Api.listini_extra.riordinaTipologieExtra, tipologie_extra)
        //TODOGP inserire controllo sulla risposta al rest del riordino tipoligie
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }}


export function* listiniExtraPackSaga() {
    yield all([
        takeEvery(SAGA_LISTINI_EXTRA_SEARCH, fetchListiniExtraPack),
        takeEvery(SAGA_LISTINI_EXTRA_JSON, getJsonListiniExtra),
        takeLatest(SAGA_LISTINI_EXTRA_ADD, createListiniExtraPack),
        takeEvery(SAGA_LISTINI_EXTRA_TIPOLOGIE_GET, getTipologiaListiniExtra),
        takeLatest(SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE, createTipologiaListiniExtra),
        takeLatest(SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE, updateTipologiaListiniExtra),
        takeLatest(SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA, riordinaTipologiaListiniExtra),
    ])
}
