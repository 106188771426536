import {ADD_CONTACT, UPDATE_CONTACT, TOGGLE_STARRED_CONTACT, DELETE_CONTACT} from '../../../redux/constants';
import {
    CONTATTI_AGGIUNGI_LISTA_ACQUISTI, CONTATTI_INDIRIZZI_AGGIUNGI,
    CONTATTI_AGGIUNGIA_ALLA_LISTA, CONTATTI_GET_INDIRIZZI,
    CONTATTI_GET_LISTA_ACQUISTI,
    CONTATTI_LAZY_LOADING,
    CONTATTI_RESET_LISTA, CONTATTI_INDIRIZZO_SELEZIONATO
} from "./contatti_redux_types";

const contacts = (state = [], action) => {
    switch (action.type) {
        case CONTATTI_RESET_LISTA:
            return action.contacts;
        case CONTATTI_AGGIUNGIA_ALLA_LISTA:
            return [...state.concat(action.contacts)];
        case ADD_CONTACT:
            return [
                ...state,
                {
                    id: action.contatto.id,
                    firstname: action.contatto.firstname,
                    lastname: action.contatto.lastname,
                    image: action.contatto.image,
                    department: action.contatto.department,
                    company: action.contatto.company,
                    phone: action.contatto.phone,
                    email: action.contatto.email,
                    address: action.contatto.address,
                    notes: action.contatto.notes,
                    frequentlycontacted: false,
                    starred: false,
                    deleted: false
                }
            ];

        case UPDATE_CONTACT:
            // console.log(action)
            // console.log(state)
            return state.map(contact => (contact.id === action.contatto.id ? action.contatto : contact));

        case TOGGLE_STARRED_CONTACT:
            return state.map(contact => (contact.id === action.id ? {...contact, starred: !contact.starred} : contact));

        case DELETE_CONTACT:

            let contatti=[];
            state.map(contact => {
                if(contact.id!==action.contatto.id)contatti.push(contact);
            });
            console.log("action",action)



            // return state.map(contact => (contact.id === action.contatto.id ? null: contact));
            return contatti;

        default:
            return state;
    }
};

const lazy = (state = 1, action) => {
    switch (action.type) {
        case CONTATTI_LAZY_LOADING:
            if (action.reset) return 1;
            return state + 1;//questa rappresenta la pagina
        default:
            return state;
    }

}


const acquisti = (state = [], action) => {
    switch (action.type) {
        case CONTATTI_GET_LISTA_ACQUISTI:
            return [...action.acquisti];
        case CONTATTI_AGGIUNGI_LISTA_ACQUISTI:
            // console.log("acquisto", action)
            return [...state.concat(action.acquisto)];
            return [...state];
        default:
            return state;
    }
};


const indirizzi = (state = [], action) => {
    switch (action.type) {
        case CONTATTI_GET_INDIRIZZI:
            return [...action.indirizzi];
        case CONTATTI_INDIRIZZI_AGGIUNGI:
            return [...state.concat(action.indirizzo)];
        default:
            return state;
    }
};

const indirizzo = (state = null, action) => {
    switch (action.type) {
        case CONTATTI_INDIRIZZO_SELEZIONATO:
            if(action.indirizzo === undefined) return null;
            else return action.indirizzo;
        default:
            return state;
    }

}


export {contacts, lazy, acquisti, indirizzo, indirizzi};
