import * as rest from './rest-constants';
import axios from 'axios';
import {logout} from "../redux/session/action";

// console.log("rest api", rest.API_URL)
function getAxios() {
    const adapter = axios.create({
        baseURL: rest.API_URL,
        headers: {
            Accept: 'application/json;charset=UTF-8',
        },
    });

    const token = localStorage.getItem('token');

    if(token) adapter.defaults.headers.common['Authorization'] = `Bearer ${token}`;



    adapter.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if(error.response.status===401 || error.response.status===403 ){
            localStorage.removeItem('token');
            window.location="/";
        }
        return Promise.reject(error);
    });







    return adapter;

}

export default getAxios;
