import {ListExtrasVisiblityFilter} from "./actions";
import {SET_VISIBILITY_FILTER_LIST_EXTRAS} from "./_constant";

const ListExtraVisiblityFilter = (state = ListExtrasVisiblityFilter.SHOW_ALL, action) => {
	switch (action.type) {
		case SET_VISIBILITY_FILTER_LIST_EXTRAS:
			return action.filter
		default:
			return state
	}
}

export default ListExtraVisiblityFilter;
