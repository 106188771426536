import {LISTINO_DETAILS} from "./_constant";

const ListinoDetails = (state = '', action) => {
	switch (action.type) {
		case LISTINO_DETAILS:
			return action.id;
		default:
			return state;
	}
};

export default ListinoDetails;
