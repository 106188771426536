import { combineReducers } from 'redux';
import settings from './settings/reducer';
import session from './session/reducer';
import {firebaseReducer} from "react-redux-firebase";
import {firestoreReducer} from "redux-firestore";
import contactReducer from "../moduli/contatti/redux/";
import corrieri from "../moduli/corrieri/redux/";
import appuntamentiReducer from "./appuntamenti/";
import listiniReducer from "../moduli/listini_pack/redux/";
import listextraReducer from "../moduli/listini_extra/redux/";
import spedizioni from "../moduli/spedizioni/redux/";
import fatture from "../moduli/fatture/redux";

const reducers = combineReducers({
    settings,
    session,
    // firebase: firebaseReducer,
    // firestore: firestoreReducer,
    contactReducer,
    corrieri,
    spedizioni,
    fatture,
    appuntamentiReducer,
    listini: listiniReducer,
    listextra: listextraReducer
});

export default reducers;
