import getAxios from "../../../rest/myaxios";
import {
    REST_LISTINI_EXTRA_CREATE,
    REST_LISTINI_EXTRA_CREATE_TIPOLOGIA, REST_LISTINI_EXTRA_GET_IMAGE,
    REST_LISTINI_EXTRA_JSON,
    REST_LISTINI_EXTRA_LIST,
    REST_LISTINI_EXTRA_TIPOLOGIE_GET,
    REST_LISTINI_EXTRA_TIPOLOGIE_RIORDINA, REST_LISTINI_EXTRA_UPDATE_TIPOLOGIA
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _getAll(search, filtro, page = 1, limit = 20) {

    try {
        let request = await axios.get(REST_LISTINI_EXTRA_LIST, {
            params: {
                search,
                filtro,
                limit,
                page
            }
        });
        return request.data.listini;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}


async function getJson() {

    try {
        let request = await axios.get(REST_LISTINI_EXTRA_JSON);
        return request.data.listini;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }


}

async function create_listino(listino) {

    let data = new FormData();
    data.append('nome', listino.nome);
    if (listino.listino_ref) data.append('listino_ref', listino.listino_ref)
    data.append('descrizione', listino.descrizione)
    data.append('image', listino.image)



    try {
        let request = await axios.post(REST_LISTINI_EXTRA_CREATE, data);
        return request.data.listino_extra;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }


}

async function aggiungiTipologiaExtra(tipologia_extra) {
    let data = new FormData();
    data.append('nome', tipologia_extra.nome);
    data.append('listino_extra_ref', tipologia_extra.listino_extra_ref)
    data.append('prezzo', tipologia_extra.prezzo)
    data.append('descrizione', tipologia_extra.descrizione)
    data.append('image', tipologia_extra.image)

    try {
        let request = await axios.post(REST_LISTINI_EXTRA_CREATE_TIPOLOGIA, data);

        return request.data.tipologia_extra;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function modificaTipologiaExtra(tipologia_extra) {
    let data = new FormData();
    data.append('extra', JSON.stringify(tipologia_extra));

    try {
        let request = await axios.post(REST_LISTINI_EXTRA_UPDATE_TIPOLOGIA, data);

        return request.data.tipologia_extra;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function _getTipologie(listino_extra_ref) {
    try {
        let request = await axios.get(REST_LISTINI_EXTRA_TIPOLOGIE_GET, {
            params: {
                listino_extra_ref
            }
        });
        return request.data.tipologie_extra;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}


async function _riordinaTipologieExtra(tipologie_da_riordinare) {
    try {
        await axios.post(REST_LISTINI_EXTRA_TIPOLOGIE_RIORDINA, {
            tipologie_da_riordinare
        });
        return true;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}


async function _getImage(contatto_id, image) {

    try {
        const url = REST_LISTINI_EXTRA_GET_IMAGE + "/" + image;

        let request = await axios.get(url, { responseType: 'blob' });

        var binaryData = [];
        binaryData.push(request.data);
        return URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));

    } catch (e) {
        return false
        // throw new Error('Error Fetching Api Server')
    }

}




export const search = _getAll;
export const create = create_listino;
export const json = getJson;
export const getImage = _getImage;
export const creaTipologia = aggiungiTipologiaExtra;
export const updateTipologia = modificaTipologiaExtra;
export const getTipologie = _getTipologie;
export const riordinaTipologieExtra = _riordinaTipologieExtra;

