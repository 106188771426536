export const SAGA_CORRIERI_SEARCH = "SAGA_CORRIERI_SEARCH";
export const SAGA_CORRIERI_CREATE = "SAGA_CORRIERI_CREATE";
export const SAGA_CORRIERI_GET_SPEDIZIONI = "SAGA_CORRIERI_GET_SPEDIZIONI";



export const CORRIERI_LAZY_LOADING = "CORRIERI_LAZY_LOADING";
export const CORRIERI_RESET_LISTA = "CORRIERI_RESET_LISTA";
export const CORRIERI_AGGIUNGIA_ALLA_LISTA = "CORRIERI_AGGIUNGIA_ALLA_LISTA";


export const CORRIERI_GET_LISTA_SPEDIZIONI = "CORRIERI_GET_LISTA_SPEDIZIONI";
