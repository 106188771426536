import {SPEDIZIONI_DETAILS} from '../../../redux/constants';
const SpedizioniDetails = (state = '', action) => {
	switch (action.type) {
		case SPEDIZIONI_DETAILS:
			return action.id;
		default:
			return state;
	}
};

export default SpedizioniDetails;
