import React, {Component} from 'react';
import Select from "react-select";
import {Col, FormGroup, Row} from "reactstrap";

class Step2 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            listino_pack: props.getStore().listino_pack,
            listino_extra: props.getStore().listino_extra,
        };

        this._validateOnDemand = true; // this flag enables onBlur validation as user fills forms
        this.validationCheck = this.validationCheck.bind(this);
        this.isValidated = this.isValidated.bind(this);
    }

    isValidated() {
        const userInput = this._grabUserInput(); // grab user entered vals

        const validateNewInput = this._validateData(userInput); // run the new input against the validator
        let isDataValid = false;

        // if full validation passes then save to store and pass as valid
        if (Object.keys(validateNewInput).every((k) => {
            return validateNewInput[k] === true
        })) {
            if (
                this.props.getStore().listino_pack !== userInput.listino_pack ||
                this.props.getStore().listino_extra !== userInput.listino_extra
            ) {
                console.log("update")
                this.props.updateStore({
                    ...userInput,
                    savedToCloud: false // use this to notify step4 that some changes took place and prompt the user to save again
                });  // Update store here (this is just an example, in reality you will do it via redux or flux)
            }
            isDataValid = true;
        } else {
            // if anything fails then update the UI validation state but NOT the UI Data State
            this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
        }


        if (isDataValid) {
            const contatto = this.props.getStore();
            delete contatto.id;
            delete contatto.savedToCloud;
            contatto.azienda = contatto.azienda.value;
            contatto.listino_pack = contatto.listino_pack.value;
            contatto.listino_extra = contatto.listino_extra.value;

            this.props.creaContatto(contatto);
        }

        return isDataValid;
    }

    _validateData(data) {

        return {
            listino_packVal: (!(data.listino_pack === null || data.listino_pack.value === 0)),
            listino_extraVal: (!(data.listino_extra === null || data.listino_extra.value === 0)),
        }
    }


    validationCheck() {
        if (!this._validateOnDemand) return;

        const userInput = this._grabUserInput(); // grab user entered vals
        const validateNewInput = this._validateData(userInput); // run the new input against the validator

        this.setState(Object.assign(userInput, validateNewInput, this._validationErrors(validateNewInput)));
    }


    _grabUserInput() {

        return {
            listino_pack: this.state.listino_pack,
            listino_extra: this.state.listino_extra,
        };
    }

    _validationErrors(val) {
        const errMsgs = {
            emailValMsg: val.emailVal ? '' : 'Email non valida!'
        }
        return errMsgs;
    }

    render() {

        this.listini_extra_json = [{value: 0, label: "-----"}].concat(this.props.listini_extra.map(o => ({value: o.id, label: o.nome})));
        this.listini_pack_json = [{value: 0, label: "-----"}].concat(this.props.listini_pack.map(o => ({value: o.id, label: o.nome})));

        return (
            <div className="step step2 mt-5">

                <Row>
                    <Col md={6}>
                        <FormGroup className="">
                            <Select
                                defaultValue={this.state.listino_pack}
                                placeholder={"Scegli Listino Pack"}
                                name="listino_pack"
                                id="listino_pack"
                                options={this.listini_pack_json}
                                onChange={(e => {
                                    this.setState({listino_pack: e})
                                })}
                            />
                            <p className="text-center mt-1 mb-3 ">
                                <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Listino Pack</small>
                                {(this.state.listino_packVal === false) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>


                        </FormGroup>

                    </Col>


                </Row>

                <Row>
                    <Col md={6}>
                        <FormGroup className="">
                            <Select
                                defaultValue={this.state.listino_extra}
                                placeholder={"Scegli Listino Extra"}
                                name="listino_extra"
                                id="listino_extra"
                                options={this.listini_extra_json}
                                onChange={(e => {
                                    this.setState({listino_extra: e})
                                })}
                            />
                            <p className="text-center mt-1 mb-3 ">
                                <small className=" mt-1 mb-3 badge badge-default badge-dark text-whites float-left">Listino Extra</small>
                                {(this.state.listino_extraVal === false) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>

                    </Col>


                </Row>


            </div>
        )
    }
}

export default Step2;
