import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {resetFiltroSpedizioni} from "../../redux/action";
import FiltroSpedizioni from "./filtri/FiltroSpedizioniDashboard";
import SpedizioniList from "./SpedizioniList";
import SpedizioneDettaglio from "./SpedizioneDettaglio";
import {resetFiltroContatti} from "../../../contatti/redux/action";
import {fetchCorrieri} from "../../../corrieri/redux/action";


const SpedizioniHome = ({spedizioni,filtro,resetFiltroSpedizioni, resetFiltroContatti, fetchCorrieri, ...props}) => {
    const [showLeftPart, setShowLeftPart] = useState(false);
    const [showRightPart, setShowRightPart] = useState(false);
    const [spedizioneSelezionata, setSpedizioneSelezionata] = useState(null);

    useEffect(()=>{
        resetFiltroSpedizioni();
        resetFiltroContatti();
        fetchCorrieri();
    },[])

    useEffect(()=>{
        setSpedizioneSelezionata(null);
    },[filtro])

    const onSpedizioneClick = (sped) => {
        // console.log("spefizione seleziinaat", sped);
        setSpedizioneSelezionata(sped);
    }

    return <Fragment>
        <div className="contact-app">
            <div className={"left-part bg-white " + (showLeftPart === true ? "show-panel" : "")}>
                <FiltroSpedizioni/>
            </div>


            <div className="right-part spedizioni-list bg-white ">
                <div className={"right-left-part bg-whites " + (showRightPart === true ? "show-right-left-panel" : "")}>
                    <span onClick={() => {
                        setShowLeftPart(!showLeftPart);
                    }} className={"bg-primary show-left-part text-white d-block d-lg-none " + (showLeftPart === true ? "left-part-open" : "")}>
                        <i className={(showLeftPart === true ? "fas fa-chevron-left" : "fas fa-chevron-right")}></i>
                    </span>

                    <SpedizioniList onSpedizioneClick={onSpedizioneClick}/>

                </div>
                <div className={"right-right-part " + (showRightPart === true ? "show-right-right-panel" : "")}>
                            <span onClick={() => {
                                setShowRightPart(false);
                            }} className={"hide-right-right-part d-block d-md-none " + (showRightPart === true ? "right-right-part-open" : "")}>
                                <i className="fas fa-times"></i>
                            </span>

                    <SpedizioneDettaglio
                        spedizione={spedizioneSelezionata}
                        onCloseClick={()=>{setSpedizioneSelezionata(null)}}
                    />

                </div>

            </div>
        </div>

    </Fragment>;

}


const mapStateToProps = (state, ownProps) => ({
    spedizioni: state.spedizioni.spedizioni,
    filtro: state.spedizioni.filtro
});


const mapDispatchToProps = {
    resetFiltroSpedizioni,
    resetFiltroContatti,
    fetchCorrieri
}
export default connect(mapStateToProps, mapDispatchToProps)(SpedizioniHome);
