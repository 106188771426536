import React from 'react';

//API URL

export const API_URL = window.API_URL ? window.API_URL : process.env.REACT_APP_API_URL;

//API End Points
export const LOGIN = `${API_URL}/security/login`;
export const TESTACL = `${API_URL}/test-acl`;
export const CHECKTOKEN = `${API_URL}/security/check-token`;

export const REST_CORRIERI_LIST = `${API_URL}/corrieri/list`;
export const REST_CORRIERI_CREATE = `${API_URL}/corrieri/create`;
export const REST_CORRIERI_GET_SPEDIZIONI = `${API_URL}/corrieri/get-spedizioni`;
export const REST_CORRIERI_GET_IMG_PROFILO = `${API_URL}/images/corrieri`;

export const REST_SPEDIZIONI_SEARCH = `${API_URL}/spedizioni/search`;
export const REST_SPEDIZIONI_CREATE = `${API_URL}/spedizioni/create`;
export const REST_SPEDIZIONI_UPDATE = `${API_URL}/spedizioni/update`;
export const REST_SPEDIZIONI_DOWNLOAD_ELENCO_PDF = `${API_URL}/spedizioni/download-report-pdf`;
export const REST_SPEDIZIONI_DOWNLOAD_ELENCO_XLS = `${API_URL}/spedizioni/download-report-xls`;

export const REST_FATTURE_SEARCH = `${API_URL}/fatture/search`;
export const REST_FATTURE_CREATE = `${API_URL}/fatture/create`;
export const REST_FATTURE_UPDATE = `${API_URL}/fatture/update`;
export const REST_FATTURE_DELETE = `${API_URL}/fatture/delete`;
export const REST_FATTURE_DOWNLOAD = `${API_URL}/fatture/download`;
export const REST_FATTURE_DETTAGLIO = `${API_URL}/fatture/dettaglio`;
export const REST_FATTURE_BATCH_CONTATTI = `${API_URL}/fatture/search-contatti-batch`;
export const REST_FATTURE_BATCH_CREATE = `${API_URL}/fatture/create-batch`;


export const REST_CONTATTI_DETTAGLIO = `${API_URL}/contatti/dettaglio`;
export const REST_CONTATTI_LIST = `${API_URL}/contatti/list`;
export const REST_CONTATTI_CREATE = `${API_URL}/contatti/create`;
export const REST_CONTATTI_UPDATE = `${API_URL}/contatti/update`;
export const REST_CONTATTI_DELETE = `${API_URL}/contatti/delete`;
export const REST_CONTATTI_GET_ACQUISTI = `${API_URL}/contatti/get-acquisti`;
export const REST_CONTATTI_CREATE_ACQUISTO = `${API_URL}/contatti/add-acquisto`;
export const REST_CONTATTI_CREATE_INDIRIZZO = `${API_URL}/contatti/add-indirizzo`;
export const REST_CONTATTI_GET_INDIRIZZI = `${API_URL}/contatti/get-indirizzi`;
export const REST_CONTATTI_GET_IMG_PROFILO = `${API_URL}/images/contatti`;


export const REST_LISTINI_PACK_LIST = `${API_URL}/listino-pack/list`;
export const REST_LISTINI_PACK_CREATE = `${API_URL}/listino-pack/create`;
export const REST_LISTINI_PACK_JSON = `${API_URL}/listino-pack/json`;
export const REST_LISTINI_PACK_GET_IMAGE = `${API_URL}/images/lp`;

export const REST_LISTINI_PACK_CREATE_TIPOLOGIA = `${API_URL}/listino-pack/tipologie/create`;
export const REST_LISTINI_PACK_UPDATE_TIPOLOGIA = `${API_URL}/listino-pack/tipologie/update`;
export const REST_LISTINI_PACK_GET_TIPOLOGIE = `${API_URL}/listino-pack/tipologie/list`;
export const REST_LISTINI_PACK_RIORDINA_TIPOLOGIE = `${API_URL}/listino-pack/tipologie/riordina`;


export const REST_LISTINI_EXTRA_LIST = `${API_URL}/listino-extra/list`;
export const REST_LISTINI_EXTRA_JSON = `${API_URL}/listino-extra/json`;
export const REST_LISTINI_EXTRA_CREATE = `${API_URL}/listino-extra/create`;
export const REST_LISTINI_EXTRA_GET_IMAGE = `${API_URL}/images/le`;
export const REST_LISTINI_EXTRA_CREATE_TIPOLOGIA = `${API_URL}/listino-extra/tipologie/create`;
export const REST_LISTINI_EXTRA_UPDATE_TIPOLOGIA = `${API_URL}/listino-extra/tipologie/update`;
export const REST_LISTINI_EXTRA_TIPOLOGIE_GET = `${API_URL}/listino-extra/tipologie/list`;
export const REST_LISTINI_EXTRA_TIPOLOGIE_RIORDINA = `${API_URL}/listino-extra/tipologie/riordina`;
