import { combineReducers } from "redux";
// import contacts from "./contacts";
// import contactsDetails from "./contactsDetails";
// import contactsVisibilityFilter from "./contactsVisibilityFilter";
// import contactsSearch from "./contactsSearch";
// import editContact from "./editContact";
import appuntamenti from "./appuntamenti";

export default combineReducers({
	appuntamenti,
	// contactsDetails,
	// contactsVisibilityFilter,
	// contactsSearch,
	// editContact
});
