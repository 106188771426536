import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {Badge, Button, Form, FormGroup, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from "reactstrap";
import {SPEDIZIONI_FILTRO_STATO_ENUM} from "../../redux/spedizioni_redux_types";
import {aggiornaSpedizione} from "../../redux/action";
import {getTipoExtraPerListExtra} from "../../../listini_extra/redux/actions";
import {ExtraMenuItem} from "../../components/ExtraMenuItem";
import ScrollMenu from "react-horizontal-scrolling-menu";
import Select from "react-select";

const Arrow = ({text, className}) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({text: '<', className: 'arrow-prev'});
const ArrowRight = Arrow({text: '>', className: 'arrow-next'});


const SpedizioneDettaglio = ({extras_items, spedizione, aggiornaSpedizione, getTipoExtraPerListExtra, onCloseClick, corrieri, ...props}) => {
    const [stato, setStato] = useState({label: '', color: '', val: ''});
    const [color, setColor] = useState('primary');
    const [contrassegnoRiconsegnato, setContrassegnoRiconsegnato] = useState(false);

    const [modificaContrassegno, setModificaContrassegno] = useState(false);
    const [contrassegnoTemp, setContrassegnoTemp] = useState(0);

    const [modificaExtra, setModificaExtra] = useState(false);

    const [spedizioneTemp, setSpedizioneTemp] = useState(null);
    const [costoTotale, setCostoTotale] = useState(0);

    const [selectCorriere, setSelectCorriere] = useState([]);
    const [corriereSelezionato, setCorriereSelezionato] = useState(null);

    useEffect(() => {

        // console.log("corrieri", corrieri);

        let options = [];
        corrieri.forEach((o, i) => {
            // console.log("corriere", o)
            options.push({value: o.id, label: `${o.cognome} - ${o.nome} - ${o.email}`});
        });
        setSelectCorriere(options);
    }, [corrieri]);


    useEffect(() => {
        // setColor(stato.color)
        if (spedizione !== null) {
            // console.log("spedizione", spedizione)
            setSpedizioneTemp(spedizione);

            let st = SPEDIZIONI_FILTRO_STATO_ENUM.getStato(spedizione.stato)
            setModificaExtra(false);
            setModificaContrassegno(false);

            setStato(st);
            setCostoTotale(spedizione.costo_totale);
            setContrassegnoRiconsegnato(!!spedizione.contrassegno_riconsegnato);
            setContrassegnoTemp((spedizione.contrassegno_importo > 0) ? spedizione.contrassegno_importo : 0);


        }

    }, [spedizione]);

    useEffect(() => {

        setCorriereSelezionato(null)
        if (spedizione !== null) {
            corrieri.forEach((o, i) => {

                if (o.id === spedizione.corriere_ref) setCorriereSelezionato({
                    value: o.id,
                    label: `${o.cognome} - ${o.nome} - ${o.email}`
                });

            });


        }


    }, [JSON.stringify(spedizione), corrieri]);


    useEffect(() => {
        let cl = stato.color;
        if (stato.val === 1 && spedizione.contrassegno_importo > 0 && !!!spedizione.contrassegno_riconsegnato) cl = 'warning';
        setColor(cl);
    }, [stato])


    // useEffect(() => {
    //     console.log("extras", extras_items)
    //
    //
    //
    // }, [JSON.stringify(extras_items)])


    if (spedizione === null) {
        return <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
            <i className="fas fa-box-open display-5"></i>
            <h4 className="mt-2">Clicca su una spedizione per visualizzarne i dettagli.</h4>
        </div>;
    } else {

        const {contatto, corriere, spedizione_tipo, contatto_pack: {listinoPack, listinoExtra, ...contatto_pack}, indirizzo_to, ...sped} = spedizione;
        // console.log("contatto_pack", contatto_pack);

        const nome_contatto = (contatto.azienda) ? `${contatto.rag_sociale}` : `${contatto.cognome} ${contatto.nome}`
        const indirizzo_to_string = (indirizzo_to) ? `${indirizzo_to.comune} - ${indirizzo_to.indirizzo}` : '';
        const corriere_string = (corriere) ? `${corriere.nome} - ${corriere.cognome}` : '';
        const extras = (sped.extra) ? Object.entries(sped.extra) : [];

        return <Fragment>
            <div className="p-4">
                <div className="d-flex flex-row mb-4">
                    <div className="d-flex w-75  justify-content-start">
                        <h1 className="font-bold mb-0">Spedizione #{spedizione.id}


                        </h1>
                    </div>
                    <div className="w-25  justify-content-end">
                        <Button className="btn-circle close p-2" onClick={onCloseClick} aria-label="Close"> <span
                            aria-hidden="true">×</span> </Button>
                    </div>
                </div>


                <div className="d-flex w-100 justify-content-start mb-0">
                    <div className="d-flex w-50 justify-content-start">
                        stato:
                    </div>
                    <div className="d-flex w-50 justify-content-start">
                        azioni:
                    </div>
                </div>
                <div className="d-flex w-100 justify-content-start mb-4">
                    <h1 className="d-flex  w-100">
                        <div className="d-flex w-50 justify-content-start">
                            <Badge color={color}>{(stato.val===1 && !!spedizione.pagato && contatto_pack.postpagato)?"FATTURATA":stato.label.toUpperCase()}</Badge>
                        </div>
                        <div className="d-flex w-50 justify-content-start">
                            {!(stato.val===1 && !!spedizione.pagato && contatto_pack.postpagato)?<Fragment>
                                {(stato.val !== 0) ? <div><a className={"btn btn-circle btn-light"} onClick={() => {
                                    setStato(SPEDIZIONI_FILTRO_STATO_ENUM.getStato(0))
                                    spedizione.stato = 0;
                                    aggiornaSpedizione(spedizione)
                                }} href={"#"} id={"btn-stato-ap"}>AP</a><UncontrolledTooltip placement="bottom"
                                                                                             target={"btn-stato-ap"}>Imposta
                                    come
                                    Aperta</UncontrolledTooltip></div> : ''}

                                {(stato.val !== 1) ? <div><a className={"btn btn-circle btn-success"} onClick={() => {
                                    setStato(SPEDIZIONI_FILTRO_STATO_ENUM.getStato(1))
                                    spedizione.stato = 1;
                                    aggiornaSpedizione(spedizione)
                                }} href={"#"} id={"btn-stato-co"}>CO</a>
                                    <UncontrolledTooltip placement="bottom" target={"btn-stato-co"}>Imposta  come Consegnata</UncontrolledTooltip>
                                </div> : ''}

                                {(stato.val !== 2) ? <div><a className={"btn  btn-circle btn-secondary"} onClick={() => {
                                    setStato(SPEDIZIONI_FILTRO_STATO_ENUM.getStato(2))
                                    spedizione.stato = 2;
                                    aggiornaSpedizione(spedizione)
                                }} href={"#"} id={"btn-stato-an"}>AN</a>
                                    <UncontrolledTooltip placement="bottom" target={"btn-stato-an"}>Imposta come Annullata</UncontrolledTooltip>
                                </div> : ''}
                            </Fragment>:<Fragment>
                                <span id={"btn-info-no-action"}>---</span>
                                <UncontrolledTooltip placement="top" target={"btn-info-no-action"}>
                                    Nessuna azione disponibile perche' la spedizione e' stata fatturata
                                </UncontrolledTooltip>
                            </Fragment>}



                        </div>
                    </h1>
                </div>


                <div className="w-100 mb-4">
                    <h2>clt: {nome_contatto}</h2>
                </div>


                <div className="w-100 mb-3">
                    <h4>Spedizione:</h4>
                    tipo spedizione: <b>{spedizione.quantita}x | {spedizione_tipo.nome} ({spedizione_tipo.prezzo}€)</b><br/>
                    pacchetto: <b>{listinoPack.nome} {(contatto_pack.postpagato) ? '(post)' : '(pre)'},del {contatto_pack.createdAt.toString()}</b><br/>
                </div>

                {(!modificaExtra) ? <div className="w-100 mb-3">
                    <h4 className="mb-0">Extra: {(spedizione.stato === 0 && !!!spedizione.pagato) ? <Fragment>
                        <Button
                            onClick={() => {
                                setModificaExtra(true)
                                getTipoExtraPerListExtra(spedizione.contatto_pack.listino_extra_ref);
                                console.log("estraaaaaa", extras)
                            }}
                            className="btn" color="link" size="sm" id={"btn-mod-extra-ok"}>
                            <i className={"fas fa-edit"}></i>
                        </Button>
                        <UncontrolledTooltip placement="right" target={"btn-mod-extra-ok"}>Modifica
                            Extra</UncontrolledTooltip></Fragment> : ''}
                    </h4>
                    {extras.length === 0 ? 'Nessun extra' : ''}
                    {extras.map((key) => {
                        // console.log()

                        return (key[1]) ? <div key={key}> - {key[1].qt}x {key[1].nome} (pu:{key[1].prezzo}€ -
                            tot:{key[1].prezzo * key[1].qt}€) <br/></div> : ''
                    })}
                </div> : <Fragment>
                    <div className="w-100 mb-3">
                        <h4 className="mb-0">Extra: </h4>
                        <div className={'row'}>
                            <div className={"col-md-12"}>

                                <ScrollMenu
                                    data={extras_items.map(el => {
                                        // const {id, nome, prezzo} = el;
                                        return <ExtraMenuItem key={el.id}

                                                              old={spedizione.extra[`cod${el.id}`]}

                                                              onBtnClick={(extra, qt) => {
                                                                  let _sped = {...spedizioneTemp}
                                                                  console.log("_spef.extra", _sped.extra)


                                                                  const codextra = `cod${extra.id}`;
                                                                  if (_sped.extra === undefined) _sped.extra = {}

                                                                  if (qt === 0) {
                                                                      console.log("qt===0")
                                                                      _sped.extra[codextra] = null;
                                                                  } else {
                                                                      _sped.extra[codextra] = {
                                                                          nome: extra.nome,
                                                                          qt: qt,
                                                                          prezzo: extra.prezzo
                                                                      }
                                                                  }

                                                                  _sped.costo_totale = parseFloat(_sped.costo_spedizione);
                                                                  _sped.costo_extra = 0;


                                                                  for (const [key, value] of Object.entries(_sped.extra)) {
                                                                      console.log(`${key}:`, value);
                                                                      if (value) _sped.costo_extra += parseFloat(value.prezzo) * value.qt;
                                                                  }

                                                                  _sped.costo_totale = parseFloat(_sped.costo_spedizione) + _sped.costo_extra;

                                                                  setCostoTotale(_sped.costo_totale);

                                                                  setSpedizioneTemp(_sped);
                                                              }}
                                                              item={el}/>;
                                    })}
                                    arrowLeft={ArrowLeft}
                                    arrowRight={ArrowRight}
                                />

                            </div>
                        </div>

                        <Button
                            onClick={() => {
                                setModificaExtra(false)
                                // spedizione={...spedizioneTemp}
                                console.log("speddddd", spedizioneTemp)
                                aggiornaSpedizione(spedizioneTemp)

                                // spedizione= {...spedizioneTemp};
                            }}
                            className="btn" color="success" size="sm" id={"btn-mod-extra-ok-salva"}>
                            Salva <i className={"fas fa-edit"}></i>
                        </Button>

                    </div>
                </Fragment>}


                <div className="w-100 mb-3">
                    <h3>Costo totale spedizione: {costoTotale}€</h3>
                </div>

                <div className="w-100 mb-3">
                    {(!modificaContrassegno) ?
                        <h4>Contrassegno: {(sped.contrassegno_importo > 0) ? `${sped.contrassegno_importo}€` : 'nessuno'}
                            <Button
                                onClick={() => {
                                    setModificaContrassegno(true)
                                }}
                                className="btn" color="link" size="sm" id={"btn-mod-contrassegno-ok"}>
                                <i className={"fas fa-edit"}></i>
                            </Button>
                            <UncontrolledTooltip placement="right" target={"btn-mod-contrassegno-ok"}>Modifica
                                contrassegno</UncontrolledTooltip>
                        </h4>
                        : <div>
                            <h4>Contrassegno:
                                <Form>
                                    <InputGroup>
                                        <Input type="number" step={.1} onChange={(e) => {
                                            console.log("e", e.target.value)
                                            if (e.target.value > 0) setContrassegnoTemp(e.target.value)
                                            else setContrassegnoTemp(0)
                                            // spedizione.contrassegno_importo=e.target.value;

                                        }}
                                               style={{width: '50px', float: 'left'}}
                                               defaultValue={(sped.contrassegno_importo > 0) ? sped.contrassegno_importo : 0}/>
                                        <InputGroupAddon addonType="append">
                                            <Button color="primary"
                                                    onClick={() => {
                                                        spedizione.contrassegno_importo = contrassegnoTemp;
                                                        aggiornaSpedizione(spedizione);
                                                        setModificaContrassegno(false)
                                                    }}
                                            >Salva</Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </Form>


                            </h4>
                        </div>}

                    {(sped.contrassegno_importo > 0 && !contrassegnoRiconsegnato) ?
                        <h3><Badge color={'danger'}>CONTRASSEGNO NON RICONSEGNATO</Badge><a
                            className={"btn  btn-circle btn-success"} onClick={() => {
                            setContrassegnoRiconsegnato(true)
                            spedizione.contrassegno_riconsegnato = true;
                            aggiornaSpedizione(spedizione)

                        }} href={"#"} id={"btn-contrassegno-ok"}>
                            <i className={"fas fa-hand-holding-usd"}></i>
                        </a><UncontrolledTooltip placement="right" target={"btn-contrassegno-ok"}>Imposta il
                            contrassegno come
                            riconsegnato</UncontrolledTooltip></h3>
                        : ''}
                    {(sped.contrassegno_importo > 0 && contrassegnoRiconsegnato) ?
                        <h3><Badge color={'success'}>CONTRASSEGNO RICONSEGNATO</Badge><a
                            className={"btn  btn-circle btn-danger"} onClick={() => {
                            setContrassegnoRiconsegnato(false)
                            spedizione.contrassegno_riconsegnato = false;
                            aggiornaSpedizione(spedizione)

                        }} id={"btn-contrassegno-ko"} href={"#"}><i className={"fas fa-hand-holding-usd"}></i>
                        </a><UncontrolledTooltip placement="right" target={"btn-contrassegno-ko"}>Imposta il
                            contrassegno come non
                            riconsegnato</UncontrolledTooltip></h3> : ''}


                </div>


                <div className="w-100 mb-3">
                    <h4>Altri dati:</h4>
                    indirizzoTo: <b>{indirizzo_to_string}</b> <br/>
                    corriere: <b>{corriere_string}</b> <br/>
                    <FormGroup style={{width: '100%'}}>
                        <InputGroup>
                            <div style={{width: '80%', float: 'left'}}>
                                <Select
                                    isClearable
                                    placeholder={"Seleziona corriere"}
                                    value={corriereSelezionato}
                                    options={selectCorriere}
                                    onChange={(v) => {
                                        setCorriereSelezionato(v)
                                        spedizione.corriere_ref = (v !== null) ? v.value : null;
                                        aggiornaSpedizione(spedizione);
                                    }}
                                />
                            </div>
                        </InputGroup>
                        <p className="text-center mb-3 "></p>
                    </FormGroup>

                </div>

            </div>
        </Fragment>
    }


}

SpedizioneDettaglio.propTypes = {
    // spedizione: PropTypes.object.isRequired,
    // onRowClick: PropTypes.func.isRequired
};


const mapStateToProps = (state, ownProps) => ({
    extras_items: state.listextra.tipoExtraPerListExtra,
    corrieri: state.corrieri.corrieri,

});

const mapDispatchToProps = {
    aggiornaSpedizione,
    getTipoExtraPerListExtra
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpedizioneDettaglio);
