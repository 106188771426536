import React, {Component, Fragment} from "react";

import CreaSpedizione from "./containers/creaSpedizioneCont";
import {connect} from "react-redux";
import FiltroContatti from "./ContattiHome/filtri/FiltroContattiDashboard";
import ContattiList from "./ContattiHome/ContattiList";
import ContattoDettaglio from "./ContattiHome/ContattoDettaglioPreview";


class Contacts extends Component {

    constructor(props) {
        super(props);

        this.state = {
            contatto: null,
            showLeftPart: false,
            showRightPart: false,
            showDettagliContatto: false,
            showCreazioneSpedizione: false
        };
    }

    componentDidMount() {
    }

    onContattoClick = (contatto) => {
        console.log("contatto", contatto)
        this.setState({
            contatto,
            showRightPart: true,
            showDettagliContatto: true,
            showCreazioneSpedizione: false
        });

    }

    onCreaSpedizioneClick = (contatto) => {
        this.setState({
            contatto,
            showRightPart: true,
            showDettagliContatto: false,
            showCreazioneSpedizione: true
        });

    }


    render() {
        return (
            <Fragment>
                <div className="contact-app">
                    <div className={"left-part bg-white " + (this.state.showLeftPart === true ? "show-panel" : "")}>
                        <FiltroContatti/>
                    </div>
                    <div className="right-part contact-list bg-white ">
                        <div className={"right-left-part bg-whites " + (this.state.showRightPart === true ? "show-right-left-panel" : "")}>
                            <span onClick={() => {
                                this.setState({showLeftPart:!this.state.showLeftPart});
                            }}
                                  className={"bg-primary show-left-part text-white d-block d-lg-none " + (this.state.showLeftPart === true ? "left-part-open" : "")}><i
                                className={(this.state.showLeftPart === true ? "fas fa-chevron-left" : "fas fa-chevron-right")}></i></span>
                            <ContattiList
                                onSelectContatto={this.onContattoClick}
                                onCreaSpedizioneClick={this.onCreaSpedizioneClick}
                            />
                        </div>
                        <div className={"right-right-part " + (this.state.showRightPart === true ? "show-right-right-panel" : "")}>
                            <span onClick={() => {
                                this.setState({showRightPart:false})
                                // this.showRightPart(false);
                            }} className={"hide-right-right-part d-block d-md-none " + (this.state.showRightPart === true ? "right-right-part-open" : "")}>
                                <i className="fas fa-times"></i>
                            </span>

                            {(!this.state.showDettagliContatto && !this.state.showCreazioneSpedizione) ?
                                <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                                    <i className="far fa-address-card display-5"></i>
                                    <h4 className="mt-2">Clicca su un contatto per visualizzarne i dettagli.</h4>
                                </div> : ""}
                            {this.state.showDettagliContatto ? <ContattoDettaglio contatto={this.state.contatto}/> : ""}
                            {this.state.showCreazioneSpedizione ? <CreaSpedizione contatto={this.state.contatto}/> : ""}

                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    contacts: state.contactReducer.contacts,
    active: state.contactReducer.contactsDetails,
});


const mapDispatchToProps = {
}
export default connect(mapStateToProps, mapDispatchToProps)(Contacts);
