import React, {useEffect, useState} from "react";
import {CustomInput, ListGroup, ListGroupItem, Modal, ModalHeader} from "reactstrap";
import FormSteps from "../../../../contatti/views/steps/steps";
import {connect} from "react-redux";
import {searchContatti, setFiltroSearch, setFiltroTipo, setFiltroEliminato, setFiltroPreferito} from "../../../redux/action";
import FilterLinkContatti from "./FilterLinkContatto";


const FiltroContatti = ({
                            searchContatti, setFiltroSearch, setFiltroTipo, setFiltroEliminato, setFiltroPreferito,
                            filtro, ...props
                        }) => {
    const [modalNewContactOpen, setModalNewContactOpen] = useState(false);

    const filtrojson = JSON.stringify(filtro);


    useEffect(() => {
        // console.log("ricerco spedizioni")

        searchContatti();
    }, [filtrojson])

    return (
        <div>
            <div className="p-3">
                <span onClick={() => {
                    setModalNewContactOpen(!modalNewContactOpen);
                }}
                      className="btn btn-danger d-block text-white">Aggiungi Cliente</span>
            </div>
            <div className="divider"></div>
            <ListGroup className="contact-filters">
                <div className="bg-light p-1">
                    <input type="text" className="form-control" placeholder="Cerca Cliente" onChange={(e) => {
                        setFiltroSearch(e.target.value);
                    }} defaultValue={filtro.search}/>
                </div>

                <div className="bg-light p-1">
                    <CustomInput type="checkbox" checked={!filtro.eliminato} id="exampleCustomCheckbox" label="Solo attivi" onChange={() => {
                        setFiltroEliminato(!filtro.eliminato)
                    }}/>
                </div>
                <div className="bg-light p-1">
                    <CustomInput type="checkbox" checked={filtro.preferito} id="ssss" label="Preferiti" onChange={() => {
                        setFiltroPreferito(!filtro.preferito)
                    }}/>
                </div>


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkContatti stato={filtro.tipo} filtro={'tutti'} onClick={() => {
                    setFiltroTipo('tutti')
                }}>
                    <i className="fas fa-address-card  mr-2"></i> Tutti
                </FilterLinkContatti>

                <FilterLinkContatti stato={filtro.tipo} filtro={'aziende'} onClick={() => {
                    setFiltroTipo('aziende')
                }}>
                    <i className="fas fa-address-card  mr-2"></i> Aziende
                </FilterLinkContatti>

                <FilterLinkContatti stato={filtro.tipo} filtro={'privati'} onClick={() => {
                    setFiltroTipo('privati')
                }}>
                    <i className="fas fa-address-card  mr-2"></i> Privati
                </FilterLinkContatti>

                <ListGroupItem>
                    <hr/>
                </ListGroupItem>


            </ListGroup>


            <Modal isOpen={modalNewContactOpen} toggle={() => {
                setModalNewContactOpen(!modalNewContactOpen)
            }} size="lg" fade={true}>
                <ModalHeader toggle={() => {
                    setModalNewContactOpen(!modalNewContactOpen)
                }}>Aggiungi Cliente</ModalHeader>
                <FormSteps onChiudi={() => {
                    setModalNewContactOpen(!modalNewContactOpen)
                }}/>
            </Modal>


        </div>
    );

}

const mapStateToProps = (state, ownProps) => ({
    filtro: state.contactReducer.filtro,
})
const mapDispatchToProps = {
    searchContatti,
    setFiltroSearch,
    setFiltroTipo,
    setFiltroEliminato,
    setFiltroPreferito
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltroContatti)
