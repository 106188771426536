import {combineReducers} from "redux";
import {acquisti, indirizzo, indirizzi, contacts, lazy} from "./contacts";
import contactsDetails from "./contactsDetails";
import contactsVisibilityFilter from "./contactsVisibilityFilter";
import contactsSearch from "./contactsSearch";
import editContact from "./editContact";
import {filtro} from "./filtro";

export default combineReducers({
    contacts,
    filtro,
    lazy,
    acquisti,
    indirizzo,
    indirizzi,
    contactsDetails,
    contactsVisibilityFilter,
    contactsSearch,
    editContact
});
