export const LISTINI_RELOADED = 'LISTINI_RELOADED';
export const LISTINO_DETAILS = 'LISTINO_DETAILS';
export const TOGGLE_STARRED_LISTINO = 'TOGGLE_STARRED_LISTINO';
export const DELETE_LISTINO = 'DELETE_LISTINO';
export const SET_VISIBILITY_FILTER_LISTINI = 'SET_VISIBILITY_FILTER_LISTINI';
export const FILTER_LISTINI = 'FILTER_LISTINI';
export const ADD_LISTINO = 'ADD_LISTINO';
export const EDIT_LISTINO = 'EDIT_LISTINO';
export const UPDATE_LISTINO = 'UPDATE_LISTINO';
export const TIPO_SPEDIZIONI_PER_LISTINI_RELOADED = 'TIPO_SPEDIZIONI_PER_LISTINI_RELOADED';
export const ADD_TIPO_SPEDIZIONE_A_LISTINO = 'ADD_TIPO_SPEDIZIONE_A_LISTINO';
export const UPDATE_TIPO_SPEDIZIONE_A_LISTINO = 'UPDATE_TIPO_SPEDIZIONE_A_LISTINO';
export const RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO = 'RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO';
