import {ADD_LIST_EXTRA, LIST_EXTRAS_RELOADED, UPDATE_LIST_EXTRA} from "./_constant";
import {LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA, LISTINI_EXTRA_JSON, LISTINI_EXTRA_LAZY_LOADING, LISTINI_EXTRA_RESET_LISTA} from "./listini-extra-redux-types";
import {LISTINI_LAZY_LOADING} from "../../listini_pack/redux/listini-redux-types";

const listini = (state = [], action) => {
    switch (action.type) {
        case LISTINI_EXTRA_RESET_LISTA:
            return action.listini;
        case LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA:
            return [...state.concat(action.listini)];
        case ADD_LIST_EXTRA:
            return [
                ...state,
                {
                    id: action.listino.id,
                    nome: action.listino.nome,
                    costo: action.listino.costo,
                    costo_spedizione: action.listino.costo_spedizione,
                    descrizione: action.listino.descrizione,
                    image: action.listino.image,
                    frequentlycontacted: false,
                    starred: false,
                    deleted: false
                }
            ];

        case UPDATE_LIST_EXTRA:
            // console.log(action)
            // console.log(state)
            return state.map(listino => (listino.id === action.id ? {...listino, [action.field]: action.value} : listino));
        //
        // case TOGGLE_STARRED_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, starred: !contact.starred} : contact));
        //
        // case DELETE_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, deleted: !contact.deleted} : contact));

        default:
            return state;
    }
};

const lazy = (state = 1, action) => {
    switch (action.type) {
        case LISTINI_EXTRA_LAZY_LOADING:
            if (action.reset) return 1;
            return state + 1;//questa rappresenta la pagina
        default:
            return state;
    }

}

const json = (state = [], action) => {
    switch (action.type) {
        case LISTINI_EXTRA_JSON:
            return action.listini;
        default:
            return state;
    }
}

export {listini, lazy, json};
