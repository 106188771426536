import React, {useEffect, useState} from "react";
import {ListGroup, ListGroupItem} from "reactstrap";
import {connect} from "react-redux";
import SettingsMenuItem from "./SettingsMenuItem";


const SettingsMenu = ({onChangeMenu, ...props}) => {

    const [sezioneAttiva, setSezioneAttiva] = useState(null);

    return (
        <div>
            <div className="p-3">
                <span onClick={() => {
                    setSezioneAttiva(null);
                    onChangeMenu(null);
                }}
                      className="btn btn-danger d-block text-white">Impostazioni</span>
            </div>


            <div className="divider"></div>
            <ListGroup className="contact-filters">


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <SettingsMenuItem
                    stato={sezioneAttiva}
                    sezione={"mailer"}
                    onClick={() => {
                        setSezioneAttiva("mailer");
                        onChangeMenu("mailer")
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Email
                </SettingsMenuItem>


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>


            </ListGroup>

        </div>
    );

}

const mapStateToProps = (state, ownProps) => ({})
const mapDispatchToProps = {}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SettingsMenu)
