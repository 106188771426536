import React, {Fragment, useState} from "react";
import {Button} from "reactstrap";
import {REST_FATTURE_DOWNLOAD} from "../../../rest/rest-constants";
import {getNomeFileFattura} from "../utils/fatture-utils";
import getAxios from "../../../rest/myaxios";
const axios = getAxios()



const BottoneDownloadFattura = ({fattura, contatto, ...props}) => {


    return (<Button
        onClick={() => {
            axios(`${REST_FATTURE_DOWNLOAD}/${fattura.id}`, {
                method: 'GET',
                responseType: 'blob' //Force to receive data in a Blob Format
            })
                .then(response => {

                    const filename = getNomeFileFattura(fattura, contatto)

                    var a = document.createElement("a");
                    a.style = "display: none";
                    document.body.appendChild(a);
                    const file = new Blob([response.data], {type: 'application/pdf'});
                    const fileURL = URL.createObjectURL(file);
                    a.href = fileURL;
                    a.target = '_blank';
                    a.download = filename;
                    a.click();
                    window.URL.revokeObjectURL(fileURL);

                })
                .catch(error => {
                    alert("Impossibile Scaricare la fatt")
                    console.log(error);
                });

        }}
        className="btn mb-0" color="success" outline size="sm" id={"btn-download-fatt"}>
        scarica fattura <i className={"fas fa-edit"}></i>
    </Button>)





}
export default BottoneDownloadFattura;
