import { connect } from 'react-redux';
import PropTypes from "prop-types";
import React from "react";
import { listiniExtraFetch, setListiniVisibilityFilter} from "../../redux/actions";


const Link = ({ active, children, onClick , filter,listiniExtraFetch,setFilt}) => {

  return (
      <li className="list-group-item" onClick={()=>{setFilt(filter);listiniExtraFetch()}}>
		<span className={"list-group-item-action " + (active ? "active" : "")}>
			{children}
		</span>
      </li>
  );
}

Link.propTypes = {
  active: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};


const mapStateToProps = (state, ownProps) => ({
  active: ownProps.filter === state.listextra.listextraVisibilityFilter,
  filter: ownProps.filter
})

const mapDispatchToProps = {
  setFilt: setListiniVisibilityFilter,
  // getListExtras,
  listiniExtraFetch
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Link)
