import { FILTER_CORRIERE } from '../../../redux/constants';
const CorrieriSearch = (state = '', action) => {
	switch (action.type) {
		case FILTER_CORRIERE:
			return action.payload
		// case SET_VISIBILITY_FILTER:
		// 	return state = ''
		default:
			return state
	}
}

export default CorrieriSearch;
