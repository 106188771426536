import {
    FATTURE_FILTRO_CONTATTO,
    FATTURE_FILTRO_DATA,
    FATTURE_FILTRO_DATA_ENUM,
    FATTURE_FILTRO_LIMIT,
    FATTURE_FILTRO_LOAD_PAGE, FATTURE_FILTRO_PAGAMENTO, FATTURE_FILTRO_PAGAMENTO_ENUM, FATTURE_FILTRO_PAGINATION_COUNT,
    FATTURE_FILTRO_RESET, FATTURE_FILTRO_STATO,
    FATTURE_FILTRO_STATO_ENUM, FATTURE_FILTRO_TIPO, FATTURE_FILTRO_TIPO_ENUM
} from "./fatture_redux_types";
import moment from "moment";

const defaults = {
    tipo: FATTURE_FILTRO_TIPO_ENUM.ALL,
    stato: FATTURE_FILTRO_STATO_ENUM.ALL,
    pagamento: FATTURE_FILTRO_PAGAMENTO_ENUM.ALL,
    data_filtro: FATTURE_FILTRO_DATA_ENUM.ALL,
    data_from: 0,
    data_to: 0,
    contatto_ref: null,
    pagination_count: 0,
    pagination_page: 1,
    pagination_limit: 50
}

const filtro = (state = defaults, action) => {
    switch (action.type) {
        case FATTURE_FILTRO_RESET:
            return defaults;

        case FATTURE_FILTRO_LOAD_PAGE:
            return {...state, pagination_page: action.payload.page};
        case FATTURE_FILTRO_PAGINATION_COUNT:
            return {...state, pagination_count: action.count};
        case FATTURE_FILTRO_LIMIT:
            let limite = action.limite;
            return {...state, pagination_page: 1, pagination_limit: limite};
        case FATTURE_FILTRO_DATA:
            let date_from = 0;
            let date_to = 0;
            let filtro = action.payload.filtro;

            if (filtro === FATTURE_FILTRO_DATA_ENUM.ODIERNE) {
                //calcolare le 00.00 e le 23.59
                date_from = moment().startOf('day').utc(true).toDate();

            } else if (filtro === FATTURE_FILTRO_DATA_ENUM.ALL) {
                date_from = 0;
                date_to = 0;
            } else if (filtro === FATTURE_FILTRO_DATA_ENUM.RANGE) {
                date_from = moment(action.payload.from,"YYYY-MM-DD").startOf('day').utc(true).toDate();
                date_to = moment(action.payload.to,"YYYY-MM-DD").endOf('day').utc(true).toDate();
                // date_to = action.payload.to;
                // console.log("date frommmmm", date_from)
                // console.log("date tooooooo", date_to)
            }

            return {...state, data_filtro: filtro, data_from: date_from, data_to: date_to, pagination_page: 1};
        case FATTURE_FILTRO_STATO:
            let stato = action.payload.stato;
            return {...state, stato, pagination_page: 1};
        case FATTURE_FILTRO_TIPO:
            return {...state, tipo: action.payload.tipo, pagination_page: 1};
        case FATTURE_FILTRO_PAGAMENTO:
            return {...state, pagamento: action.payload.pagamento, pagination_page: 1};
        case FATTURE_FILTRO_CONTATTO:
            let contatto_ref = action.payload.contatto;
            return {...state, contatto_ref, pagination_page: 1};


        default:
            return state;
    }
};


export {filtro};
