import React from "react";
import {Redirect, Route} from "react-router-dom";
import {connect} from "react-redux";
import Fulllayout from "../layouts/fulllayout";

function PrivateRoute({user, children, ...rest}) {
    // console.log("user", user)
    // console.log("rest", rest)

    let component = rest.component;
    if(rest.layout==='Fulllayout') component = Fulllayout
    if(user|| !rest.protected) return <Route key={rest.key} component={component}/>

    else return <Redirect
        to={{
            pathname: "/authentication/login",
            state: {from: rest.location}
        }}
    />;


}

const mapStatToProps = (state) => {
    return {
        // loading: state.session.loading,
        user: state.session.user,
        // fetching: state.session.fetching_sessions,
        // fetching_error: state.session.fetching_error,
    }
}


const mapDispatchToProps = {
    // restore
}


export default connect(mapStatToProps, mapDispatchToProps)(PrivateRoute);
