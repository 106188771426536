import getAxios from "../../../rest/myaxios";
import {REST_MAILER_TEST, REST_SETTINGS_GET, REST_SETTINGS_SET} from "./settings-rest-constants";


const axios = getAxios()

async function _get(categoria) {
    try {
        let request = await axios.get(REST_SETTINGS_GET, {
            params: {
                categoria,
            }
        });
        return request.data.categoria;
    } catch (e) {
        console.error("errore fetching",e)
        throw new Error('Error Fetching Api Server');
    }

}

async function _set(categoria,settings) {

    try {
        let data = new FormData();
        data.append('categoria', categoria);
        data.append('settings', JSON.stringify(settings));

        let request = await axios.post(REST_SETTINGS_SET, data);

        return request.data;
    } catch (e) {
        throw new Error('Error Fetching Api Server');
    }

}

async function _mailerTest(settings) {
    try {
        let data = new FormData();
        data.append('settings', JSON.stringify(settings));
        let request = await axios.post(REST_MAILER_TEST, data);
        return request.data;
    } catch (e) {
        console.error("errore fetching",e)
        throw new Error('Error Fetching Api Server');
    }

}



export const get = _get;
export const set = _set;
export const mailer_test = _mailerTest;
