import React, {Fragment, useEffect, useState} from "react";
import {Button, Col, Form, Input, ModalBody, ModalFooter, Row} from "reactstrap";
import moment from "moment";
import {connect} from "react-redux";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {
    SPEDIZIONI_FILTRO_DATA_ENUM,
    SPEDIZIONI_FILTRO_PAGAMENTO_ENUM,
    SPEDIZIONI_FILTRO_STATO_ENUM,
    SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM
} from "../../spedizioni/redux/spedizioni_redux_types";
import {creaFattura} from "../redux/action";
import Api from "../../../rest";
import {fetching} from "../../../redux/session/action";
import BottoneDownloadFattura from "../FatturaDettaglio/BottoneDownloadFattura";


const Arrow = (props)=>(props.direction==='asc'?<i className={"fas fa-arrow-up"}/>:<i className={"fas fa-arrow-down"}/>);



const NuovaFatturaBatch = ({fetching, ...props}) => {
    const [contatti, setContatti] = useState([]);
    const [fatturaCreata, setFatturaCreata] = useState(false);
    const [fattureGenerate, setFattureGenerate] = useState([]);

    const [dataDa, setDataDa] = useState('');
    const [dataA, setDataA] = useState('');
    const [daValida, setDaValida] = useState(false);
    const [aValida, setAValida] = useState(false);
    const [contattiSelezionati, setContattiSelezionati] = useState([]);

    const [erroreDataFrom, setErroreDataFrom] = useState(false);
    const [erroreDataFromMsg, setErroreDataFromMsg] = useState('');
    const [erroreDataTo, setErroreDataTo] = useState(false);
    const [erroreDataToMsg, setErroreDataToMsg] = useState('');

    const [note, setNote] = useState('');

    useEffect(() => {
        console.clear()
        console.log("cambio")
        console.log("data da",moment(dataDa, 'YYYY-MM-DD').startOf('day').utc(true).toDate())
        console.log("data a",moment(dataA, 'YYYY-MM-DD').endOf('day').utc(true).toDate())
        const da = moment(dataDa, 'YYYY-MM-DD').startOf('day').utc(true);
        const a = moment(dataA, 'YYYY-MM-DD').endOf('day').utc(true);


        const data_da_valida =da.isValid() && da.isBetween(moment("2020-01-01", 'YYYY-MM-DD'), moment("2030-12-31", 'YYYY-MM-DD'));
        const data_a_valida = a.isValid() && a.isBetween(moment("2020-01-01", 'YYYY-MM-DD'), moment("2030-12-31", 'YYYY-MM-DD'));

        setDaValida(data_da_valida);
        setAValida(data_a_valida);

        if (data_da_valida && data_a_valida) {

            setContatti([])
            const _filtro = {
                pagamento: SPEDIZIONI_FILTRO_PAGAMENTO_ENUM.NON_PAGATE,
                stato: SPEDIZIONI_FILTRO_STATO_ENUM.CONSEGNATE,
                tipologia: SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.POSTPAGATE,
                data_filtro: SPEDIZIONI_FILTRO_DATA_ENUM.RANGE,
                data_from: da.toDate(),
                data_to: a.toDate(),
                corriere_ref: null,
                contatto_ref: null,
                pagination_count: 0,
                pagination_page: 1,
                pagination_limit: 1000000000000
            }

            fetching(true)
            Api.fatture.batch_search(_filtro).then((r) => {
                setContatti(r)


                fetching(false);
            }).catch((e) => {
                console.error(e)
                fetching(false, true, 'impossibile caricare impostazioni')
            });


            //EFFETTUO RICERCA


        }else{
            console.log("non valida")


        }

        setContattiSelezionati([]);

    }, [dataA, dataDa])


    const onSubmit = (e) => {
        e.preventDefault();

        setErroreDataFrom((dataDa === ''));
        setErroreDataFromMsg((dataDa === '') ? 'Obbligatorio!' : '');

        setErroreDataTo((dataA === ''));
        setErroreDataToMsg((dataA === '') ? 'Obbligatorio!' : '');

        setErroreDataTo(moment(dataDa, 'YYYY-MM-DD').isAfter(moment(dataA, 'YYYY-MM-DD')));
        setErroreDataToMsg(moment(dataDa, 'YYYY-MM-DD').isAfter(moment(dataA, 'YYYY-MM-DD')) ? 'Piu\' grande di "Dal"' : '');


        if (!erroreDataFrom && !erroreDataTo) {
            if(contattiSelezionati.length===0){
                alert("Seleziona almeno un cliente")
            }else{
                let dati = {
                    data_from: moment(dataDa, 'YYYY-MM-DD').startOf('day').utc(true).toDate(),
                    data_to: moment(dataA, 'YYYY-MM-DD').endOf('day').utc(true).toDate(),
                    contatti: contattiSelezionati
                }
                console.log("creo fatture", dati)

                fetching(true)
                Api.fatture.batch_create(dati).then((r) => {
                    setFatturaCreata(true);

                    setFattureGenerate(r.fatture);
                    // console.log(r)

                    fetching(false);
                }).catch((e) => {
                    console.error(e)
                    fetching(false, true, 'impossibile caricare impostazioni')
                });


            }



        }

    }

    const onRowSelect = (row, isSelected, e) => {

        let array = contattiSelezionati;
        if (isSelected) {
            array.push(row.id)
        } else {
            array.pop(row.id)
        }
        setContattiSelezionati(array);

        console.log(e);
    }

    const onSelectAll = (isSelected, rows) => {
        if (isSelected) {
            let array = [];
            for (let i = 0; i < rows.length; i++) {
                array.push(rows[i].id);
            }
            setContattiSelezionati(array);
        } else {
            setContattiSelezionati([]);
        }
    }

    return <Fragment>


        {(!fatturaCreata) ? <Form
            onSubmit={onSubmit}
        >

            <ModalBody>


                <Row>
                    <Col md={4}>
                        <Input name={"date_from"} onChange={(e) => {
                            setDataDa(e.target.value);
                        }}
                               type="date"/>
                        <small className="mt-1 mb-1 badge badge-default badge-dark text-light float-left">Dal </small>
                        {(erroreDataFrom) ? <small className=" badge badge-default badge-danger text-white">{erroreDataFromMsg}</small> : ""}
                        <small className="mt-1 mb-1 badge badge-default badge-light text-dark float-right">Obbligatorio</small>

                    </Col>
                    <Col md={4}>
                        <Input name={"date_to"} onChange={(e) => {
                            setDataA(e.target.value);
                        }} type="date"/>
                        <small className="mt-1 mb-1 badge badge-default badge-dark text-light float-left">Fino al </small>
                        {(erroreDataTo) ? <small className=" badge badge-default badge-danger text-white">{erroreDataToMsg}</small> : ""}
                        <small className="mt-1 mb-1 badge badge-default badge-light text-dark float-right">Obbligatorio</small>

                    </Col>
                </Row>


                {(daValida && aValida) ? <div>


                    <h5 className={"mt-4"}>Seleziona Clienti per cui generare fattura:</h5>
                    <BootstrapTable key={1} className="spedizioni-in-fattura-table no-wrap  v-middle mb-0" data={contatti} striped hover
                        // pagination
                                    selectRow={{
                                        mode: 'checkbox',
                                        columnWidth: '40px',
                                        clickToSelect: true,
                                        onSelect: onRowSelect,
                                        onSelectAll: onSelectAll
                                    }}

                                    options={{
                                        defaultSortName: 'nome',  // default sort column name
                                        defaultSortOrder: 'asc'  // default sort order
                                    }}


                                    tableHeaderClass='mb-0'

                    >

                        <TableHeaderColumn isKey={true} width='250'
                                           key={(c,r)=>(r.id)}

                                           dataField='nome'
                                           dataSort={true}
                                           caretRender={(direction) => <Arrow direction={direction}/>}

                                           dataFormat={(c, r) => {
                                               // console.log("C",r);
                                               return `${r.nome}`
                                           }}>
                            Cliente
                        </TableHeaderColumn>
                        <TableHeaderColumn width='100' dataSort={true} dataField='spedizioni'
                                           caretRender={(direction) => <Arrow direction={direction}/>}
                                           dataFormat={(c, r) => (r.spedizioni)}>
                            Tot.spedizioni
                        </TableHeaderColumn>
                        <TableHeaderColumn width='100' dataSort={true} dataField='totale'
                                           caretRender={(direction) => <Arrow direction={direction}/>}
                                           dataFormat={(c, r) =>(`${r.totale}€`)}>
                            Importo
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div> : ''}


            </ModalBody>
            <ModalFooter>
                {(daValida && aValida) ? <Button color="primary" type="submit">Crea fatture</Button> : ''}
            </ModalFooter>
        </Form> : <>


            <h5 className={"mt-4"}>Sono state generate le seguenti fatture:</h5>
            <BootstrapTable key={2} className="atture-generate-table no-wrap  v-middle mb-0" data={fattureGenerate} striped hover
                // pagination
                //             selectRow={{
                //                 mode: 'checkbox',
                //                 columnWidth: '40px',
                //                 clickToSelect: true,
                //                 onSelect: onRowSelect,
                //                 onSelectAll: onSelectAll
                //             }}

                            options={{
                                defaultSortName: 'nome_fatt',  // default sort column name
                                defaultSortOrder: 'asc'  // default sort order
                            }}


                            tableHeaderClass='mb-0'

            >

                <TableHeaderColumn isKey={true} width='250'
                                   dataField='nome_fatt'
                                   dataSort={true}
                                   caretRender={(direction) => <Arrow direction={direction}/>}

                                   dataFormat={(c, r) => {
                                       // console.log("C",r);
                                       return `${r.contatto.rag_sociale} ${r.contatto.cognome} ${r.contatto.nome} - fatt: #${r.fattura.id}`
                                   }}>
                    Cliente
                </TableHeaderColumn>

                <TableHeaderColumn width='100' dataSort={true} dataField='totales'
                                   caretRender={(direction) => <Arrow direction={direction}/>}
                                   dataFormat={(c, r) =>(`${r.fattura.importo}€`)}>
                    Importo
                </TableHeaderColumn>
                <TableHeaderColumn width='100' dataSort={false} dataField='totales'
                                   dataFormat={(c, r) =><BottoneDownloadFattura fattura={r.fattura} contatto={r.contatto}/> }>

                </TableHeaderColumn>
            </BootstrapTable>
        </>}


    </Fragment>


}

const mapStateToProps = (state, ownProps) => ({
    filtro: state.spedizioni.filtro,
});

const mapDispatchToProps = {
    fetching

    // setFiltroSpedizioni,
    // searchSpedizioni,
    // creaFattura
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NuovaFatturaBatch)


