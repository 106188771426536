import getAxios from "../../../rest/myaxios";
import {
    REST_CONTATTI_CREATE,
    REST_CONTATTI_CREATE_ACQUISTO, REST_CONTATTI_CREATE_INDIRIZZO, REST_CONTATTI_DELETE, REST_CONTATTI_DETTAGLIO,
    REST_CONTATTI_GET_ACQUISTI,
    REST_CONTATTI_GET_IMG_PROFILO, REST_CONTATTI_GET_INDIRIZZI,
    REST_CONTATTI_LIST, REST_CONTATTI_UPDATE
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _getAll( filtro) {



    try {
            let request = await axios.get(REST_CONTATTI_LIST, {
                params: {
                    filtro,
                }
            });
            return request.data.contatti;
    } catch (e) {
        console.error(e)
        throw new Error('Error Fetching Api Server')
    }

}

async function _dettaglio( contatto_id) {



    try {
            let request = await axios.get(`${REST_CONTATTI_DETTAGLIO}/${contatto_id}`, {
                // params: {
                //     contatto_id,
                // }
            });
            return request.data;
    } catch (e) {
        console.error(e)
        throw new Error('Error Fetching Api Server')
    }

}


async function _create(contatto) {

    // inserire formdata
    let data = new FormData();
    data.append('azienda', contatto.azienda);
    data.append('citta', contatto.citta);
    data.append('codfisc', contatto.codfisc);
    data.append('cognome', contatto.cognome);
    data.append('email', contatto.email);
    data.append('indirizzo', contatto.indirizzo);
    data.append('listino_extra', contatto.listino_extra);
    data.append('listino_pack', contatto.listino_pack);
    data.append('nome', contatto.nome);
    data.append('phone', contatto.phone);
    data.append('piva', contatto.piva);
    data.append('rag_sociale', contatto.rag_sociale);
    data.append('image', contatto.image);


    try {
        let request = await axios.post(REST_CONTATTI_CREATE, data);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        return request.data.contatto;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}

async function _update(contatto) {
    let data = new FormData();
    data.append('contatto', JSON.stringify(contatto));

    try {
        let request = await axios.post(REST_CONTATTI_UPDATE, data);
        return request.data.contatto;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}

async function _elimina(contatto) {
    let data = new FormData();
    data.append('contatto', contatto.id);

    try {
        let request = await axios.delete(REST_CONTATTI_DELETE, {data:{contatto:contatto.id}});
        return request.data.contatto;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}


async function _getImageProfilo(contatto_id, image) {

    try {
        const url = REST_CONTATTI_GET_IMG_PROFILO + "/" + image;

        let request = await axios.get(url, {responseType: 'blob'});

        var binaryData = [];
        binaryData.push(request.data);
        return URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));

    } catch (e) {
        // throw new Error('Error Fetching Api Server')
        console.error('Error Fetching Api Server')
    }

}


async function _getAcquisti(contatto_ref, attivi) {

    try {

        let request = await axios.get(REST_CONTATTI_GET_ACQUISTI, {
            params: {
                contatto_ref,
                attivi
            }
        });

        return request.data.packs;

    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}
async function _getIndirizzi(contatto_ref) {

    try {

        let request = await axios.get(REST_CONTATTI_GET_INDIRIZZI, {
            params: {
                contatto_ref,
            }
        });

        return request.data.indirizzi;

    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}

async function _createAcquisto(pack) {
    // inserire formdata
    let data = new FormData();
    data.append('contatto_ref', pack.contatto_ref);
    data.append('listino_pack_ref', pack.listino_pack_ref);
    data.append('listino_extra_ref', pack.listino_extra_ref);
    data.append('postpagato', pack.postpagato);
    data.append('importo_accreditato', pack.importo_accreditato);
    data.append('importo_pagato', pack.importo_pagato);
    data.append('importo_saldato', pack.importo_saldato);
    data.append('pacchetti_negativi', JSON.stringify(pack.pacchetti_negativi));
    data.append('note', pack.note);

    try {
        let request = await axios.post(REST_CONTATTI_CREATE_ACQUISTO, data);

        console.log("aggiunto pacchetto", request.data.pack);

        // setTimeout()

        return request.data.pack;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}

async function _createIndirizzo(indirizzo) {
    // inserire formdata
    let data = new FormData();
    data.append('contatto_ref', indirizzo.contatto_ref);
    data.append('comune', indirizzo.comune);
    data.append('indirizzo', indirizzo.indirizzo);
    if(indirizzo.lat) data.append('lat', indirizzo.lat);
    if(indirizzo.lon) data.append('lon', indirizzo.lon);

    try {
        let request = await axios.post(REST_CONTATTI_CREATE_INDIRIZZO, data);

        console.log("aggiunto indirizzo", request.data.indirizzo);

        // setTimeout()

        return request.data.indirizzo;
    } catch (e) {
        console.error("errore api", e)
        throw new Error('Error Fetching Api Server')
    }

}


export const search = _getAll;
export const dettaglio = _dettaglio;
export const create = _create;
export const update = _update;
export const elimina = _elimina;
export const getImageProfilo = _getImageProfilo;
export const getAcquisti = _getAcquisti;
export const createAcquisto = _createAcquisto;
export const getIndirizzi = _getIndirizzi;
export const createIndirizzo = _createIndirizzo;

