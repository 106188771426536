import React, {Fragment, useEffect, useState} from "react";
import  {Button, Col, Form, FormGroup, Input,InputGroup,InputGroupAddon, InputGroupText, ModalBody, ModalFooter, Row} from "reactstrap";
import Select from "react-select";
import moment from "moment";
import {connect} from "react-redux";
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {
    SPEDIZIONI_FILTRO_DATA_ENUM,
    SPEDIZIONI_FILTRO_PAGAMENTO_ENUM,
    SPEDIZIONI_FILTRO_STATO_ENUM,
    SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM
} from "../../spedizioni/redux/spedizioni_redux_types";
import {searchSpedizioni, setFiltroSpedizioni} from "../../spedizioni/redux/action";
import {creaFattura} from "../redux/action";



const tipoDocumento = [
    {value: 0, label: 'Fattura'},
    {value: 1, label: 'Nota di credito'},
];


const NuovaFattura = ({contatto, filtro, setFiltroSpedizioni, searchSpedizioni, spedizioni, creaFattura, ...props}) => {
    const [fatturaCreata, setFatturaCreata] = useState(false);
    const [contattoScelto, setContattoScelto] = useState(contatto);
    const [tipologia, setTipologia] = useState(tipoDocumento[0]);
    const [dataDa, setDataDa] = useState('');
    const [dataA, setDataA] = useState('');
    const [daValida, setDaValida] = useState(false);
    const [aValida, setAValida] = useState(false);
    const [possoRicercare, setPossoRicercare] = useState(false);
    const [totaleFattura, setTotaleFattura] = useState(0);
    const [spedizioniSelezionate, setSpedizioniSelezionate] = useState([]);

    const [erroreDataFrom, setErroreDataFrom] = useState(false);
    const [erroreDataFromMsg, setErroreDataFromMsg] = useState('');
    const [erroreDataTo, setErroreDataTo] = useState(false);
    const [erroreDataToMsg, setErroreDataToMsg] = useState('');

    const [note,setNote] = useState('');

    useEffect(() => {
        const da = moment(dataDa, 'YYYY-MM-DD').startOf('day').utc(true);
        const a = moment(dataA, 'YYYY-MM-DD').endOf('day').utc(true);


        setDaValida(da.isValid() && da.isBetween(moment("2020-01-01", 'YYYY-MM-DD'), moment("2030-12-31", 'YYYY-MM-DD')));
        setAValida(a.isValid() && a.isBetween(moment("2020-01-01", 'YYYY-MM-DD'), moment("2030-12-31", 'YYYY-MM-DD')));

        if (daValida && setAValida && contattoScelto) {
            setPossoRicercare(true);

            // console.log("ricerco le spedizioni");

            const _filtro = {
                pagamento: (tipologia.value === 0) ? SPEDIZIONI_FILTRO_PAGAMENTO_ENUM.NON_PAGATE : SPEDIZIONI_FILTRO_PAGAMENTO_ENUM.PAGATE,
                stato: SPEDIZIONI_FILTRO_STATO_ENUM.CONSEGNATE,
                tipologia: SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.POSTPAGATE,
                data_filtro: SPEDIZIONI_FILTRO_DATA_ENUM.RANGE,
                data_from: da.toDate(),
                data_to: a.toDate(),
                corriere_ref: null,
                contatto_ref: contattoScelto.id,
                pagination_count: 0,
                pagination_page: 1,
                pagination_limit: 1000000000000
            }
            setFiltroSpedizioni(_filtro);


        } else {
            setPossoRicercare(false);

            // console.log("non ricerco perchje la ricerca no ne' valida")
        }

        setSpedizioniSelezionate([]);
        setTotaleFattura(0);

    }, [JSON.stringify(contattoScelto), dataA, dataDa, tipologia])

    useEffect(() => {

        // let tot = 0;
        // spedizioni.map((spedizione)=>tot+=parseFloat(spedizione.costo_totale));
        // setTotaleFattura(tot);

    }, [JSON.stringify(spedizioni)])


    useEffect(() => {

        if (possoRicercare) {
            console.log("ricerco", filtro);
            searchSpedizioni();
        }


    }, [setPossoRicercare, JSON.stringify(filtro)])


    const onSubmit = (e) => {
        e.preventDefault();

        setErroreDataFrom((dataDa === ''));
        setErroreDataFromMsg((dataDa === '') ? 'Obbligatorio!' : '');

        setErroreDataTo((dataA === ''));
        setErroreDataToMsg((dataA === '') ? 'Obbligatorio!' : '');

        setErroreDataTo(moment(dataDa, 'YYYY-MM-DD').isAfter(moment(dataA, 'YYYY-MM-DD')));
        setErroreDataToMsg(moment(dataDa, 'YYYY-MM-DD').isAfter(moment(dataA, 'YYYY-MM-DD')) ? 'Piu\' grande di "Dal"' : '');


        if(!erroreDataFrom && !erroreDataTo) {
            let fattura = {
                tipo: tipologia.value,
                contatto_ref: contattoScelto.id,
                data_from:moment(dataDa, 'YYYY-MM-DD').startOf('day').utc(true).toDate(),
                data_to: moment(dataA, 'YYYY-MM-DD').endOf('day').utc(true).toDate(),
                importo: totaleFattura,
                note: note,
                spedizioni: spedizioniSelezionate
            }
            console.log("creo fattura", fattura)
            creaFattura(fattura);
            setFatturaCreata(true)


        }

    }

    const onRowSelect = (row, isSelected, e) => {
        // let rowStr = '';

        let array = spedizioniSelezionate;
        if (isSelected) {
            array.push(row.id)
            let total = Math.abs(totaleFattura);
            total+=parseFloat(row.costo_totale);
            if(tipologia.value===1)total*=-1;
            setTotaleFattura(total);
        } else {
            array.pop(row.id)
            let total = Math.abs(totaleFattura);
            total-=parseFloat(row.costo_totale);
            if(tipologia.value===1)total*=-1;
            setTotaleFattura(total);
        }
        setSpedizioniSelezionate(array);

        console.log(e);
    }

    const onSelectAll = (isSelected, rows) => {
        if (isSelected) {
            let tot = 0;
            let array = [];
            for (let i = 0; i < rows.length; i++) {
                tot += parseFloat(rows[i].costo_totale);
                array.push(rows[i].id);
            }
            if(tipologia.value===1)tot*=-1;
            setTotaleFattura(tot);
            setSpedizioniSelezionate(array);
        } else {
            setSpedizioniSelezionate([]);
            setTotaleFattura(0);
        }
    }

    return <Fragment>


        {(!fatturaCreata)?<Form
            onSubmit={onSubmit}
        >

        {(contatto) ? contatto.nome : 'select clienti'}
        <ModalBody>


                <Row>
                    <Col md={4}>
                        <FormGroup>
                            <Select
                                defaultValue={tipologia}
                                placeholder={"tipologia"}
                                name="tipologia"
                                id="tipologia"
                                options={tipoDocumento}
                                onChange={(e => {
                                    setTipologia(e)
                                })}
                            />
                            <small className="mt-1 mb-1 badge badge-default badge-dark text-light float-left">Tipologia Documento</small>
                        </FormGroup>
                    </Col>

                    <Col md={4}>
                        <Input name={"date_from"} onChange={(e) => {
                            setDataDa(e.target.value);
                        }}
                               type="date"/>
                        <small className="mt-1 mb-1 badge badge-default badge-dark text-light float-left">Dal </small>
                        {(erroreDataFrom) ? <small className=" badge badge-default badge-danger text-white">{erroreDataFromMsg}</small> : ""}
                        <small className="mt-1 mb-1 badge badge-default badge-light text-dark float-right">Obbligatorio</small>

                    </Col>
                    <Col md={4}>
                        <Input name={"date_to"} onChange={(e) => {
                            setDataA(e.target.value);
                        }} type="date"/>
                        <small className="mt-1 mb-1 badge badge-default badge-dark text-light float-left">Fino al </small>
                        {(erroreDataTo) ? <small className=" badge badge-default badge-danger text-white">{erroreDataToMsg}</small> : ""}
                        <small className="mt-1 mb-1 badge badge-default badge-light text-dark float-right">Obbligatorio</small>

                    </Col>
                </Row>

                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Input type="textarea" placeholder="Inserisci delle note" rows="2" onChange={(e)=>{
                                console.log("note", e.target.value);
                                setNote(e.target.value);
                            }}/>
                            <small className="mt-1 mb-4 badge badge-default badge-dark text-light float-left">Note</small>
                        </FormGroup>

                    </Col>
                </Row>


                {(possoRicercare) ? <div>
                    <InputGroup >
                        <InputGroupAddon addonType={"prepend"}>
                            <InputGroupText>Totale Fattura:</InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder={"Totale fattura"} value={totaleFattura} onChange={(e)=>{
                            setTotaleFattura(e.target.value)
                        }} />
                        <InputGroupAddon addonType={"append"}>
                            <InputGroupText>€</InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>





                    <h5>Spedizioni:</h5>
                    <BootstrapTable className="spedizioni-in-fattura-table no-wrap  v-middle mb-0" data={spedizioni} striped hover
                        // pagination
                                    selectRow={{
                                        mode: 'checkbox',
                                        columnWidth: '40px',
                                        // clickToSelect: true,
                                        onSelect: onRowSelect,
                                        onSelectAll: onSelectAll

                                    }}

                                    options={{
                                        defaultSortName: 'data_spedizione',  // default sort column name
                                        defaultSortOrder: 'asc'  // default sort order
                                    }}


                                    tableHeaderClass='mb-0'

                    >

                        <TableHeaderColumn isKey={true} width='250'
                                           dataField='spedizione'
                                           dataFormat={(c, r) => {
                                               // console.log("C",r);
                                               const indirizzo = (r.indirizzo_to)?`<br/>To: ${r.indirizzo_to.comune} - ${r.indirizzo_to.indirizzo}`:'';
                                               return `#${r.id} | ${r.quantita}x | ${r.spedizione_tipo.nome} - ${r.costo_totale}€ ${indirizzo}`
                                           }}>
                            Spedizione
                        </TableHeaderColumn>
                        <TableHeaderColumn width='100' dataSort={true} dataField='data_spedizione'
                                           caretRender={(direction) => (direction === 'asc') ? <i className={"fas fa-arrow-up"}></i> :
                                               <i className={"fas fa-arrow-down"}></i>}
                                           dataFormat={(c, r) => {
                                               return moment(c).format("DD/MM/YYYY H:mm:ss")
                                           }}>
                            Data
                        </TableHeaderColumn>
                    </BootstrapTable>
                </div> : ''}


        </ModalBody>
        <ModalFooter>
            {(contattoScelto && daValida && aValida) ? <Button color="primary" type="submit">Salva fattura</Button> : ''}
        </ModalFooter>
    </Form>:'fattura creata'}


    </Fragment>


}

const mapStateToProps = (state, ownProps) => ({
    filtro: state.spedizioni.filtro,
    spedizioni: state.spedizioni.spedizioni,

});

const mapDispatchToProps = {
    setFiltroSpedizioni,
    searchSpedizioni,
    creaFattura
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NuovaFattura)


