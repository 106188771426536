import {
    ADD_TIPO_EXTRA_A_LIST_EXTRA,
    RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA,
    TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED,
    UPDATE_TIPO_EXTRA_A_LIST_EXTRA
} from "./_constant";
import {UPDATE_TIPO_SPEDIZIONE_A_LISTINO} from "../../listini_pack/redux/_constant";

const tipoExtraPerListExtra = (state = [], action) => {
    switch (action.type) {
        case TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED:
            return action.tipologie_extra;
        case RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA:
                return action.tipologie_extra;
        case ADD_TIPO_EXTRA_A_LIST_EXTRA:
            return [
                ...state,
                action.tipologia_extra
                // {
                //     id: action.spedizione.id,
                //     nome: action.spedizione.nome,
                //     costo: action.spedizione.costo,
                //     ordi
                // }
            ];
        case UPDATE_TIPO_EXTRA_A_LIST_EXTRA:
            // console.log(action)
            // console.log(state)
            return state.map(tipologia => (tipologia.id === action.tipologia.id ? action.tipologia : tipologia));


        default:
            return state;
    }
};

export default tipoExtraPerListExtra;
