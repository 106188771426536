import React from "react";
import {Modal, ModalHeader, ListGroup, ListGroupItem} from "reactstrap";
import {ListExtrasVisiblityFilter} from "../../redux/actions";
import FilterLink from "../containers/FilterLink";
import AddListExtraContainer from "../containers/AddListExtraContainer";
// import FilterLink from "../containers/listiniFilterLink";
// import AddListExtra from "../containers/addListExtra";

class ListExtraFilterComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    render() {
        return (
            <div>
                <div className="p-3">
                    <span onClick={this.toggle} className="btn btn-danger d-block text-white">Nuovo Listino</span>
                </div>
                <div className="divider"></div>
                <ListGroup className="contact-filters">
                    <FilterLink filter={ListExtrasVisiblityFilter.SHOW_ALL}>
                        <i className="fas fa-clipboard-list mr-2"></i> All
                    </FilterLink>
                    <FilterLink filter={ListExtrasVisiblityFilter.STARRED_EXTRAS}>
                        <i className="fas fa-star mr-2"></i> Starred
                    </FilterLink>
                    <ListGroupItem>
                        <hr/>
                    </ListGroupItem>
                </ListGroup>
                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="md">
                    <ModalHeader toggle={this.toggle}>Aggiungi Listino</ModalHeader>
                    <AddListExtraContainer onListinoAggiunto={this.toggle}/>
                </Modal>
            </div>
        );
    }
}

export default ListExtraFilterComponent;
