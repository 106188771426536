import { CORRIERE_DETAILS } from '../../../redux/constants';
const CorriereDetails = (state = '', action) => {
	switch (action.type) {
		case CORRIERE_DETAILS:
			return action.id;
		default:
			return state;
	}
};

export default CorriereDetails;
