import {
    ADD_TIPO_SPEDIZIONE_A_LISTINO,
    RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO,
    TIPO_SPEDIZIONI_PER_LISTINI_RELOADED,
    UPDATE_TIPO_SPEDIZIONE_A_LISTINO
} from "./_constant";

const tipoSpedizioniPerListino = (state = [], action) => {
    switch (action.type) {
        case TIPO_SPEDIZIONI_PER_LISTINI_RELOADED:
            return action.tipologie_pack;
        case RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO:
                return action.tipologie_pack;
        case ADD_TIPO_SPEDIZIONE_A_LISTINO:
            return [
                ...state,
                action.tipologia
            ];
        //
        case UPDATE_TIPO_SPEDIZIONE_A_LISTINO:
            // console.log(action)
            // console.log(state)
            return state.map(tipologia => (tipologia.id === action.tipologia.id ? action.tipologia : tipologia));
        //
        // case TOGGLE_STARRED_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, starred: !contact.starred} : contact));
        //
        // case DELETE_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, deleted: !contact.deleted} : contact));

        default:
            return state;
    }
};

export default tipoSpedizioniPerListino;
