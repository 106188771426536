import getAxios from "../../../rest/myaxios";
import {
    REST_CORRIERI_CREATE, REST_CORRIERI_GET_IMG_PROFILO, REST_CORRIERI_GET_SPEDIZIONI,
    REST_CORRIERI_LIST
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _getAll(search, filtro, page = 1, limit = 20) {

    try {
        let request = await axios.get(REST_CORRIERI_LIST, {
            params: {
                search,
                filtro,
                limit,
                page
            }
        });
        return request.data.corrieri;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}


async function _create(corriere) {

    // inserire formdata
    let data = new FormData();
    data.append('azienda', corriere.azienda);
    data.append('citta', corriere.citta);
    data.append('codfisc', corriere.codfisc);
    data.append('cognome', corriere.cognome);
    data.append('email', corriere.email);
    data.append('indirizzo', corriere.indirizzo);
    data.append('listino_extra', corriere.listino_extra);
    data.append('listino_pack', corriere.listino_pack);
    data.append('nome', corriere.nome);
    data.append('phone', corriere.phone);
    data.append('piva', corriere.piva);
    data.append('rag_sociale', corriere.rag_sociale);
    data.append('image', corriere.image);


    try {
        let request = await axios.post(REST_CORRIERI_CREATE, data);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        return request.data.corriere;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}


async function _getImageProfilo(corriere_id, image) {

    try {
        const url = REST_CORRIERI_GET_IMG_PROFILO + "/" + image;

        let request = await axios.get(url, {responseType: 'blob'});

        var binaryData = [];
        binaryData.push(request.data);
        return URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));

    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}


async function _getSpedizioni(corriere_ref, from, to) {

    try {

        let request = await axios.get(REST_CORRIERI_GET_SPEDIZIONI, {
            params: {
                corriere_ref,
                from,
                to
            }
        });

        return request.data.spedizioni;

    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}




export const search = _getAll;
export const create = _create;
export const getImageProfilo = _getImageProfilo;
export const getSpedizioni = _getSpedizioni;

