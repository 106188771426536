export const FETCH_FROM_SERVER_START = "FETCH_FROM_SERVER_START";
export const FETCH_FROM_SERVER_SUCCESS = "FETCH_FROM_SERVER_SUCCESS";
export const FETCH_FROM_SERVER_ERROR = "FETCH_FROM_SERVER_ERROR";



export const APPUNTAMENTI_RELOADED = "APPUNTAMENTI_RELOADED";
export const APPUNTAMENTI_ADD = "APPUNTAMENTI_ADD";
export const APPUNTAMENTO_UPDATE = "APPUNTAMENTO_UPDATE";

// SESSION CONSTANTS
export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGGED = 'SESSION_LOGGED';
export const SESSION_LOGGED_OUT = 'SESSION_LOGGED_OUT';
export const SESSION_LOADING = 'SESSION_LOADING';
export const SESSION_RESTORED = 'SESSION_RESTORED';
export const SESSION_FETCHING = 'SESSION_FETCHING';
export const SESSION_FETCHING_SESSIONS = 'SESSION_FETCHING_SESSIONS';
export const SESSION_FETCHING_ERROR = 'SESSION_FETCHING_ERROR';

// SETTINGS CONSTANTS
export const LOGO_BG = 'LOGO_BG';
export const NAVBAR_BG = 'NAVBAR_BG';
export const SIDEBAR_BG = 'SIDEBAR_BG';
export const THEME = 'THEME';
export const DIRECTION = 'DIRECTION';
export const SIDEBAR_POSITION = 'SIDEBAR_POSITION';
export const HEADER_POSITION = 'HEADER_POSITION';
export const LAYOUT = 'LAYOUT';
export const SIDEBAR_TYPE = 'SIDEBAR_TYPE';

// CHAT CONSTANTS
export const SELECTED_CHAT = 'SELECTED_CHAT';
export const SEARCH_USER = 'SEARCH_USER';
export const MSG_SUBMIT = 'MSG_SUBMIT';

// CONTACT CONSTANTS
export const CONTACTS_RELOADED = 'CONTACTS_RELOADED';
export const ADD_CONTACT = 'ADD_CONTACT';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const CONTACT_DETAILS = 'CONTACT_DETAILS';
export const EDIT_CONTACT = 'EDIT_CONTACT';
export const SET_VISIBILITY_FILTER = 'SET_VISIBILITY_FILTER';
export const FILTER_CONTACT = 'FILTER_CONTACT';
export const TOGGLE_STARRED_CONTACT = 'TOGGLE_STARRED_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';

// EMAIL CONSTANTS
export const STAR_EMAIL = 'STAR_EMAIL';
export const IMPORTANT_EMAIL = 'IMPORTANT_EMAIL';
export const OPEN_EMAIL = 'OPEN_EMAIL';
export const TRASH_EMAIL = 'TRASH_EMAIL';
export const ASSIGN_FOLDER = 'ASSIGN_FOLDER';
export const ASSIGN_LABEL = 'ASSIGN_LABEL';
export const SET_SELECTED_EMAIL = 'SET_SELECTED_EMAIL';
export const FILTER_EMAIL = 'FILTER_EMAIL';
export const SET_EMAIL_VISIBILITY_FILTER = 'SET_EMAIL_VISIBILITY_FILTER';



// CORRIERI CONSTANTS
export const CORRIERI_RELOADED = 'CORRIERI_RELOADED';
export const ADD_CORRIERE = 'ADD_CORRIERE';
export const UPDATE_CORRIERE = 'UPDATE_CORRIERE';
export const CORRIERE_DETAILS = 'CORRIERE_DETAILS';
export const EDIT_CORRIERE = 'EDIT_CORRIERE';
export const FILTER_CORRIERE = 'FILTER_CORRIERE';
export const DELETE_CORRIERE = 'DELETE_CORRIERE';

// SPEDIZIONI CONSTANTS
// export const CORRIERI_RELOADED = 'CORRIERI_RELOADED';
// export const ADD_CORRIERE = 'ADD_CORRIERE';
// export const UPDATE_CORRIERE = 'UPDATE_CORRIERE';
export const SPEDIZIONI_DETAILS = 'SPEDIZIONE_DETAILS';
// export const EDIT_CORRIERE = 'EDIT_CORRIERE';
// export const FILTER_CORRIERE = 'FILTER_CORRIERE';
// export const DELETE_CORRIERE = 'DELETE_CORRIERE';
