import getAxios from "../../../rest/myaxios";
import {
    REST_LISTINI_PACK_CREATE,
    REST_LISTINI_PACK_LIST,
    REST_LISTINI_EXTRA_JSON,
    REST_LISTINI_PACK_JSON,
    REST_LISTINI_PACK_CREATE_TIPOLOGIA,
    REST_LISTINI_PACK_GET_TIPOLOGIE,
    REST_LISTINI_PACK_RIORDINA_TIPOLOGIE,
    REST_CONTATTI_GET_IMG_PROFILO,
    REST_LISTINI_PACK_GET_IMAGE, REST_LISTINI_PACK_UPDATE_TIPOLOGIA
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _getAll(search, filtro, page = 1, limit = 20) {

    try {
        let request = await axios.get(REST_LISTINI_PACK_LIST, {
            params: {
                search,
                filtro,
                limit,
                page
            }
        });
        return request.data.listini;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }

}

async function create_listino(listino) {
    let data = new FormData();
    data.append('nome', listino.nome);
    if (listino.listino_ref) data.append('listino_ref', listino.listino_ref)
    data.append('postpagato', listino.postpagato)
    data.append('prezzo', listino.prezzo)
    data.append('descrizione', listino.descrizione)
    data.append('image', listino.image)

    try {
        let request = await axios.post(REST_LISTINI_PACK_CREATE, data);
        return request.data.listino_pack;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}


async function getJson() {

    try {
        let request = await axios.get(REST_LISTINI_PACK_JSON);
        return request.data.listini;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }


}


async function aggiungiTipologiaPack(tipologia_pack) {
    let data = new FormData();
    data.append('nome', tipologia_pack.nome);
    data.append('listino_pack_ref', tipologia_pack.listino_pack_ref)
    data.append('prezzo', tipologia_pack.prezzo)
    data.append('descrizione', tipologia_pack.descrizione)
    data.append('image', tipologia_pack.image)

    try {
        let request = await axios.post(REST_LISTINI_PACK_CREATE_TIPOLOGIA, data);

        return request.data.tipologia_pack;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function updateTipologiaPack(spedizione) {
    let data = new FormData();
    data.append('spedizione', JSON.stringify(spedizione));

    try {
        let request = await axios.post(REST_LISTINI_PACK_UPDATE_TIPOLOGIA, data);

        return request.data.tipologia_pack;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function _getTipologie(listino_pack_ref) {
    try {
        let request = await axios.get(REST_LISTINI_PACK_GET_TIPOLOGIE, {
            params: {
                listino_pack_ref
            }
        });
        return request.data.tipologie_pack;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function _riordinaTipologiePack(tipologie_da_riordinare) {
    try {
        let request = await axios.post(REST_LISTINI_PACK_RIORDINA_TIPOLOGIE, {
            tipologie_da_riordinare
        });
        return true;
    } catch (e) {
        throw new Error('Error Fetching Api Server')
    }
}

async function _getImageProfilo(image) {

    try {
        const url = REST_LISTINI_PACK_GET_IMAGE + "/" + image;

        let request = await axios.get(url, { responseType: 'blob' });

        var binaryData = [];
        binaryData.push(request.data);
        return URL.createObjectURL(new Blob(binaryData, {type: "application/zip"}));

    } catch (e) {
        return false
        // throw new Error('Error Fetching Api Server')
    }

}


export const search = _getAll;
export const create = create_listino;
export const json = getJson;
export const getImage = _getImageProfilo;
export const createTipologia = aggiungiTipologiaPack;
export const updateTipologia = updateTipologiaPack;
export const getTipologie = _getTipologie;
export const riordinaTipologiePack = _riordinaTipologiePack;

