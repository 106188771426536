import React from 'react';
import {
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    CustomInput,
    FormGroup,
    Form,
    Row,
    Col,
    UncontrolledTooltip,
    Button
} from 'reactstrap';
import logo_staging from '../../assets/images/bmlogo-staging.png';
import logo_prod from '../../assets/images/bmlogo.png';
import bgimage from '../../assets/images/background/bikemessenger-bg.jpg';
import {connect} from "react-redux";
import {login, logout} from "../../redux/session/action";
import {Redirect} from "react-router-dom";


let logo = (window.ENVIRONMENT==='production' || process.env.REACT_APP_ENVIRONMENT==='production' ) ? logo_prod : logo_staging;


const sidebarBackground = {
    backgroundImage: "url(" + bgimage + ")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom center",
    backgroundSize: 'cover'

};

//TODOGP creare una pagina che riepiloga le variabili ENV
// console.log("process.env.NODE_ENV", process.env)

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // username: "gianpaolo.faedda@gmail.com",
            // password: "123456",
            username: "",
            password: "",
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeInputLogin = this.onChangeInputLogin.bind(this);

    }
    componentDidMount() {

        console.log("props login", this.props)


        setTimeout(()=>{
            // this.props.logout()
        },5000)

    }

    onChangeInputLogin(event) {
        if (event.target.name === "username") {
            this.setState({username: event.target.value});
        } else if (event.target.name === "password") {
            this.setState({password: event.target.value});
        }
    }


    handleSubmit(event) {
        event.preventDefault();

        // console.log(this.state);
        // console.log(this.firebase)

        this.props.login(this.state.username, this.state.password)
        // this.firebase.login({
        // 	email: this.state.username,
        // 	password: this.state.password
        // });

    }


    render() {
        // console.log("state  dddd  ", this.props);

        if(this.props.session.user && this.props.location.state) {
            window.location=this.props.location.state.from
            if(this.props.location.state.from=="/authentication/login")window.location="/";
            else window.location=this.props.location.state.from
            // return <Redirect to={this.props.location.state.from.pathname}/>
            // console.log("REDIRECCCCCCT",this.props.location.state.from.pathname)
        }


        return <div className="">
            {/*--------------------------------------------------------------------------------*/}
            {/*Login Cards*/}
            {/*--------------------------------------------------------------------------------*/}
            <div className="auth-wrapper d-flex no-block justify-content-center align-items-center" style={sidebarBackground}>
                <div className="auth-box on-sidebar">
                    <div id="loginform">
                        <div className="logo">
                            <span className="db"><img src={logo} alt="logo"/></span>
                            <h5 className="font-medium mb-3">Sign In to Admin</h5>
                        </div>
                        <Row>
                            <Col xs="12">
                                <Form
                                    ref={c => {
                                        this.form = c;
                                    }}
                                    onSubmit={this.handleSubmit}
                                    className="mt-3" id="loginform" action="/dashbaord"
                                >
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ti-user"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input type="text" name={"username"} placeholder="Username" value={this.state.username}
                                               onChange={this.onChangeInputLogin} required/>
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="ti-pencil"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>

                                        <Input type="password" name={"password"} placeholder="Password" value={this.state.password}
                                               onChange={this.onChangeInputLogin}
                                               required/>


                                    </InputGroup>
                                    <div className="d-flex no-block align-items-center mb-3">
                                        <CustomInput type="checkbox" id="exampleCustomCheckbox" label="Remember Me"/>
                                        <div className="ml-auto">
                                            <a href="/authentication/reset-password" id="to-recover" className="forgot text-dark float-right"><i
                                                className="fa fa-lock mr-1"></i> Forgot pwd?</a>
                                        </div>
                                    </div>
                                    <Row className="mb-3">
                                        <Col xs="12">
                                            <Button color="primary" size="lg" type="submit" block>Log In</Button>
                                        </Col>
                                    </Row>
                                    <div className="text-center mb-2">
                                        <div className="social">
                                            <Button id="UncontrolledTooltipExample1" className="btn-facebook mr-2" color="primary">
                                                <i aria-hidden="true" className="fab fa-facebook-f"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample1">
                                                Facebook</UncontrolledTooltip>
                                            <Button id="UncontrolledTooltipExample2" className="btn-googleplus" color="danger">
                                                <i aria-hidden="true" className="fab fa-google-plus-g"></i>
                                            </Button>
                                            <UncontrolledTooltip placement="top" target="UncontrolledTooltipExample2">
                                                Google Plus</UncontrolledTooltip>
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        Don&apos;t have an account? <a href="/authentication/register" className="text-info ml-1"><b>Sign Up</b></a>
                                        <br/>

                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>;
    }
}


const mapStateToProps = state => ({
    ...state
});


const mapDispatchToProps = {
    login: login,
    logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
