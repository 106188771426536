import {all, call, put, takeEvery} from 'redux-saga/effects'
import  Api from "../rest";
import {CONTACTS_RELOADED} from "../redux/constants";
const delay = (ms) => new Promise(res => setTimeout(res, ms))

// ...


// Our worker Saga: will perform the async increment task
export function* incrementAsync(action) {
    // yield delay(2000)
    yield put({type: 'FETCHING_API_START'})

    const data = yield call(delay, 2000)

    console.log(action)
    console.log("data",data)
    yield put({type: 'FETCHING_API_SUCCESS'})
}


export function* caricaContatti(action) {
    console.log("actionnnnn", action)
    try {
        const result = yield call( Api.contatti.search, '/products')
        yield put({type: CONTACTS_RELOADED, contacts:result.contatti.rows})
    } catch (error) {
        yield put({type: 'FETCHING_API_ERROR', error})
    }
}


export function* watchIncrementAsync(action) {
    yield takeEvery('INCREMENT_ASYNC', incrementAsync)
    yield takeEvery('CARICA_CONTATTID', caricaContatti)
}


// function* watchIncrementAsync(action) {
//     try {
//         const { subscriber, msisdn } = action;
//         const subscriberDetails = yield axios.patch(`${ENDPOINTS.SUBSCRIBER.PATCH.URL}${msisdn}`, subscriber).then(response => response.data);
//         yield put({ type: types.UPDATE_SUBSCRIBER_SUCCESS, data: subscriberDetails });
//     } catch (error) {
//         yield put({ type: types.UPDATE_SUBSCRIBER_FAILED, error });
//     }
// }


export function* provaSaga() {
    yield all([
        watchIncrementAsync()
        // takeLatest(types.CREATE_SUBSCRIBER, createSubscriber),
        // takeLatest(types.UPDATE_SUBSCRIBER, updateSubscriber),
    ])
}
