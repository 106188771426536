import {
    CONTATTI_FILTRO_ELIMINATO,
    CONTATTI_FILTRO_LIMIT,
    CONTATTI_FILTRO_LOAD_PAGE,
    CONTATTI_FILTRO_PAGINATION_COUNT, CONTATTI_FILTRO_PREFERITO,
    CONTATTI_FILTRO_RESET, CONTATTI_FILTRO_SEARCH, CONTATTI_FILTRO_TIPO
} from "./contatti_redux_types";

const defaults = {
    search: '',
    tipo: 'tutti',
    eliminato: false,
    preferito: false,
    pagination_count: 0,
    pagination_page: 1,
    pagination_limit: 50
}


const filtro = (state = defaults, action) => {
    switch (action.type) {

        case CONTATTI_FILTRO_RESET:
            return defaults;
        case CONTATTI_FILTRO_LOAD_PAGE:
            return {...state, pagination_page: action.payload.page};
        case CONTATTI_FILTRO_PAGINATION_COUNT:
            return {...state, pagination_count: action.count};
        case CONTATTI_FILTRO_LIMIT:
            let limite = action.limite;
            return {...state, pagination_page: 1,  pagination_limit: limite};
        case CONTATTI_FILTRO_SEARCH:
            return {...state, search: action.payload.search, pagination_page: 1};
        case CONTATTI_FILTRO_TIPO:
            return {...state, tipo: action.payload.tipo, pagination_page: 1};
        case CONTATTI_FILTRO_ELIMINATO:
            return {...state, eliminato: action.payload.eliminato, pagination_page: 1};
        case CONTATTI_FILTRO_PREFERITO:
            return {...state, preferito: action.payload.preferito, pagination_page: 1};

        default:
            return state;
    }
};


export {filtro};
