import React from 'react';
import {Provider} from "react-redux";
import {configureStore} from "./redux/store";
// import fbConfig from "./firebase/fbConfig";
import {createFirestoreInstance} from "redux-firestore";
// import {ReactReduxFirebaseProvider, ReduxFirestoreProvider} from "react-redux-firebase";
import Home from "./views/home/home";

const store = configureStore();

//TODOGP spostare Props per react redux firestore
// const rrfConfig = {
//     userProfile: 'users',
//     useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
// };
//
// const rrfProps = {
//     firebase: fbConfig,
//     config: rrfConfig,
//     dispatch: store.dispatch,
//     createFirestoreInstance: createFirestoreInstance
// };


const App = () => (
    <Provider store={store}>
        {/*<ReduxFirestoreProvider {...rrfProps}>*/}
            <Home/>
        {/*</ReduxFirestoreProvider>*/}
    </Provider>
);

export default App;
