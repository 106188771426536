// import defaul_profile_img from "../../assets/images/users/1.jpg";
import {
    ADD_LIST_EXTRA,
    ADD_TIPO_EXTRA_A_LIST_EXTRA, DELETE_LIST_EXTRA, EDIT_LIST_EXTRA, FILTER_LIST_EXTRAS, LIST_EXTRA_DETAILS,
    LIST_EXTRAS_RELOADED, RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA, SET_VISIBILITY_FILTER_LIST_EXTRAS,
    TIPO_EXTRA_PER_LIST_EXTRAS_RELOADED, TOGGLE_STARRED_LISTEXTRA, UPDATE_LIST_EXTRA
} from "./_constant";
import listextraDetails from "./listextraDetails";
import {
    LISTINI_EXTRA_LAZY_LOADING, SAGA_LISTINI_EXTRA_ADD,
    SAGA_LISTINI_EXTRA_JSON,
    SAGA_LISTINI_EXTRA_SEARCH,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE,
    SAGA_LISTINI_EXTRA_TIPOLOGIE_GET, SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA, SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE
} from "./listini-extra-redux-types";
import listextraSearch from "./listextraSearch";
import listextraVisibilityFilter from "./listextraVisibilityFilter";
import {SAGA_LISTINI_PACK_ADD, SAGA_LISTINI_PACK_TIPOLOGIA_ADD, SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA} from "../../listini_pack/redux/listini-redux-types";
import {RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO} from "../../listini_pack/redux/_constant";

export const ListExtrasVisiblityFilter = {
    SHOW_ALL: "SHOW_ALL",
    FREQUENT_EXTRAS: "FREQUENT_EXTRAS",
    STARRED_EXTRAS: "STARRED_EXTRAS",
    ENGINEERING_DEPARTMENT_EXTRAS: "ENGINEERING_DEPARTMENT_EXTRAS",
    SALES_DEPARTMENT_EXTRAS: "SALES_DEPARTMENT_EXTRAS",
    SUPPORT_DEPARTMENT_EXTRAS: "SUPPORT_DEPARTMENT_EXTRAS",
    SEARCH_EXTRA: "SEARCH_EXTRA",
    DELETED_EXTRA: "DELETED_EXTRA"
};


export const getTipoExtraPerListExtra = (listino_extra_ref) => {
    return (dispatch, getState, {getFirestore}) => {

        // console.log("listino_extra_ref", listino_extra_ref)
        dispatch({
            type: SAGA_LISTINI_EXTRA_TIPOLOGIE_GET,
            payload: {
                listino_extra_ref
            }
        });
    }
}


export const listiniExtraFetch = (lazy = false) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        if (lazy) {
            dispatch({
                type: LISTINI_EXTRA_LAZY_LOADING,
            });
        } else {
            dispatch({
                type: LISTINI_EXTRA_LAZY_LOADING,
                reset: true
            })
        }
        let l = (lazy) ? state.listextra.lazy + 1 : state.listextra.lazy;

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_LISTINI_EXTRA_SEARCH,
            payload: {
                search: state.listextra.listextraSearch,
                filtro: state.listextra.listextraVisibilityFilter,
                lazy: l,
            }
        })


    }
}

export const listiniExtraGetJson = () => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: SAGA_LISTINI_EXTRA_JSON,
        });
    }
};


export const addTipoExtra = (tipologia_extra) => {
    console.log("aggiungo tipo extra", tipologia_extra)
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE,
            payload: {
                tipologia_extra,
            }
        });
    }
}

export const modificaTipoExtra = (tipologia_extra) => {
    console.log("modifico tipo extra", tipologia_extra)
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE,
            payload: {
                tipologia_extra,
            }
        });
    }
}


export const riordinaTipoExtra = (tipoExtra) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        let tipologie_extra_riordinate = []
        let tipologie_extra_da_riordinare = [];
        tipoExtra.forEach((o, i) => {
            let ordinamento = i + 1;
            o.ordinamento = ordinamento;
            tipologie_extra_riordinate.push(o);

            tipologie_extra_da_riordinare.push({
                id: o.id,
                ordinamento: o.ordinamento
            })
        });


        dispatch({
            type: SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA,
            payload: {tipologie_extra: tipologie_extra_da_riordinare}
        });

        dispatch({type: RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA, tipologie_extra: tipologie_extra_riordinate})


    }
}


// export const riordinaTipoExtra = (tipoExtra) => {
//     return (dispatch, getState, {getFirestore, getFirebase}) => {
//         const state = getState();
//         const fstore = getFirestore();
//         var batch = fstore.batch();
//
//         let _extras = []
//         tipoExtra.forEach((o, i) => {
//             let ordinamento = i + 1;
//             var sfRef = fstore
//                 .collection("listextra").doc(o.listino_ref)
//                 .collection("tipo_extra").doc(o.id);
//             batch.update(sfRef, {"ordinamento": ordinamento});
//             o.ordinamento = ordinamento;
//             _extras.push(o);
//         })
//
//         batch.commit().then(function () {
//             console.log("aggiornamento eseguito")
//         });
//
//         dispatch({
//             type: RIORDINO_TIPO_EXTRA_DEL_LIST_EXTRA,
//             spedizioni: _extras
//         })
//
//     }
// }


export const addListExtra = (listino) => {
    // nome = nome.toLowerCase();

    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_LISTINI_EXTRA_ADD,
            payload: {
                listino_extra: listino,
            }
        });


    }


}

export const aggiornaListino = (id, values, flag) => {
    // console.log(values)

    return (dispatch, getState, {getFirestore}) => {


        let state = getState();
        let fstore = getFirestore();


        let key = state.listextra.listini.findIndex((e) => e.id == id);


        let contatto_in_edit = Object.assign({}, state.listextra.listini[key]);


        delete contatto_in_edit.id;
        delete contatto_in_edit.image;
        console.log("contatto_in_edit", contatto_in_edit);


        // console.log("contatto", state.contactReducer.contact)
        fstore.collection(
            "/listextra").doc(id).update(contatto_in_edit).then(r => {
            console.log(r)

        });


        dispatch({
            type: EDIT_LIST_EXTRA,
            flag
        });

    }

};

export const setEditListinoFlag = flag => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: EDIT_LIST_EXTRA,
            flag
        })
    }
};
export const updateListino = (id, field, value) => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: UPDATE_LIST_EXTRA,
            id: id,
            field: field,
            value: value
        });

    };
}


export const listExtraSearch = searchTerm => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: FILTER_LIST_EXTRAS,
            payload: searchTerm
        })

    }


};

export const setListiniVisibilityFilter = (filter) => {
    return (dispatch, getState, {getFirestore}) => {
        // console.log("camgiato", filter);


        dispatch({
            type: SET_VISIBILITY_FILTER_LIST_EXTRAS,
            filter
        })


    }
}

export const listinoDetails = id => ({
    type: LIST_EXTRA_DETAILS,
    id
});

export const toggleStarredListino = id => ({
    type: TOGGLE_STARRED_LISTEXTRA,
    id
});

export const deleteListino = id => ({
    type: DELETE_LIST_EXTRA,
    id
});
