import {FILTER_LISTINI, SET_VISIBILITY_FILTER_LISTINI} from "./_constant";

const ListiniSearch = (state = '', action) => {
	switch (action.type) {
		case FILTER_LISTINI:
			return action.payload
		// case SET_VISIBILITY_FILTER_LISTINI:
		// 	return state = ''
		default:
			return state
	}
}

export default ListiniSearch;
