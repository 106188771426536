import React from "react";
import PropTypes from "prop-types";
import { Table } from 'reactstrap';
import Listino from "./listino";

const ListiniList = ({ active, toggleStarredListino, deleteListino, listinoClick, showRightPart, ...state}) => (
	<div className="table-responsive contact-list">
		<Table className="table contact-table no-wrap table-hover v-middle mb-0">
			<tbody>
				{state.listini.listini.map(_listino => {
					// console.log(contact)
					return <Listino
						key={_listino.id}
						active={active}
						{..._listino}
						onStarredClick={() => toggleStarredListino(_listino.id)}
						onDeleteClick={() => deleteListino(_listino.id)}
						onListinoClick={() => { listinoClick(_listino.id);  showRightPart(); }}
					/>
				}
				)}
			</tbody>
		</Table>
	</div>
);

ListiniList.prototype = {
	listini: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			nome: PropTypes.string.isRequired,
			frequentlycontacted: PropTypes.bool.isRequired,
			starred: PropTypes.bool.isRequired,
			deleted: PropTypes.bool.isRequired
		}).isRequired
	).isRequired,
	toggleStarredListino: PropTypes.func.isRequired,
	deleteListino: PropTypes.func.isRequired,
	listinoClick: PropTypes.func.isRequired
};
export default ListiniList;
