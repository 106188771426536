import getAxios from "../../../rest/myaxios";
import {
    REST_SPEDIZIONI_CREATE, REST_SPEDIZIONI_SEARCH, REST_SPEDIZIONI_UPDATE
} from "../../../rest/rest-constants";

const axios = getAxios()

async function _search(filtro) {

    //TODOGP gestire ricerca per filtro

    try {
        let request = await axios.get(REST_SPEDIZIONI_SEARCH, {
            params: {
                filtro,
                // limit,
                // page
            }
        });
        return request.data.spedizioni;
    } catch (e) {
        console.error("wwwwww",e)
        throw new Error('Error Fetchingddd Api Server')
    }

}

async function _create(spedizione) {


    console.log("spedizione pre from", spedizione)

    // inserire formdata
    let data = new FormData();
    data.append('spedizione', JSON.stringify(spedizione));


    try {
        let request = await axios.post(REST_SPEDIZIONI_CREATE, data);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        return request.data;
    } catch (e) {

        throw new Error('Error Fetching Api Server')
    }

}


async function _update(spedizione) {


    console.log("spedizione pre from", spedizione)

    // inserire formdata
    let data = new FormData();
    data.append('spedizione', JSON.stringify(spedizione));


    try {
        let request = await axios.post(REST_SPEDIZIONI_UPDATE, data);

        // console.log("contatto", request.data.contatto);

        // setTimeout()

        return request.data;
    } catch (e) {

        throw new Error('Error Fetching Api Server')
    }

}


export const search = _search;
export const create = _create;
export const update = _update;

