import React, {Fragment, useEffect, useState} from "react";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Tooltip,
    Modal,
    ModalHeader,
    Label,
    InputGroup,
    FormGroup,
    InputGroupAddon,
    InputGroupText, Col, Row, ModalBody, Button
} from "reactstrap";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import user1 from "../../../../assets/images/users/1.jpg";
import API from "../../../../rest";
import AddPackToContact from "./addPackToContact";
import ScrollMenu from 'react-horizontal-scrolling-menu';
import {ExtraMenuItem} from "../../../spedizioni/components/ExtraMenuItem";
import AddIndirizzoToContact from "./addIndirizzoToContact";
import Select from 'react-select'

const Arrow = ({text, className}) => {
    return (
        <div
            className={className}
        >{text}</div>
    );
};


const ArrowLeft = Arrow({text: '<', className: 'arrow-prev'});
const ArrowRight = Arrow({text: '>', className: 'arrow-next'});

const MenuItem = ({text, selected}) => {
    return <div
        className={`menu-item ${selected ? 'active' : ''}`}
    >{text}</div>;
};

export const MenuSped = (list, selected) =>
    list.map(el => {
        const {id, nome} = el;
        return <MenuItem text={nome} key={id} selected={selected}/>;
    });


const ContactsDetails = ({
                             resetContatto,
                             fetchCorrieri, corrieri, creaSpedizione,
                             tipoExtraPerListExtra, getTipoExtraPerListExtra, tipoSpedizioniPerListino, getTipoSpedizioniPerListino,
                             acquisti, indirizzi, indirizzo, getAcquisti, getIndirizzi, contatto, onEditClick, editContactFlag, onChange,
                             ...props
                         }) => {
    const [spedizioneAggiunta, setSpedizioneAggiunta] = useState(false);
    let nome, lastname, codfisc, citta, phone, email, address, notes;

    const [immagineProfilo, setImmagineProfilo] = useState(user1);

    const [spedizione, setSpedizione] = useState({contatto_ref: contatto.id,quantita:1});
    const [spedizioneText, setSpedizioneText] = useState(JSON.stringify(spedizione));

    const [quantita, setQuantita] = useState(1);
    const [costoTotale, setCostoTotale] = useState('------');


    const [modalOpen, setModalOpen] = useState(false);

    const [modalIndirizzoOpen, setModalIndirizzoOpen] = useState(false);

    const [sceltaExtraVisibile, setSceltaExtraVisibile] = useState(false);

    const [sceltaCorriereVisibile, setSceltaCorriereVisibile] = useState(false);
    const [nomeCorriere, setNomeCorriere] = useState('');

    const [selectIndirizzi, setSelectIndirizzi] = useState([]);
    const [indirizzoSelezionato, setIndirizzoSelezionato] = useState(null);

    const [selectCorriere, setSelectCorriere] = useState([]);
    const [corriereSelezionato, setCorriereSelezionato] = useState(null);

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }

    const toggleIndirizzoModal = () => {
        setModalIndirizzoOpen(!modalIndirizzoOpen);
    }


    useEffect(() => {
        fetchCorrieri();
        // if (!fetchedImgs) {
        setImmagineProfilo(user1)

        if (contatto && contatto.image != null) {
            API.contatti.getImageProfilo(contatto.id, contatto.image).then((r) => {
                setImmagineProfilo(r)
            });
        } else {
        }

        let sp = {
            contatto_ref: contatto.id,
            quantita: 1
        }
        setSpedizione(sp);
        setSpedizioneText(JSON.stringify(sp));

        getAcquisti(contatto.id, true)
        getIndirizzi(contatto.id)
        getTipoSpedizioniPerListino(0);
        getTipoExtraPerListExtra(0);

        setSpedizioneAggiunta(false);

        // }
    }, [contatto.id]); // passing an empty array as

    useEffect(() => {
        spedizione.quantita = parseInt(quantita);
        setSpedizione(spedizione);
        setSpedizioneText(JSON.stringify(spedizione));

    }, [quantita]);


    useEffect(() => {
        calcolaCostoSpedizione();
    }, [contatto, spedizioneText]);

    useEffect(() => {
        console.log("indirizzo", indirizzo)
        if (indirizzo) {
            setModalIndirizzoOpen(false);

            setTimeout(() => {
                setIndirizzoSelezionato({value: indirizzo.id, label: `${indirizzo.comune} - ${indirizzo.indirizzo}`})
                console.log("propago selezioinati", indirizzo.id)
            }, 1000)

        }

    }, [indirizzo]);

    useEffect(() => {
        if (indirizzoSelezionato) {
            spedizione.indirizzo_to_ref = indirizzoSelezionato.value;
            setSpedizione(spedizione);
            setSpedizioneText(JSON.stringify(spedizione));

        }
        if (corriereSelezionato) {
            spedizione.corriere_ref = corriereSelezionato.value;
            setSpedizione(spedizione);
            setSpedizioneText(JSON.stringify(spedizione));
        }


    }, [indirizzoSelezionato, corriereSelezionato]);


    useEffect(() => {

        let options = [];
        indirizzi.forEach((o, i) => {
            console.log("indiriz", o)
            options.push({value: o.id, label: `${o.comune} - ${o.indirizzo}`});
        })
        setSelectIndirizzi(options);


    }, [indirizzi]);

    useEffect(() => {

        let options = [];
        corrieri.forEach((o, i) => {
            console.log("corriere", o)
            options.push({value: o.id, label: `${o.cognome} - ${o.nome} - ${o.email}`});
        })
        setSelectCorriere(options);


    }, [corrieri]);


    const onSelectContattoPackRef = (e) => {
        const pack = acquisti.find(acquisto => acquisto.id == e);
        console.log("contatto_pack", pack)

        spedizione.contatto_pack_ref = pack.id;
        spedizione.contatto_pack_nome = pack.listinoPack.nome;
        getTipoSpedizioniPerListino(pack.listinoPack.id);
        getTipoExtraPerListExtra(pack.listinoExtra.id);
        setSpedizione(spedizione);
        setSpedizioneText(JSON.stringify(spedizione));
    }


    const onSelectSpedizione = (e) => {
        const spedizione_scelta = tipoSpedizioniPerListino.find(sped => sped.id == e);
        // setSpedScelta(spedizione_scelta);
        spedizione.spedizione_tipologia_ref = spedizione_scelta.id;
        spedizione.spedizione_tipologia_nome = spedizione_scelta.nome;
        spedizione.costo_spedizione = parseFloat(spedizione_scelta.prezzo);

        setSpedizione(spedizione);
        setSpedizioneText(JSON.stringify(spedizione));
    }

    const calcolaCostoSpedizione = () => {
        console.log("calcolo costo", spedizione)

        if (spedizione.contatto_pack_ref === undefined || spedizione.spedizione_tipologia_ref === undefined) {
            setCostoTotale('----');
            return;
        }

        // spedizione.costo_spedizione = spedizione.costo_spedizione;
        spedizione.costo_totale = spedizione.costo_spedizione * spedizione.quantita;
        spedizione.costo_extra = 0;


        if (spedizione.extra !== undefined) {
            for (const [key, value] of Object.entries(spedizione.extra)) {
                console.log(`${key}:`, value);
                if (value) spedizione.costo_extra += value.prezzo * value.qt;
            }
        }


        spedizione.costo_totale = spedizione.costo_spedizione * spedizione.quantita + spedizione.costo_extra;

        console.log(spedizione)
        setCostoTotale(spedizione.costo_totale);
        setSpedizione(spedizione)

    }

    // console.log("------------------", spedizione)


    return <Fragment>
        {(spedizioneAggiunta) ? <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                <i className="far fa-address-card display-5"></i>
                <h4 className="mt-2">Spediazione aggiunta correttamente.</h4>
                <a className="btn btn-success" onClick={() => {
                    setSpedizioneAggiunta(false)
                }}>Chiudi.</a>
            </div>
            : ''}
        {contatto && !spedizioneAggiunta ? (
            <div className="">

                <div className="d-flex align-items-center p-4 border-bottom">
                    <div className="mr-3">
                        <img src={immagineProfilo} alt="user" className="rounded-circle" width="50" height="50"/>
                    </div>
                    <div>
                        <h5 className="message-title mb-0">Creazione spedizione per: {contatto.rag_sociale} {contatto.nome} {contatto.cognome}</h5>
                        <p className="mb-0">{contatto.citta}</p>
                        {/*<Button className="btn btn-success mt-2" size="sm" onClick={() => {*/}
                        {/*    console.log("devo settare a null il contatto")*/}
                        {/*}}>Annulla</Button>*/}
                    </div>
                </div>


                {spedizione.contatto_pack_ref === undefined ?
                    <Card>
                        <CardBody>
                            <div className="d-md-flex align-items-center">
                                <div>
                                    <CardTitle>Pacchetti Attivi</CardTitle>
                                    {/*<CardSubtitle>Post</CardSubtitle>*/}
                                </div>
                                <div className="ml-auto d-flex no-block align-items-center">
                                    <div className="dl">
                                    </div>
                                </div>
                            </div>

                            <ScrollMenu
                                data={
                                    acquisti.map(el => {
                                        const {id, listinoPack, listinoExtra} = el;
                                        return <MenuItem text={listinoPack.nome} key={id} selected={false}/>;
                                    })
                                    // MenuContattiPack(acquisti, 1)
                                }
                                arrowLeft={ArrowLeft}
                                arrowRight={ArrowRight}
                                // selected={(packAttivo) ? packAttivo.id : 0}
                                onSelect={onSelectContattoPackRef}
                            />
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardBody>
                            <div className="d-md-flex align-items-center">
                                <div>
                                    <CardTitle>Riepilogo Spedizione</CardTitle>
                                    {/*<CardSubtitle>Post</CardSubtitle>*/}
                                </div>
                                <div className="ml-auto d-flex no-block align-items-center">
                                    <div className="dl">
                                    </div>
                                </div>
                            </div>


                            <div className={'row'}>
                                <div className={'col-md-10'}>
                                    <h4>Listino: {spedizione.contatto_pack_nome} </h4>
                                </div>
                                <div className={'col-md-2 text-align-right'}>
                                    <h4> {costoTotale}€</h4>
                                </div>
                            </div>


                            {(spedizione.spedizione_tipologia_ref) ? <Fragment>

                                <Row>
                                    <Col md={8}>
                                        <h5>Tipologia: {spedizione.spedizione_tipologia_nome} ({spedizione.costo_spedizione}€)</h5>
                                    </Col>
                                    <Col md={4}>
                                        <InputGroup>
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    Qt.
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <input
                                                className="form-control"
                                                type="number"
                                                value={quantita}
                                                onChange={(e) => {
                                                    setQuantita(e.target.value);
                                                }}
                                                required
                                            /> </InputGroup>
                                    </Col>
                                </Row>
                                <br/>
                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <h6>Indirizzo:</h6>
                                    </div>
                                    <div className={'col-md-12'}>
                                        <FormGroup style={{width: '100%'}}>
                                            <InputGroup>

                                                <div style={{width: '80%', float: 'left'}}>
                                                    <Select
                                                        placeholder={"Seleziona destinazione"}
                                                        value={indirizzoSelezionato}
                                                        options={selectIndirizzi}
                                                        onChange={(v) => {
                                                            setIndirizzoSelezionato(v)

                                                        }}
                                                    />
                                                </div>

                                                <InputGroupAddon addonType="append">
                                                    <Button onClick={toggleIndirizzoModal} color="danger"><i className="fas fa-plus mr-3"></i></Button>
                                                </InputGroupAddon>

                                            </InputGroup>
                                            <p className="text-center mb-3 "></p>
                                        </FormGroup>
                                    </div>

                                </div>

                                <div className={'row'}>
                                    <div className={'col-md-12'}>
                                        <h6>Corriere:</h6>
                                    </div>
                                    <div className={'col-md-12'}>
                                        <FormGroup style={{width: '100%'}}>
                                            <InputGroup>
                                                <div style={{width: '80%', float: 'left'}}>
                                                    <Select
                                                        placeholder={"Seleziona corriere"}
                                                        value={corriereSelezionato}
                                                        options={selectCorriere}
                                                        onChange={(v) => {
                                                            setCorriereSelezionato(v)
                                                        }}
                                                    />
                                                </div>
                                            </InputGroup>
                                            <p className="text-center mb-3 "></p>
                                        </FormGroup>
                                    </div>
                                </div>

                                {(spedizione.contatto_pack_ref !== undefined
                                    && spedizione.spedizione_tipologia_ref !== undefined
                                    && spedizione.spedizione_tipologia_ref !== null) ? <Fragment>

                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <h6 onClick={() => {
                                                setSceltaExtraVisibile(!sceltaExtraVisibile)
                                            }}>
                                                Scegli Extra
                                                {sceltaExtraVisibile ? <i className="fas fa-chevron-up ml-3"></i> :
                                                    <i className="fas fa-chevron-down ml-3"></i>}
                                            </h6>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            {sceltaExtraVisibile ?
                                                <ScrollMenu
                                                    data={tipoExtraPerListExtra.map(el => {
                                                        // const {id, nome, prezzo} = el;
                                                        return <ExtraMenuItem key={el.id}
                                                                              onBtnClick={(extra, qt) => {
                                                                                  const codextra = `cod${extra.id}`;
                                                                                  if (spedizione.extra === undefined) spedizione.extra = {}

                                                                                  if (qt === 0) {
                                                                                      console.log("qt===0")
                                                                                      spedizione.extra[codextra] = null;
                                                                                  } else {
                                                                                      spedizione.extra[codextra] = {
                                                                                          nome: extra.nome,
                                                                                          qt: qt,
                                                                                          prezzo: extra.prezzo
                                                                                      }
                                                                                  }
                                                                                  setSpedizione(spedizione);
                                                                                  setSpedizioneText(JSON.stringify(spedizione))
                                                                              }}
                                                                              item={el}/>;
                                                    })}
                                                    arrowLeft={ArrowLeft}
                                                    arrowRight={ArrowRight}
                                                /> : ''}
                                        </div>
                                    </div>

                                </Fragment> : ''
                                }


                                <div className={'row'}>
                                    <div className="p-3 col-md-6">
                                        <span onClick={() => {
                                            setSpedizione({contatto_ref: spedizione.contatto_ref});
                                            setSpedizioneText(JSON.stringify({contatto_ref: spedizione.contatto_ref}));
                                            setIndirizzoSelezionato(null)
                                            setCorriereSelezionato(null)
                                        }} className="btn btn-danger d-block text-white ">Reset</span>
                                    </div>
                                    <div className="p-3 col-md-6">
                                        <span onClick={() => {
                                            creaSpedizione(spedizione);
                                            setSpedizioneAggiunta(true);
                                            setSpedizione({contatto_ref: spedizione.contatto_ref});
                                            setSpedizioneText(JSON.stringify({contatto_ref: spedizione.contatto_ref}));
                                            setIndirizzoSelezionato(null)
                                            setCorriereSelezionato(null)
                                        }} className="btn btn-success d-block text-white ">Salva</span>
                                    </div>
                                </div>


                            </Fragment> : ''
                            }
                        </CardBody>
                    </Card>
                }

                {(spedizione.contatto_pack_ref !== undefined && spedizione.spedizione_tipologia_ref === undefined) ?
                    <Card>
                        <CardBody>
                            <div className="d-md-flex align-items-center">
                                <div>
                                    <CardTitle>Scegli tipologia spedizione</CardTitle>
                                    {/*<CardSubtitle>Post</CardSubtitle>*/}
                                </div>
                                <div className="ml-auto d-flex no-block align-items-center">
                                    <div className="dl">
                                    </div>
                                </div>
                            </div>

                            <ScrollMenu
                                data={tipoSpedizioniPerListino.map(el => {
                                    const {id, nome} = el;
                                    return <MenuItem text={nome} key={id} selected={false}/>;
                                })}
                                arrowLeft={ArrowLeft}
                                arrowRight={ArrowRight}
                                // selected={(spedScelta) ? spedScelta.id : 0}
                                onSelect={onSelectSpedizione}
                            />

                        </CardBody>
                    </Card>
                    : ''
                }


                <Modal isOpen={modalOpen} toggle={toggleModal} className={""} size="lg" fade={true}>
                    <ModalHeader toggle={toggleModal}>Aggiungi Pack</ModalHeader>
                    <AddPackToContact/>
                </Modal>

                <Modal isOpen={modalIndirizzoOpen} toggle={toggleIndirizzoModal} className={""} size="lg" fade={true}>
                    <ModalHeader toggle={toggleIndirizzoModal}>Aggiungi Indirizzo</ModalHeader>
                    <AddIndirizzoToContact contatto={contatto}/>
                </Modal>


            </div>
        ) : (
            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                <i className="far fa-address-card display-5"></i>
                <h4 className="mt-2">Clicca su un contatto per visualizzarne i dettagli.</h4>
            </div>
        )}
    </Fragment>
        ;
}


ContactsDetails.prototype = {
    selectedContacts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            image: PropTypes.string,
            codfisc: PropTypes.string,
            citta: PropTypes.string,
            phone: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            address: PropTypes.string,
            notes: PropTypes.string,
            frequentlycontacted: PropTypes.bool.isRequired,
            starred: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired
};
export default ContactsDetails;




