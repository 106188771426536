import { CONTACT_DETAILS } from '../../../redux/constants';
const ContactDetails = (state = '', action) => {
	switch (action.type) {
		case CONTACT_DETAILS:
			return (action.contatto)?action.contatto:null;
		default:
			return state;
	}
};

export default ContactDetails;
