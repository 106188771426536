import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import Api from "../../../rest";
import {
    LISTINI_AGGIUNGIA_ALLA_LISTA,
    LISTINI_PACK_JSON,
    LISTINI_RESET_LISTA,
    SAGA_LISTINI_PACK_ADD,
    SAGA_LISTINI_PACK_JSON,
    SAGA_LISTINI_PACK_SEARCH,
    SAGA_LISTINI_PACK_TIPOLOGIA_ADD,
    SAGA_LISTINI_PACK_TIPOLOGIA_LIST,
    SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA,
    SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE
} from "../redux/listini-redux-types";
import {LISTINI_EXTRA_JSON} from "../../listini_extra/redux/listini-extra-redux-types";
import {
    ADD_TIPO_SPEDIZIONE_A_LISTINO,
    RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO,
    TIPO_SPEDIZIONI_PER_LISTINI_RELOADED,
    UPDATE_TIPO_SPEDIZIONE_A_LISTINO
} from "../redux/_constant";
import {SESSION_FETCHING, SESSION_FETCHING_ERROR} from "../../../redux/constants";


export function* fetchListiniPack(action) {
    let search = action.payload.search;
    let filtro = action.payload.filtro;
    let lazy = action.payload.lazy;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_pack.search, search, filtro, lazy)

        if (lazy === 1) yield put({type: LISTINI_RESET_LISTA, listini: result.rows});
        else yield put({type: LISTINI_AGGIUNGIA_ALLA_LISTA, listini: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* createListiniPack(action) {
    let listino = action.payload.listino_pack;

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_pack.create, listino)

        console.log("listino aggiunto:", result)


        yield put({type: LISTINI_AGGIUNGIA_ALLA_LISTA, listini: [result]});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* getListiniPackJson(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_pack.json)
        yield put({type: LISTINI_PACK_JSON, listini: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* createTipologiaListiniPack(action) {
    let tipologia_pack = action.payload.tipologia_pack;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const tipologia_creata = yield call(Api.listini_pack.createTipologia, tipologia_pack)

        console.log("tipologia_creata", tipologia_creata)
        yield put({type: ADD_TIPO_SPEDIZIONE_A_LISTINO, tipologia: tipologia_creata});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        console.log(error)
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* updateTipologiaListiniPack(action) {
    let tipologia_pack = action.payload.tipologia_pack;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const tipologia_aggiornata = yield call(Api.listini_pack.updateTipologia, tipologia_pack)

        console.log("tipologia_aggiornata", tipologia_aggiornata)
        //TODO AGGIORNARE LISTA REDUX DELLE SPEDIZIONI

        yield put({type: UPDATE_TIPO_SPEDIZIONE_A_LISTINO, tipologia: tipologia_pack});

        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        console.log(error)
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* getTipologiaListiniPack(action) {
    let listinoPackRef = action.payload.listino_pack_ref;

    yield put({type: SESSION_FETCHING, fetching: true});
    try {
        const result = yield call(Api.listini_pack.getTipologie, listinoPackRef)
        // console.log(result)

        yield put({type: TIPO_SPEDIZIONI_PER_LISTINI_RELOADED, tipologie_pack: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* riordinaTipologiePack(action) {
    let tipologie_pack = action.payload.tipologie_pack;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.listini_pack.riordinaTipologiePack, tipologie_pack)
        //TODOGP inserire controllo sulla risposta al rest del riordino tipoligie

        yield put({type: SESSION_FETCHING, fetching: false});

        // yield put({type: RIORDINO_TIPO_SPEDIZIONI_DEL_LISTINO, tipologie_pack: tipologie_pack});
        // else yield put({type: LISTINI_AGGIUNGIA_ALLA_LISTA, listini: result.rows});


    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* listiniPackSaga() {
    yield all([
        takeEvery(SAGA_LISTINI_PACK_SEARCH, fetchListiniPack),
        takeEvery(SAGA_LISTINI_PACK_JSON, getListiniPackJson),
        takeLatest(SAGA_LISTINI_PACK_ADD, createListiniPack),
        takeLatest(SAGA_LISTINI_PACK_TIPOLOGIA_ADD, createTipologiaListiniPack),
        takeLatest(SAGA_LISTINI_PACK_TIPOLOGIA_UPDATE, updateTipologiaListiniPack),
        takeEvery(SAGA_LISTINI_PACK_TIPOLOGIA_LIST, getTipologiaListiniPack),
        takeLatest(SAGA_LISTINI_PACK_TIPOLOGIA_RIORDINA, riordinaTipologiePack)
    ])
}
