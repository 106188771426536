import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Button, Table, UncontrolledTooltip} from 'reactstrap';
import SpedizioneListItem from "./SpedizioneListItem";
import {connect} from "react-redux";
import PaginationCustom from "../../../commons/Pagination";
import {setFiltroLoadPage} from "../../redux/action";
import {SPEDIZIONI_FILTRO_DATA_ENUM} from "../../redux/spedizioni_redux_types";
import moment from "moment";
import getAxios from "../../../../rest/myaxios";
import {REST_SPEDIZIONI_DOWNLOAD_ELENCO_PDF, REST_SPEDIZIONI_DOWNLOAD_ELENCO_XLS, REST_SPEDIZIONI_SEARCH} from "../../../../rest/rest-constants";
const axios = getAxios()


const SpedizioniList = ({onSpedizioneClick, active, spedizioni, filtro, filtroLoadPage, toggleStarredContact, deleteContact, contactDetails, showRightPart, showDettagliContatto, showCreaSpedizione, ...state}) => {
    const [totaleContrassegno, setTotaleContrassegno] = useState(0);
    const a = (value) => {
        filtroLoadPage(value)
        console.log("a", value)
    }

    const [downloadPdfDisabled, setDownloadPdfDisabled] = useState(true);
    const [downloadExcelDisabled, setDownloadExcelDisabled] = useState(true);

    useEffect(() => {

        if (filtro.contrassegno) {
            let tot = 0;
            spedizioni.map(_sp => {
                tot += parseFloat(_sp.contrassegno_importo);
            })
            setTotaleContrassegno(tot);


        }


    }, [JSON.stringify(spedizioni)])


    useEffect(() => {
        // console.log("controllo se posso scaricare");

        let disattivo = false;

        if (filtro.data_filtro === SPEDIZIONI_FILTRO_DATA_ENUM.ALL) disattivo = true;

        if (filtro.data_from === 0) disattivo = true;
        else {
            let data_da = moment(filtro.data_from);
            let data_to = (filtro.data_to !== 0) ? moment(filtro.data_to) : moment().endOf('day');
            const delta = data_to.diff(data_da, 'days') + 1;
            if (delta > 62) disattivo = true;
        }

        setDownloadPdfDisabled(disattivo);
        setDownloadExcelDisabled(disattivo);
    }, [JSON.stringify(filtro)])



    const download = (url,filename,filetype)=>{
        axios( url, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            params:{
                filtro
            }
        })
            .then(response => {

                var linek = document.createElement("a");
                linek.style = "display: none";
                document.body.appendChild(linek);
                const file = new Blob([response.data], {type: 'application/pdf'});
                const fileURL = URL.createObjectURL(file);
                linek.href = fileURL;
                linek.target = '_blank';
                linek.download = filename;
                linek.click();
                window.URL.revokeObjectURL(fileURL);

            })
            .catch(error => {
                alert("Impossibile generare il documento.")
                console.log(error);
            });
    }


    return (
        <div className="table-responsive spedizioni-list">

            {
                filtro.pagination_count > 0 ? <div className={"d-flex"}>
                    <PaginationCustom
                        handleClick={a}
                        currentPage={filtro.pagination_page}
                        totali={filtro.pagination_count}
                        limite={filtro.pagination_limit}
                    />
                    <div className={"d-flex"}>
                        <Button
                            id={"btn-download-pdf"}
                            style={{width: 30, height: 30, marginLeft: 25, marginTop: 20, marginBottom: 15}}
                            size={"sm"} onClick={() => {
                            download(REST_SPEDIZIONI_DOWNLOAD_ELENCO_PDF,'report-spedizioni.pdf','appliation/pdf');
                        }}
                            className="btn" disabled={downloadPdfDisabled} color="primary">
                            <i className={"far fa-file-pdf"}/>
                            <UncontrolledTooltip placement="bottom" target={"btn-download-pdf"}>Download Report Pdf</UncontrolledTooltip>
                        </Button>
                        <Button
                            id={"btn-download-xls"}
                            style={{width: 30, height: 30, marginLeft: 10, marginTop: 20, marginBottom: 15}}
                            size={"sm"} onClick={() => {
                            download(REST_SPEDIZIONI_DOWNLOAD_ELENCO_XLS,'bmcagliari-report-spedizioni.csv','text/csv');

                        }}
                            className="btn" disabled={downloadExcelDisabled} color="primary">
                            <i className={"far fa-file-excel"}/>
                            <UncontrolledTooltip placement="bottom" target={"btn-download-xls"}>Esporta Excel</UncontrolledTooltip>
                        </Button>
                    </div>

                </div> : "nessuna spedizione"
            }


            {(filtro.contrassegno && filtro.pagination_count > 0) ? <h3>Tot. contrassegno: {totaleContrassegno}€</h3> : ''}
            <Table className="table spedizioni-table no-wrap table-hover v-middle mb-0">
                <tbody>
                {spedizioni.map(spedizione => {
                        return <SpedizioneListItem
                            key={spedizione.id}
                            active={active}
                            spedizione={spedizione}
                            onRowClick={() => {
                                onSpedizioneClick(spedizione)
                            }}
                            onContrassegnoClick={() => {
                                console.log("seleziono spedizione", spedizione.id);
                            }}
                        />
                    }
                )}
                </tbody>
            </Table>
            <hr/>
            {
                filtro.pagination_count > 0 ? <div>
                    <PaginationCustom
                        handleClick={a}
                        currentPage={filtro.pagination_page}
                        totali={filtro.pagination_count}
                        limite={filtro.pagination_limit}
                    />
                </div> : ''
            }

        </div>
    );

}

SpedizioniList.prototype = {
    spedizioni: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
        }).isRequired
    ).isRequired,
    onSpedizioneClick: PropTypes.func.isRequired,
    // deleteContact: PropTypes.func.isRequired,
    // contactDetails: PropTypes.func.isRequired
};


const mapStateToProps = (state, ownProps) => ({
    spedizioni: state.spedizioni.spedizioni,
    filtro: state.spedizioni.filtro,
});

const mapDispatchToProps = {
    filtroLoadPage: setFiltroLoadPage
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SpedizioniList);
