import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import user1 from '../../../../assets/images/users/1.jpg';
import API from "../../../../rest";

const Contact = ({
                     onSelectContatto,
                     onCreaSpedizioneClick,
                     onStarredClick,
                     onDeleteClick,
    contatto,
                     id,
                     nome,
                     cognome,
                     rag_sociale,
                     image,
                     citta,
                     starred,
                     active,
                     ...state
                 }) => {

    const [immagineProfilo, setImmagineProfilo] = useState(user1);

    useEffect(() => {
        if (contatto && contatto.image != null) {
            API.contatti.getImageProfilo(contatto.id, contatto.image).then((r) => {
                if(r)setImmagineProfilo(r)
            });
        }
    }, [image]); // passing an empty array as

    return (
        <tr className={((active === contatto.id) ? "bg-light" : "")}>
            <td onClick={()=>{ onSelectContatto(contatto)}} style={{width: 70 + 'px'}}>
                <img src={immagineProfilo} className="rounded-circle" width="50" height="50" alt={""}/>
            </td>
            <td  onClick={()=>{ onSelectContatto(contatto)}}>
                <p className="mb-0 text-truncate">
                    {contatto.cognome} {contatto.nome} {contatto.rag_sociale}
                </p>
                <p className="mb-0 text-muted">{contatto.citta}</p>
            </td>
            <td className="text-center" style={{width: 70 + 'px'}}>
                <i
                    onClick={onStarredClick}
                    className="fas fa-star"
                    style={{color: contatto.starred ? "#FFC107" : "#495057"}}
                ></i>
                <i onClick={()=>{ onCreaSpedizioneClick(contatto)}} className="fas fa-box-open ml-3"></i>
            </td>
        </tr>
    );
}


Contact.propTypes = {
    // nome: PropTypes.string.isRequired,
    // cognome: PropTypes.string.isRequired,
    // image: PropTypes.string.isRequired,
    contatto: PropTypes.object.isRequired,
    onStarredClick: PropTypes.func.isRequired,
    onSelectContatto: PropTypes.func.isRequired,
    onCreaSpedizioneClick: PropTypes.func.isRequired
};

export default Contact;
