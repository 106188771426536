import Dashboard from '../views/dashboard/dashboard';
import Contacts from "../moduli/contatti/views/contacts";
import Profile from "../views/profile/profile_func";
import Listini from "../moduli/listini_pack/views/listini_pack";
import Listextra from "../moduli/listini_extra/views/listextra";
import SpedizioniHome from "../moduli/spedizioni/views/SpedizioniHome/SpedizioniHome";
import ContattoDettaglio from "../moduli/contatti/views/ContattoDettaglio/ContattoDettaglio";
import Login from "../views/authentication/login";
import Fulllayout from "../layouts/fulllayout";
import ContattoSpedizioni from "../moduli/contatti/views/ContattoDettaglio/ContattoSpedizioni";
import FattureHome from "../moduli/fatture/FattureHome/FattureHome";
import SettingsHome from "../moduli/settings/SettingsHome/SettingsHome";

var AllRoutes = [
    {navlabel: true, name: "Personal", icon: "mdi mdi-dots-horizontal",},
    {path: '/authentication/login', name: 'Dashboard', icon: 'ti-dashboard', layout: null, component: Login, protected: false},
    {path: '/cliente/:contatto_id/:nome/spedizioni', name: 'Spedizioni contatto', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: ContattoSpedizioni, protected: true},
    {path: '/cliente/:contatto_id/:nome/fatture', name: 'Spedizioni contatto', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: ContattoDettaglio, protected: true},
    {path: '/cliente/:contatto_id/:nome/pacchetti', name: 'Spedizioni contatto', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: ContattoDettaglio, protected: true},
    {path: '/cliente/:contatto_id/:nome', name: 'Cliente', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: ContattoDettaglio, protected: true},
    {path: '/dashboard', name: 'Dashboard', icon: 'ti-dashboard', layout: 'Fulllayout', component: Dashboard, protected: true},
    {path: '/clienti', name: 'Clienti', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: Contacts, protected: true},
    {path: '/spedizioni/dashboard', name: 'Spedizioni', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: SpedizioniHome, protected: true},
    {path: '/fatture/dashboard', name: 'Fatture', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: FattureHome, protected: true},
    {path: '/impostazioni/listini-extra', name: 'Listini Extra', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: Listextra, protected: true},
    {path: '/impostazioni/listini-pack', name: 'Listini Pack', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: Listini, protected: true},
    {path: '/impostazioni/corrieri', name: 'Corrieri', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: Dashboard, protected: true},
    {path: '/impostazioni/settings', name: 'Settings', icon: 'mdi mdi-contacts', layout: 'Fulllayout', component: SettingsHome, protected: true},


    {path: '/', pathTo: '/dashboard', name: 'Dashboard', redirect: true}
];
export default AllRoutes;
