import React from "react";
import FilterSearch from "../containers/listiniFilterSearch";

class ListiniSearch extends React.Component {
	render() {
		return (
			<div className="bg-light p-4">
				<FilterSearch />
			</div>
		);
	}
}

export default ListiniSearch;
