import React, {Component} from 'react';
import StepZilla from 'react-stepzilla';
import {
    Card,
    CardBody,
    CardTitle
} from 'reactstrap';

import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import {listiniExtraGetJson} from "../../../listini_extra/redux/actions";
import {listiniPackGetJson} from "../../../listini_pack/redux/actions";
import {connect} from "react-redux";
import {creaContatto} from "../../redux/action";

const stateOptions = [
    {value: 0, label: 'Privato'},
    {value: 1, label: 'Azienda'},
];


class formSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.sampleStore = {
            id: null,
            azienda: stateOptions[0],
            nome: '',
            cognome: '',
            codfisc: '',
            rag_sociale: '',
            piva: '',
            citta: '',
            indirizzo: '',
            email: '',
            phone: '',
            image: '',
            listino_pack: null,
            // listino_pack: {value:1,label:' listino pack scelto'},
            listino_extra: null,
            // listino_extra: {value:2,label:' listino extra scelto'},
            savedToCloud: false
        };
    }

    componentDidMount() {
        this.props.listiniExtraGetJson()
        this.props.listiniPackGetJson()
    }


    getStore() {
        return this.sampleStore;
    }

    updateStore(update) {
        this.sampleStore = {
            ...this.sampleStore,
            ...update,
        }
    }

    render() {
        const steps =
            [
                {
                    name: 'Anagrafica', component: <Step1 getStore={() => (this.getStore())} updateStore={(u) => {
                        this.updateStore(u)
                    }}/>
                },
                {
                    name: 'Scegli Listini', component: <Step2
                        creaContatto={this.props.creaContatto}
                        listini_pack={this.props.listini_pack}
                        listini_extra={this.props.listini_extra}
                        getStore={() => (this.getStore())} updateStore={(u) => {
                        this.updateStore(u)
                    }}/>
                },
                {
                    name: 'Fatto!', component: <Step4 onChiudi={this.props.onChiudi} getStore={() => (this.getStore())} updateStore={(u) => {
                        this.updateStore(u)
                    }}/>
                }
            ]

        return (
            <Card>
                <CardBody>
                    <div className='example'>
                        <div className='step-progress'>
                            <StepZilla
                                steps={steps}
                                preventEnterSubmission={true}
                                nextTextOnFinalActionStep={"Save"}
                            />
                        </div>
                    </div>
                </CardBody>
            </Card>

        )
    }
}


const mapStateToProps = state => ({
    listini_pack: state.listini.json,
    listini_extra: state.listextra.json
});

const mapDispatchToProps = {
    listiniExtraGetJson,
    listiniPackGetJson,
    creaContatto,
}


export default connect(mapStateToProps, mapDispatchToProps)(formSteps);
