import {
    FATTURE_FILTRO_CONTATTO,
    FATTURE_FILTRO_DATA,
    FATTURE_FILTRO_LOAD_PAGE, FATTURE_FILTRO_PAGAMENTO,
    FATTURE_FILTRO_RESET,
    FATTURE_FILTRO_STATO, FATTURE_FILTRO_TIPO,
    SAGA_FATTURE_CREATE, SAGA_FATTURE_DELETE, SAGA_FATTURE_DETTAGLI,
    SAGA_FATTURE_SEARCH, SAGA_FATTURE_UPDATE
} from "./fatture_redux_types";


export const searchFatture = () => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();
        const filtro = state.fatture.filtro;
        console.log("filtro fatture", filtro);

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_FATTURE_SEARCH,
            payload: {
                filtro
            }
        })

    }
}


export const creaFattura = (fattura) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_FATTURE_CREATE,
            payload: {
                fattura,
            }
        });

    }


}


export const resetFiltroFatture = () => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_RESET,
        });
    }
}

export const setFiltroFattureData = (filtro, from, to) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_DATA,
            payload: {
                filtro,
                from,
                to
            }
        });
    }
}

export const setFiltroFattureStato = (stato) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_STATO,
            payload: {
                stato,
            }
        });
    }
}

export const setFiltroFattureTipo = (tipo) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_TIPO,
            payload: {
                tipo,
            }
        });
    }
}

export const setFiltroFatturePagamento = (pagamento) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_PAGAMENTO,
            payload: {
                pagamento,
            }
        });
    }
}

export const setFiltroFattureLoadPage = (page) => {

    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: FATTURE_FILTRO_LOAD_PAGE,
            payload: {
                page,
            }
        });
    }
}

export const setFiltroContattoPerFatture = (contatto_id)=>{
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: FATTURE_FILTRO_CONTATTO,
            payload: {
                contatto:contatto_id,
            }
        });
    }
}



export const aggiornaFattura = (fattura) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_FATTURE_UPDATE,
            payload: {
                fattura
            }
        });
    }
}
export const eliminaFattura = (fattura_id) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_FATTURE_DELETE,
            payload: {
                fattura_id
            }
        });
    }
}


export const dettaglioFattura = (fattura_id) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_FATTURE_DETTAGLI,
            payload: {
                fattura:fattura_id
            }
        });
    }
}




