import React, {Fragment, useEffect, useState} from "react";
import {Table, Button, Input, Col, Modal, ModalHeader} from "reactstrap";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import user1 from "../../../../assets/images/users/1.jpg";
import {getFirebase} from "react-redux-firebase";
import {useSelector} from "react-redux";
import AddTipoSpedizione from "../containers/addTipoSpedizione";
import {getTipoSpedizioniPerListino, riordinaTipoSpedizioni} from "../../redux/actions";
import 'react-virtualized/styles.css';
import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {Table as TableVirtuaized, Column, defaultTableRowRenderer, AutoSizer} from 'react-virtualized';
import arrayMove from 'array-move';
import API from "../../../../rest";


const ROW_HEIGHT = 30;
const HEADER_ROW_HEIGHT = 20;
const COL_WIDTH = 100;


const SortableTable = SortableContainer(TableVirtuaized)
// const SortableTableRowRenderer = SortableElement(defaultTableRowRenderer)
// const SortableTableRowRenderer = SortableElement((_ref) => defaultTableRowRenderer(_ref));


const ListinoDetails = ({selectedListino, tipoSpedizioni, getTipoSpedizioniPerListino, onEditClick, editListinoFlag, onChange, riordinaTipoSpedizioni, ...props}) => {

    let nome, descrizione;

    const [modalOpen, setModalOpen] = useState(false);
    const [modalModificaOpen, setModalModificaOpen] = useState(false);
    const [immagineProfilo, setImmagineProfilo] = useState(user1);
    const [spedizioneSelezionata, setSpedizioneSelezionata]=useState(null);


    const SortableTableRowRenderer = SortableElement((value) => {
        console.log(value)
        return <li>{value.rowData.nome} |{value.rowData.prezzo}€|  <Button
            onClick={() => {
                setSpedizioneSelezionata(value.rowData)
                setModalModificaOpen(!modalModificaOpen)
            }}
            className="btn" color="link" size="sm" id={"btn-mod-extra-ok"}>
            <i className={"fas fa-edit"}></i>
        </Button> </li>
    });


    useEffect(() => {// if (!fetchedImgs) {
        setImmagineProfilo(user1)
        if (selectedListino && selectedListino.image != null) {
            API.listini_pack.getImage(selectedListino.id, selectedListino.image + "").then(a => {
                if (a) setImmagineProfilo(a)
                else setImmagineProfilo(user1)
            });

        } else {
        }
    }, [selectedListino]); // passing an empty array as


    useEffect(() => {
        if (selectedListino) getTipoSpedizioniPerListino(selectedListino.id)
    }, [selectedListino])


    function f(id) {
        console.log("modifico id: ", id)
        let _values = {
            nome: nome.value
        }
        props.aggiornaListino(id, _values);
    }


    const cols = [
        {dataKey: 'ordinamento', label: '#'},
        {dataKey: 'nome', label: 'Tipo'},
        {dataKey: 'prezzo', label: 'Prezzo'},
        {dataKey: 's', label: ''},
    ]


    return <Fragment>
        {selectedListino ? (
            <div className="">
                <div className="d-flex align-items-center p-4 border-bottom">
                    <div className="mr-3">
                        <img src={immagineProfilo} alt="user" className="rounded-circle" width="50" height="50"/>
                    </div>
                    <div>
                        <h5 className="message-title mb-0">{selectedListino.nome}</h5>
                        <p className="mb-0">{selectedListino.descrizione}</p>
                        {editListinoFlag ?
                            <Button className="btn btn-success mt-2" size="sm" onClick={() => {
                                f(selectedListino.id);
                            }}>Salva</Button>
                            :
                            <Button className="btn btn-info mt-2" size="sm" onClick={onEditClick}>Modifica</Button>
                        }
                    </div>
                </div>
                <div className="details-table px-4">
                    <Table responsive borderless size="sm" className="mt-4">
                        <tbody>
                        <tr className="d-flex">
                            <td className="col-3 font-bold">First Name</td>
                            <td className="col-9">
                                {editListinoFlag ? (
                                    <Input
                                        type="text"
                                        name="firstName"
                                        id="firstName"
                                        ref={node => (nome = node)}
                                        defaultValue={selectedListino.nome}
                                        onChange={e => onChange(selectedListino.id, "nome", e.target.value)}
                                    />
                                ) : (
                                    ": " + selectedListino.nome
                                )}
                            </td>
                        </tr>
                        <tr className="d-flex">
                            <td className="col-3 font-bold">Descrizione</td>
                            <td className="col-9">
                                {editListinoFlag ? (
                                    <Input
                                        type="text"
                                        name="descrizione"
                                        id="descrizione"
                                        ref={node => (descrizione = node)}
                                        defaultValue={selectedListino.descrizione}
                                        onChange={e => onChange(selectedListino.id, "descrizione", e.target.value)}
                                    />
                                ) : (
                                    ": " + selectedListino.descrizione
                                )}
                            </td>
                        </tr>
                        </tbody>
                    </Table>

                    <div className="p-3">

                        <span onClick={()=>{
                            setSpedizioneSelezionata(undefined)
                            setModalOpen(!modalOpen)
                            // toggleModalAddSpedizione()
                        }} className="btn btn-danger d-block text-white">Nuovo tipo spedizione</span>
                    </div>

                    Tipo spedizioni
                    <AutoSizer>
                        {({width, height}) => (
                            <SortableTable
                                lockAxis="y"
                                onSortEnd={({oldIndex, newIndex}) => {

                                    if (oldIndex != newIndex) {
                                        let a = arrayMove(tipoSpedizioni, oldIndex, newIndex);
                                        console.log("on drag end", a)
                                        riordinaTipoSpedizioni(a)
                                    }
                                }}
                                width={width}
                                height={height}
                                headerHeight={ROW_HEIGHT}
                                rowHeight={ROW_HEIGHT}
                                rowCount={tipoSpedizioni.length}
                                rowGetter={({index}) => tipoSpedizioni[index]}
                                rowRenderer={params => (<SortableTableRowRenderer {...params} />)}
                            >
                                {cols.map(col => (
                                    <Column
                                        {...col}
                                        key={col.dataKey}
                                        width={width / cols.length}
                                    />
                                ))}
                            </SortableTable>
                        )}
                    </AutoSizer>

                </div>
            </div>
        ) : (
            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                <i className="fas fa-money-check-alt display-5"></i>
                <h4 className="mt-2">Clicca su un listino per visualizzarne i dettagli.</h4>


            </div>


        )}
        <Modal isOpen={modalOpen} toggle={()=>{ setModalOpen(!modalOpen)}} className={props.className} size="md">
            <ModalHeader toggle={()=>{ setModalOpen(!modalOpen)}}>Aggiungi Tipo Spedizione</ModalHeader>
            <AddTipoSpedizione spedizione={spedizioneSelezionata} onSalvataggioComplete={()=>{ setModalOpen(!modalOpen)}}/>
        </Modal>
        <Modal isOpen={modalModificaOpen} toggle={()=>{setModalModificaOpen(!modalModificaOpen)}} className={props.className} size="md">
            <ModalHeader toggle={()=>{setModalModificaOpen(!modalModificaOpen)}}>Modifica Tipo Spedizione</ModalHeader>
            <AddTipoSpedizione spedizione={spedizioneSelezionata} onSalvataggioComplete={()=>{setModalModificaOpen(!modalModificaOpen)}}/>
        </Modal>
    </Fragment>
        ;
}
ListinoDetails.prototype = {
    selectedContacts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            nome: PropTypes.string.isRequired,
            costo: PropTypes.string,
            costo_spedizione: PropTypes.string,
            image: PropTypes.string,
            frequentlycontacted: PropTypes.bool.isRequired,
            starred: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired
};
export default ListinoDetails;
