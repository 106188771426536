import { combineReducers } from "redux";
import {listini, lazy, json} from "./listini"
import listinoDetails from "./listinoDetails";
import editListino from "./editListino";
import listiniSearch from "./listiniSearch";
import listiniVisibilityFilter from "./listiniVisibilityFilter";
import tipoSpedizioniPerListino from "./tipoSpedizioniPerListino";

export default combineReducers({
	listini,
	lazy,
	json,
	listinoDetails,
	listiniVisibilityFilter,
	listiniSearch,
	editListino,
	tipoSpedizioniPerListino
});
