import React, {useState} from "react";
import {Col, Row, Form, FormGroup, Label, Button, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import user1 from '../../../../assets/images/default_thumbs/listino_extra_default.jpg';
import {addListExtra} from "../../redux/actions";

const mapStateToProps = state => ({
    ...state,
    id: state.listini.listini.length
});

const mapDispatchToProps = {
    addListExtra
}


const AddListExtraContainer = ({onListinoAggiunto, ...props}) => {
    let nome, descrizione, listino_ref, fileUploader;
	const [image, setImage] = useState("");
    const [sourceImg, setSourceImg] = useState(user1);

    const handleChange = e => {
        console.log(e.target.files)
        if (e.target.files[0]) {
            setImage(e.target.files[0]);


            let file = e.target.files[0];

            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSourceImg(reader.result)
            }.bind(this);

        }


    };





    return (
        <React.Fragment>
            <Form
                onSubmit={e => {
                    e.preventDefault();

                    if (!nome.value.trim() ) {
                        return;
                    }


                    // props.addContact(firstname.value, props)


                    let listino = {
                        nome: nome.value.toLowerCase().trim(),
                        listino_ref: (!listino_ref.value === "") ? listino_ref.value : null,
                        descrizione: descrizione.value,
                        image
                    }
                    // console.log("listino", listino)

                    props.addListExtra(listino)
                    onListinoAggiunto()
                    nome.value = "";

                }}
            >
                <ModalBody>
                    <Row>
                        <Col md={6} className="text-center">
                            <img
                                onClick={(e) => {
                                    fileUploader.click()
                                }}
                                src={sourceImg}
                                className="rounded-circle"
                                // alt={props.id}
                                width="100"
                            />
                            <input
                                accept="image/*"
                                ref={comp => fileUploader = comp}
                                id="contained-button-file"
                                multiple
                                type="file"
								onChange={handleChange}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <p className="text-center mt-0 mb-3 " onClick={(e) => {fileUploader.click()}}>
                                <small className=" badge badge-default badge-light text-dark">clicca per carica un'immagine</small>
                            </p>

                        </Col>
                        <Col md={6}>

                            <FormGroup className="mb-3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        ref={node => (nome = node)}
                                        placeholder="Nome listino"
                                        required
                                    /> </InputGroup>


                                <small className="mt-1 mb-4 badge badge-default badge-dark text-light float-left">Nome</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>


                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="far fa-copy"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="listino_ref"
                                        id="listino_ref"
                                        ref={node => (listino_ref = node)}
                                        placeholder="Listino di riferimento"
                                    />
                                </InputGroup>
                                <small className="mt-1 mb-4 badge badge-default badge-dark text-light float-left">Copia i dati da questo listino</small>
                            </FormGroup>

                        </Col>
                    </Row>

                    <Row>
                        <Col md={12}>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-align-left"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="descrizione"
                                        id="descrizione"
                                        placeholder="Descrizione"
                                        ref={node => (descrizione = node)}
                                    />
                                </InputGroup>

                                <small className="mt-1 mb-4 badge badge-default badge-dark text-light float-left">Descrizione</small>
                            </FormGroup>


                        </Col>
                    </Row>



                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        Aggiungi Listino
                    </Button>
                </ModalFooter>
            </Form>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddListExtraContainer);
