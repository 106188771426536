import {EDIT_LIST_EXTRA, LIST_EXTRA_DETAILS} from "./_constant";

const EditListExtra = (state = false, action) => {
	switch (action.type) {
		case EDIT_LIST_EXTRA:
			return !state ? true : false;
		case LIST_EXTRA_DETAILS:
			return state = false;
		default:
			return state;
	}
};

export default EditListExtra;
