import PropTypes from "prop-types";
import React from "react";


const FilterLinkContatti = ({active, onClick, children, filtro, stato}) => {
    let attivo = filtro === stato

    return (
        <li className="list-group-item" onClick={() => {
            onClick(filtro)
        }}>
		<span className={"list-group-item-action " + (attivo ? "active" : "")}>
			{children}
		</span>
        </li>
    );
}

FilterLinkContatti.propTypes = {
    filtro: PropTypes.string.isRequired,
    stato: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
};



export default FilterLinkContatti;
