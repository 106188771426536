import React, {useEffect, useState} from "react";
import {CustomInput, Input, ListGroup, ListGroupItem, Modal, ModalHeader} from "reactstrap";
import {connect} from "react-redux";
import {
    searchFatture,
    setFiltroContattoPerFatture,
    setFiltroFattureData,
    setFiltroFatturePagamento,
    setFiltroFattureStato,
    setFiltroFattureTipo
} from "../redux/action";
import FilterLinkFatture from "./FilterLinkFatture";
import {FATTURE_FILTRO_DATA_ENUM, FATTURE_FILTRO_PAGAMENTO_ENUM, FATTURE_FILTRO_TIPO_ENUM} from "../redux/fatture_redux_types";
import NuovaFattura from "../NuovaFattura/NuovaFattura";
import ContattoAsyncSelect from "../../contatti/views/components/ContattoAsyncSelect";
import moment from "moment";
import NuovaFatturaBatch from "../NuovaFattura/NuovaFatturaBatch";


const FiltroFattureDashboard = ({
                                    searchFatture,
                                    setFiltroFattureTipo,
                                    setFiltroFattureData,
                                    setFiltroFatturePagamento,
                                    setFiltroContattoPerFatture,
                                    // setFiltroSearch, setFiltroTipo, setFiltroEliminato, setFiltroPreferito,
                                    filtro, ...props
                                }) => {
    const [modalNewFatturaOpened, setModalNewFatturaOpened] = useState(false);
    const [modalFattureBatch, setModalFattureBatch] = useState(false);
    const [dataFrom, setDataFrom] = useState(moment().startOf('month').format("YYYY-MM-DD"))
    const [dataTo, setDataTo] = useState(moment().format("YYYY-MM-DD"))

    const [pronto, setPronto] = useState(false);


    const filtrojson = JSON.stringify(filtro);

    useEffect(() => {
        if(pronto) setFiltroFattureData(FATTURE_FILTRO_DATA_ENUM.RANGE, dataFrom, dataTo);
    }, [dataFrom, dataTo])


    useEffect(() => {
        // console.log("ricerco spedizioni")

        searchFatture();
    }, [filtrojson])

    return (
        <div>
            <div className="p-3">
                <span onClick={() => {
                    setModalNewFatturaOpened(true)
                }}
                      className="btn btn-danger d-block text-white">Fatture</span>
            </div>


            <div className="divider"></div>

            <ListGroup className="contact-filters">
                <ContattoAsyncSelect
                    onChange={(contatto) => {
                        setFiltroContattoPerFatture(contatto);
                    }}
                />


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkFatture
                    stato={filtro.data_filtro}
                    filtro={FATTURE_FILTRO_DATA_ENUM.ALL}
                    onClick={() => {
                        setFiltroFattureData(FATTURE_FILTRO_DATA_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkFatture>
                <FilterLinkFatture
                    stato={filtro.data_filtro}
                    filtro={FATTURE_FILTRO_DATA_ENUM.RANGE}
                    onClick={() => {
                        setPronto(true);
                        setFiltroFattureData(FATTURE_FILTRO_DATA_ENUM.RANGE, dataFrom, dataTo)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Range
                </FilterLinkFatture>
                {(filtro.data_filtro === FATTURE_FILTRO_DATA_ENUM.RANGE) ? <div className={""}>
                    <Input defaultValue={moment(dataFrom).format("YYYY-MM-DD")} name={"date_from"} onChange={(e) => {
                        console.log("setDataFrom(e.target.value);", e.target.value)
                        setDataFrom(e.target.value);
                    }} type="date"/>
                    <Input defaultValue={moment(dataTo).format("YYYY-MM-DD")} name={"date_to"} onChange={(e) => {
                        setDataTo(e.target.value);
                    }} type="date"/>
                </div> : ""}


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkFatture
                    stato={filtro.tipo}
                    filtro={FATTURE_FILTRO_TIPO_ENUM.ALL}
                    onClick={() => {
                        setFiltroFattureTipo(FATTURE_FILTRO_TIPO_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkFatture>
                <FilterLinkFatture
                    stato={filtro.tipo}
                    filtro={FATTURE_FILTRO_TIPO_ENUM.FATTURA}
                    onClick={() => {
                        setFiltroFattureTipo(FATTURE_FILTRO_TIPO_ENUM.FATTURA)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Addebito
                </FilterLinkFatture>
                <FilterLinkFatture
                    stato={filtro.tipo}
                    filtro={FATTURE_FILTRO_TIPO_ENUM.NOTA_CREDITO}
                    onClick={() => {
                        setFiltroFattureTipo(FATTURE_FILTRO_TIPO_ENUM.NOTA_CREDITO)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Accredito
                </FilterLinkFatture>

                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkFatture
                    stato={filtro.pagamento}
                    filtro={FATTURE_FILTRO_PAGAMENTO_ENUM.ALL}
                    onClick={() => {
                        setFiltroFatturePagamento(FATTURE_FILTRO_PAGAMENTO_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkFatture>
                <FilterLinkFatture
                    stato={filtro.pagamento}
                    filtro={FATTURE_FILTRO_PAGAMENTO_ENUM.NON_PAGATE}
                    onClick={() => {
                        setFiltroFatturePagamento(FATTURE_FILTRO_PAGAMENTO_ENUM.NON_PAGATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Non pagate
                </FilterLinkFatture>
                <FilterLinkFatture
                    stato={filtro.pagamento}
                    filtro={FATTURE_FILTRO_PAGAMENTO_ENUM.PAGATE}
                    onClick={() => {
                        setFiltroFatturePagamento(FATTURE_FILTRO_PAGAMENTO_ENUM.PAGATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Pagate
                </FilterLinkFatture>



                <div className="p-3">
                <span onClick={() => {
                    setModalFattureBatch(true)
                }}
                      className="btn btn-danger d-block text-white">Genera Batch</span>
                </div>




            </ListGroup>


            <Modal isOpen={modalNewFatturaOpened} toggle={() => {
                setModalNewFatturaOpened(!modalNewFatturaOpened);
            }} size="lg">
                <ModalHeader toggle={() => {
                    setModalNewFatturaOpened(!modalNewFatturaOpened);
                }}>Crea Fattura</ModalHeader>
                <NuovaFattura/>
            </Modal>

            <Modal isOpen={modalFattureBatch} toggle={() => {
                setModalFattureBatch(!modalFattureBatch);
            }} size="lg">
                <ModalHeader toggle={() => {
                    setModalFattureBatch(!modalFattureBatch);
                }}>Crea FattureBatch</ModalHeader>
                <NuovaFatturaBatch/>
            </Modal>


        </div>
    );

}

const mapStateToProps = (state, ownProps) => ({
    filtro: state.fatture.filtro,
})
const mapDispatchToProps = {
    searchFatture,
    setFiltroContattoPerFatture,
    setFiltroFattureData,
    setFiltroFattureStato,
    setFiltroFattureTipo,
    setFiltroFatturePagamento
    // setFiltroEliminato,
    // setFiltroPreferito
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltroFattureDashboard)
