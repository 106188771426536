import React, {useEffect, useState} from "react";
import {Col, CustomInput, Input, InputGroup, ListGroup, ListGroupItem, Row} from "reactstrap";
import AsyncSelect from 'react-select/async';
import FilterLinkSpedizioni from "./FilterLinkSpedizioni";
import {connect} from "react-redux";
import {
    SPEDIZIONI_FILTRO_DATA_ENUM,
    SPEDIZIONI_FILTRO_STATO_ENUM,
    SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM
} from "../../../redux/spedizioni_redux_types";
import {
    searchSpedizioni,
    setFiltroContattoPerSpedizioni, setFiltroCorrierePerSpedizioni, setFiltroSpedizioniContrassegno,
    setFiltroSpedizioniData,
    setFiltroSpedizioniStato,
    setFiltroSpedizioniTipologia
} from "../../../redux/action";
import moment from "moment";
import {searchContatti, setFiltroSearch} from "../../../../contatti/redux/action";
import Select from "react-select";
import ContattoAsyncSelect from "../../../../contatti/views/components/ContattoAsyncSelect";


const FiltroSpedizioni = ({
                              searchSpedizioni, filtro,
                              setFiltroContattoPerSpedizioni, setFiltroSpedizioniData, setFiltroSpedizioniStato, setFiltroSpedizioniTipologia, setFiltroCorrierePerSpedizioni,
                              contatti, filtro_contatti, searchContatti, setFiltroSearchContatti, setFiltroSpedizioniContrassegno,
                              corrieri,
                              ...props
                          }) => {
    const [dataFrom, setDataFrom] = useState(moment().format("YYYY-MM-DD"))
    const [dataTo, setDataTo] = useState(moment().format("YYYY-MM-DD"))

    const [contattoSearch, setContattoSearch] = useState('');
    const [contattiSelect, setContattiSelect] = useState([]);

    const [selectCorriere, setSelectCorriere] = useState([]);
    const [corriereSelezionato, setCorriereSelezionato] = useState(null);


    useEffect(() => {
        setFiltroSpedizioniData(SPEDIZIONI_FILTRO_DATA_ENUM.RANGE, dataFrom, dataTo);
    }, [dataFrom, dataTo])

    useEffect(() => {
        searchSpedizioni();
    }, [JSON.stringify(filtro)])

    useEffect(() => {

        let options = [];
        corrieri.forEach((o, i) => {
            options.push({value: o.id, label: `${o.cognome} - ${o.nome} - ${o.email}`});
        });
        setSelectCorriere(options);


    }, [JSON.stringify(corrieri)])

    return (
        <div>
            <div className="p-3 hidden">
                <span onClick={() => {
                }} className="btn btn-danger d-block text-white">Spedizioni</span>
            </div>


            <div className="divider"></div>

            <ListGroup className="contact-filters">

                <ContattoAsyncSelect
                    onChange={(contatto) => {
                        setFiltroContattoPerSpedizioni(contatto);
                    }}
                />

                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkSpedizioni
                    stato={filtro.data_filtro}
                    filtro={SPEDIZIONI_FILTRO_DATA_ENUM.ODIERNE}
                    onClick={() => {
                        setFiltroSpedizioniData(SPEDIZIONI_FILTRO_DATA_ENUM.ODIERNE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Odierne
                </FilterLinkSpedizioni>
                <FilterLinkSpedizioni
                    stato={filtro.data_filtro}
                    filtro={SPEDIZIONI_FILTRO_DATA_ENUM.ALL}
                    onClick={() => {
                        setFiltroSpedizioniData(SPEDIZIONI_FILTRO_DATA_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkSpedizioni>
                <FilterLinkSpedizioni
                    stato={filtro.data_filtro}
                    filtro={SPEDIZIONI_FILTRO_DATA_ENUM.RANGE}
                    onClick={() => {
                        setFiltroSpedizioniData(SPEDIZIONI_FILTRO_DATA_ENUM.RANGE, dataFrom, dataTo)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Range
                </FilterLinkSpedizioni>
                {(filtro.data_filtro === SPEDIZIONI_FILTRO_DATA_ENUM.RANGE) ? <div className={""}>
                    <Input defaultValue={moment(dataFrom).format("YYYY-MM-DD")} name={"date_from"} onChange={(e) => {
                        setDataFrom(e.target.value);
                    }} type="date"/>
                    <Input defaultValue={moment(dataTo).format("YYYY-MM-DD")} name={"date_to"} onChange={(e) => {
                        setDataTo(e.target.value);
                    }} type="date"/>
                </div> : ""}


                <ListGroupItem>
                    <hr/>
                </ListGroupItem>


                <FilterLinkSpedizioni
                    stato={filtro.stato} filtro={SPEDIZIONI_FILTRO_STATO_ENUM.ALL}
                    onClick={() => {
                        setFiltroSpedizioniStato(SPEDIZIONI_FILTRO_STATO_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkSpedizioni>

                <FilterLinkSpedizioni
                    stato={filtro.stato} filtro={SPEDIZIONI_FILTRO_STATO_ENUM.APERTE}
                    onClick={() => {
                        setFiltroSpedizioniStato(SPEDIZIONI_FILTRO_STATO_ENUM.APERTE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Aperte
                </FilterLinkSpedizioni>

                <FilterLinkSpedizioni
                    stato={filtro.stato} filtro={SPEDIZIONI_FILTRO_STATO_ENUM.CONSEGNATE}
                    onClick={() => {
                        setFiltroSpedizioniStato(SPEDIZIONI_FILTRO_STATO_ENUM.CONSEGNATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Consegnate
                </FilterLinkSpedizioni>

                <FilterLinkSpedizioni
                    stato={filtro.stato} filtro={SPEDIZIONI_FILTRO_STATO_ENUM.ANNULLATE}
                    onClick={() => {
                        setFiltroSpedizioniStato(SPEDIZIONI_FILTRO_STATO_ENUM.ANNULLATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Annullate
                </FilterLinkSpedizioni>

                <ListGroupItem>
                    <hr/>
                </ListGroupItem>

                <FilterLinkSpedizioni
                    stato={filtro.tipologia} filtro={SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.ALL}
                    onClick={() => {
                        setFiltroSpedizioniTipologia(SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.ALL)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Tutte
                </FilterLinkSpedizioni>
                <FilterLinkSpedizioni
                    stato={filtro.tipologia} filtro={SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.PREPAGATE}
                    onClick={() => {
                        setFiltroSpedizioniTipologia(SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.PREPAGATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Prepagate
                </FilterLinkSpedizioni>

                <FilterLinkSpedizioni
                    stato={filtro.tipologia} filtro={SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.POSTPAGATE}
                    onClick={() => {
                        setFiltroSpedizioniTipologia(SPEDIZIONI_FILTRO_TIPOLOGIA_ENUM.POSTPAGATE)
                    }}
                >
                    <i className="fas fa-address-card  mr-2"></i> Postpagate
                </FilterLinkSpedizioni>

                <ListGroupItem>
                    <hr/>
                </ListGroupItem>



                <div className="bg-light p-1">
                    <CustomInput type="checkbox" checked={filtro.contrassegno} id="exampleCustomCheckbox" label="Contrassegno Aperto" onChange={() => {
                        setFiltroSpedizioniContrassegno(!filtro.contrassegno)
                    }}/>
                </div>



                <ListGroupItem>
                    <hr/>
                </ListGroupItem>




                <Select
                    isClearable
                    placeholder={"Corriere..."}
                    value={corriereSelezionato}
                    options={selectCorriere}
                    onChange={(v) => {
                        setCorriereSelezionato(v)
                        let corriere_id= (v!==null)?v.value:null;
                        setFiltroCorrierePerSpedizioni(corriere_id);
                    }}
                />


            </ListGroup>
        </div>
    );

}


const mapStateToProps = (state, ownProps) => ({
    filtro: state.spedizioni.filtro,
    filtro_contatti: state.contactReducer.filtro,
    contatti: state.contactReducer.contacts,
    corrieri: state.corrieri.corrieri,

})
const mapDispatchToProps = {
    searchSpedizioni,
    setFiltroContattoPerSpedizioni,
    setFiltroSpedizioniData,
    setFiltroSpedizioniStato,
    setFiltroSpedizioniContrassegno,
    setFiltroSpedizioniTipologia,
    searchContatti,
    setFiltroSearchContatti: setFiltroSearch,
    setFiltroCorrierePerSpedizioni
    // fetchContatti
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FiltroSpedizioni)
