import React, {useState} from "react";
import {Col, Row, Form, FormGroup, Label, Button, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import user1 from '../../../../assets/images/default_thumbs/listino_pack_default.jpg';
import {addListino} from "../../redux/actions";
import Select, {components} from "react-select";

const mapStateToProps = state => ({
    ...state,
    id: state.listini.listini.length
});

const mapDispatchToProps = {
    addListino
}

const stateOptions = [
    {value: 0, label: 'Prepagato'},
    {value: 1, label: 'Postpagato'},
];

const AddListino = ({onListinoAggiunto,...props}) => {
    let nome, descrizione, listino_ref, prezzo, fileUploader;
    const [image, setImage] = useState("");
    const [postpagato, setPostpagato] = useState(stateOptions[0]);
    const [sourceImg, setSourceImg] = useState(user1);

    let handleChange = e => {
        console.log(e.target.files)
        if (e.target.files[0]) {
            setImage(e.target.files[0]);


            let file = e.target.files[0];

            var reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = function (e) {
                setSourceImg(reader.result)
            }.bind(this);

        }


    };

    return (
        <React.Fragment>
            <Form
                onSubmit={e => {
                    e.preventDefault();
                    if (!nome.value.trim()) {
                        return;
                    }

                    let listino = {
                        nome: nome.value.toLowerCase().trim(),
                        postpagato: postpagato.value,
                        listino_ref: (!listino_ref.value === "") ? listino_ref.value : null,
                        prezzo: (postpagato.value === 0) ? prezzo.value : null,
                        descrizione: descrizione.value,
                        image
                    }
                    props.addListino(listino)
                    onListinoAggiunto();
                }}
            >
                <ModalBody>
                    <Row>
                        <Col md={6} className="text-center">
                            <img
                                onClick={(e) => {
                                    fileUploader.click()
                                }}
                                src={sourceImg}
                                className="rounded-circle"
                                // alt={props.id}
                                width="100"
                            />
                            <input
                                accept="image/*"
                                ref={comp => fileUploader = comp}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChange}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <p className="text-center mt-0 mb-3 " onClick={(e) => {
                                fileUploader.click()
                            }}>
                                <small className=" badge badge-default badge-light text-dark">clicca per carica un'immagine</small>
                            </p>

                        </Col>
                        <Col md={6}>

                            <FormGroup className="mb-3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        ref={node => (nome = node)}
                                        placeholder="Nome listino"
                                        required
                                    /> </InputGroup>


                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Nome</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>


                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="far fa-copy"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="listino_ref"
                                        id="listino_ref"
                                        ref={node => (listino_ref = node)}
                                        placeholder="Listino di riferimento"
                                    />
                                </InputGroup>

                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Copia i dati da questo listino</small>
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Select
                                    defaultValue={postpagato}
                                    placeholder={"tipologia"}
                                    name="postpagato"
                                    id="postpagato"
                                    options={stateOptions}
                                    onChange={(e => {
                                        setPostpagato(e)
                                    })}
                                />
                                <small className="mt-1 mb-4 badge badge-default badge-dark text-light float-left">Tipologia listino</small>

                            </FormGroup>
                        </Col>

                        <Col md={6} className={(postpagato.value === 1) ? "hide" : ""}>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-euro-sign"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="number"
                                        step="0.01"
                                        name="prezzo"
                                        id="prezzo"
                                        ref={node => (prezzo = node)}
                                        placeholder="Prezzo"
                                        required={(postpagato.value === 1) ? false : true}

                                    />
                                </InputGroup>

                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Inserisci il prezzo</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio se prepagato</small>
                            </FormGroup>

                        </Col>


                    </Row>
                    <Row>
                        <Col md={12}>

                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-align-left"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="descrizione"
                                        id="descrizione"
                                        placeholder="Descrizione"
                                        ref={node => (descrizione = node)}
                                    />
                                </InputGroup>

                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Descrizione</small>
                            </FormGroup>


                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        Aggiungi Listino
                    </Button>
                </ModalFooter>
            </Form>
        </React.Fragment>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AddListino);
