import {combineReducers} from "redux";
import {corrieri, lazy, spedizioni} from "./corrieri";
import corriereDetails from "./corriereDetails";
import editCorriere from "./editCorriere";
import corrieriSearch from "./corrieriSearch";

export default combineReducers({
    corrieri,
    lazy,
    corriereDetails,
    corrieriSearch,
    editCorriere
});
