import {APPUNTAMENTI_RELOADED, APPUNTAMENTI_ADD} from '../constants/';
import {APPUNTAMENTO_UPDATE} from "../constants";

const contacts = (state = [], action) => {
    switch (action.type) {
        case APPUNTAMENTI_RELOADED:
            return action.appuntamenti;
        case APPUNTAMENTI_ADD:
            return [
                ...state,
                {
                    id: action.id,
                    title: action.titolo,
                    azienda_ref: action.azienda_ref,
                    contatto_ref: action.contatto_ref,
                    start: action.start,
                    end: action.end,
                }
            ];

        case APPUNTAMENTO_UPDATE:
            // console.log(action)
            // console.log(state)
            return state.map(appuntamento => (appuntamento.id === action.appuntamento.id ? action.appuntamento : appuntamento));

        // case TOGGLE_STARRED_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, starred: !contact.starred} : contact));

        // case DELETE_CONTACT:
        //     return state.map(contact => (contact.id === action.id ? {...contact, deleted: !contact.deleted} : contact));

        default:
            return state;
    }
};

export default contacts;
