import {createStore, applyMiddleware, compose} from 'redux';
import {createLogger} from 'redux-logger';
import reducers from './reducers';
import thunk from 'redux-thunk'
import createSagaMiddleware from 'redux-saga'

import {getFirebase} from "react-redux-firebase";
import {getFirestore, reduxFirestore} from "redux-firestore";
// import fbConfig from "../firebase/fbConfig";
import rootSaga from '../sagas';

const logger = createLogger({
    collapsed: true,
});

const sagaMiddleware = createSagaMiddleware()

export function configureStore(initialState) {
    const middlewares = [
        thunk.withExtraArgument({getFirebase, getFirestore}),
        logger,
        sagaMiddleware
    ];

    const store =  createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware(...middlewares),
            // reduxFirestore(fbConfig),

        )
    );

    sagaMiddleware.run(rootSaga)

    return store;
}


