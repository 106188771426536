import {connect} from "react-redux";
import React, {useEffect, useState} from "react";
import user1 from "../../../../assets/images/users/1.jpg";
import API from "../../../../rest";


const RowAcquistoPack = ({pack, ...props}) => {
    var dateObj = new Date(pack.createdAt);


    const tipo = (pack.listinoPack.postpagato) ? "POST" : "PRE";
    const nomePack = pack.listinoPack.nome + " (" + tipo + ")";

    const [imgListino, setImgListino] = useState(user1);

    useEffect(() => {

        if (pack.listinoPack.image)
            API.listini_pack.getImage(pack.listinoPack.image).then((r) => {
                if(r)setImgListino(r)
            });


    }, [pack.id])


    return <tr key={pack.id}>
        <td>
            <div className="d-flex no-block align-items-center">
                <div className="mr-2"><img src={imgListino} alt="user" className="rounded-circle" width="45"/></div>
                <div className="">
                    <h5 className="mb-0 font-16 font-medium">{nomePack}</h5><span>{pack.listinoExtra.nome}</span></div>
            </div>
        </td>
        <td>{dateObj.toLocaleDateString()}</td>
        <td className="blue-grey-text  text-darken-4 font-medium">{pack.saldo}€/{pack.importo_accreditato}€<i className="fa fa-circle text-orange" id="tlp1"></i>
        </td>
    </tr>
}


const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = {};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RowAcquistoPack);
