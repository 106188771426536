import {all, call, put, takeEvery,takeLatest} from 'redux-saga/effects'
import Api from "../../../rest";
import {
    CORRIERI_AGGIUNGIA_ALLA_LISTA, CORRIERI_GET_LISTA_SPEDIZIONI,
    CORRIERI_RESET_LISTA, SAGA_CORRIERI_CREATE, SAGA_CORRIERI_GET_SPEDIZIONI,
    SAGA_CORRIERI_SEARCH
} from "../redux/corrieri_redux_types";
import {SESSION_FETCHING, SESSION_FETCHING_ERROR} from "../../../redux/constants";


export function* caricaCorrieri(action) {
    let search = action.payload.search;
    let filtro = action.payload.filtro;
    let lazy = action.payload.lazy;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.corrieri.search, search, filtro, lazy)

        if (lazy === 1) yield put({type: CORRIERI_RESET_LISTA, corrieri: result.rows});
        else yield put({type: CORRIERI_AGGIUNGIA_ALLA_LISTA, corrieri: result.rows});

        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _creaCorriere(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api creazione corriere", action.payload.corriere)
        const result = yield call(Api.corrieri.create, action.payload.corriere);

        console.log("result api creazione corriere", result)

        yield put({type: SESSION_FETCHING, fetching: false});

        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        // else yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: result.rows});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* _getSpedizioni(action) {
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.corrieri.getSpedizioni, action.payload.corriere_ref);

        yield put({type: CORRIERI_GET_LISTA_SPEDIZIONI, spedizioni: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}



export function* corrieriSaga() {
    yield all([
        takeEvery(SAGA_CORRIERI_SEARCH, caricaCorrieri),
        takeLatest(SAGA_CORRIERI_CREATE, _creaCorriere),
        takeEvery(SAGA_CORRIERI_GET_SPEDIZIONI, _getSpedizioni),
    ])
}
