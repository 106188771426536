import {
    SAGA_SPEDIZIONE_UPDATE,
    SAGA_SPEDIZIONI_CREATE,
    SAGA_SPEDIZIONI_SEARCH, SPEDIZIONI_FILTRO_CONTATTO, SPEDIZIONI_FILTRO_CONTRASSEGNO, SPEDIZIONI_FILTRO_CORRIERE,
    SPEDIZIONI_FILTRO_DATA, SPEDIZIONI_FILTRO_LOAD_PAGE, SPEDIZIONI_FILTRO_PAGAMENTO, SPEDIZIONI_FILTRO_PAGAMENTO_ENUM, SPEDIZIONI_FILTRO_RESET,
    SPEDIZIONI_FILTRO_STATO, SPEDIZIONI_FILTRO_TIPOLOGIA,
    SPEDIZIONI_LAZY_LOADING, SPEDIZIONI_SET_FILTRO
} from "./spedizioni_redux_types";


export const searchSpedizioni = () => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();
        const filtro = state.spedizioni.filtro;
        // console.log("filtro spedizioni", filtro);

        //SE lazy e' tru devo aumentare l'offset per la queri e fare il dispatch dell'aggiunta e non il repalce dei contatti
        // if (lazy) {
        //     dispatch({
        //         type: SPEDIZIONI_LAZY_LOADING,
        //     });
        // } else {
        //     dispatch({
        //         type: SPEDIZIONI_LAZY_LOADING,
        //         reset: true
        //     })
        // }

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_SPEDIZIONI_SEARCH,
            payload: {
                filtro
            }
        })

    }
}


export const creaSpedizione = (spedizione) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_SPEDIZIONI_CREATE,
            payload: {
                spedizione,
            }
        });

    }


}


export const setFiltroSpedizioni = (filtro) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_SET_FILTRO,
            payload:{
                filtro
            }
        });
    }
}
export const resetFiltroSpedizioni = () => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_RESET,
        });
    }
}

export const setFiltroSpedizioniData = (filtro, from, to) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_DATA,
            payload: {
                filtro,
                from,
                to
            }
        });
    }
}

export const setFiltroSpedizioniStato = (stato) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_STATO,
            payload: {
                stato,
            }
        });
    }
}
export const setFiltroSpedizioniContrassegno = (contrassegno) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_CONTRASSEGNO,
            payload: {
                contrassegno,
            }
        });
    }
}
export const setFiltroSpedizioniTipologia = (tipologia) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_TIPOLOGIA,
            payload: {
                tipologia,
            }
        });
    }
}
export const setFiltroSpedizioniPagamento = (pagamento) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_PAGAMENTO,
            payload: {
                pagamento,
            }
        });
    }
}

export const setFiltroLoadPage = (page) => {

    return (dispatch, getState, {getFirestore, getFirebase}) => {
        console.log("SPEDIZIONI_FILTRO_LOAD_PAGE",page)


        dispatch({
            type: SPEDIZIONI_FILTRO_LOAD_PAGE,
            payload: {
                page,
            }
        });
    }
}

export const setFiltroContattoPerSpedizioni = (contatto_id)=>{
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_CONTATTO,
            payload: {
                contatto:contatto_id,
            }
        });
    }
}

export const setFiltroCorrierePerSpedizioni = (corriere_id)=>{
    return (dispatch, getState, {getFirestore, getFirebase}) => {
        dispatch({
            type: SPEDIZIONI_FILTRO_CORRIERE,
            payload: {
                corriere:corriere_id,
            }
        });
    }
}



export const aggiornaSpedizione = (spedizione) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_SPEDIZIONE_UPDATE,
            payload: {
                spedizione
            }
        });
    }
}




