import React, {useEffect, useState} from "react";
import {Col, Row, Form, FormGroup, Label, Button, ModalBody, ModalFooter, InputGroup, InputGroupAddon, InputGroupText} from "reactstrap";
import {connect} from "react-redux";
import user1 from '../../../../assets/images/default_thumbs/listino_extra_tipo_default.jpg';
import {addTipoExtra, modificaTipoExtra} from "../../redux/actions";


const AddTipoExtra = ({extra, onAdded, addTipoExtra,modificaTipoExtra, ...props}) => {
    let fileUploader;
    const [image, setImage] = useState("");
    const [sourceImg, setSourceImg] = useState(user1);
    const [nome, setNome] = useState("");
    const [costo, setCosto] = useState(0);
    const [descrizione, setDescrizione] = useState("");

    useEffect(()=>{
        if(extra!==undefined){
            setNome(extra.nome);
            setCosto(parseFloat(extra.prezzo));
            setDescrizione(extra.descrizione);
        }
    },[JSON.stringify(extra)])


    const handleChange = e => {
        if (e.target.files[0]) {
            setImage(e.target.files[0]);
            let file = e.target.files[0];
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                setSourceImg(reader.result)
            }.bind(this);
        }
    };


    return (
        <React.Fragment>
            <Form
                onSubmit={e => {
                    e.preventDefault();

                    if (!nome.trim() ) {
                        return;
                    }


                    const tipologia_extra = {
                        nome: nome,
                        listino_extra_ref: props.listextra.listextraDetails,
                        descrizione: descrizione,
                        prezzo: costo,
                        image
                    }


                    console.log("dati pescati dal form ", tipologia_extra)

                    if(extra){
                        tipologia_extra.id=extra.id;
                        modificaTipoExtra(tipologia_extra)
                    }else{
                        addTipoExtra(tipologia_extra)
                    }


                    onAdded()
                    setNome("");
                    setCosto(1);
                    setDescrizione("");


                }}
            >
                <ModalBody>

                    <Row>
                        {/*immagine*/}
                        <Col md={6} className="text-center">
                            <img
                                onClick={(e) => {
                                    fileUploader.click()
                                }}
                                src={sourceImg}
                                className="rounded-circle"
                                // alt={props.id}
                                width="100"
                            />
                            <input
                                accept="image/*"
                                ref={comp => fileUploader = comp}
                                id="contained-button-file"
                                multiple
                                type="file"
                                onChange={handleChange}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <p className="text-center mt-0 mb-3 " onClick={(e) => {
                                fileUploader.click()
                            }}>
                                <small className=" badge badge-default badge-light text-dark">clicca per carica un'immagine</small>
                            </p>

                        </Col>

                        {/*nome e prezzo*/}
                        <Col md={6}>

                            <FormGroup className="mb-3">
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="nome"
                                        id="nome"
                                        value={nome}
                                        onChange={(e)=>{
                                            setNome(e.target.value)
                                        }}
                                        placeholder="Tipo Extra"
                                        required
                                    /> </InputGroup>


                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Nome</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>


                            <FormGroup>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="far euro"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        name="costo"
                                        id="costo"
                                        value={costo}
                                        onChange={(e)=>{
                                            setCosto(e.target.value)
                                        }}
                                        required
                                    />
                                </InputGroup>
                                <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Prezzo</small>
                                <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                            </FormGroup>

                        </Col>
                    </Row>




                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="descrizione">Descrizione</Label>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="descrizione"
                                    id="descrizione"
                                    value={descrizione}
                                    onChange={(e)=>{
                                        setDescrizione(e.target.value)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" type="submit">
                        {(extra===undefined)?'Aggiungi Extra':"Modifica Extra"}
                    </Button>
                </ModalFooter>
            </Form>
        </React.Fragment>
    );
};

const mapStateToProps = state => ({
    ...state,
    id: state.listextra.listini.length
});

const mapDispatchToProps = {
    addTipoExtra,
    modificaTipoExtra
}


export default connect(mapStateToProps, mapDispatchToProps)(AddTipoExtra);
