import React, {Fragment} from "react";


const Fetching = ({...props}) => {
    return <Fragment>
        <div
            id={"overlay-fetching"}>
            <div id={"overlay-fetching-content"}>
                <div>
                    <i className={"fas fa-circle-notch fa-spin fa-4x mb-3"}></i><br/>
                    Loading....
                </div>
            </div>
        </div>
    </Fragment>

}


export default Fetching;
