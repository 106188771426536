import React, {Component, Fragment, useEffect, useState} from "react";

// import ContactsSearch from "./components/contactsSearch";
import ListiniList from "./containers/visibleListiniList";
import ListinoDetails from "./containers/visibleListinoDetails";
import {connect} from "react-redux";
import {listiniFetch} from "../redux/actions";
import ListiniSearch from "./components/listiniSearch";
import ListiniFilter from "./components/listiniFilter";

const Listini_pack = (props) => {

    const [showLeftPart, setShowLeftPart] = useState(false);
    const [showRightPart, setShowRightPart] = useState(false);

    useEffect(() => {
        if (!props.fetched) {
            props.listiniFetch();
        }
        console.log('use effect in listini.js!');
    }, []); // passing an empty array as


    // props.getListini();

    let showLeftPartFx = () => {
        setShowLeftPart(!setShowLeftPart);
    }
    let showRightPartFx = () => {
        setShowRightPart(!setShowRightPart);
    }

    return (
        <Fragment>
            <div className="contact-app">
                <div className={"left-part bg-white " + (showLeftPart === true ? "show-panel" : "")}>
                    <ListiniFilter/>
                </div>
                <div className="right-part contact-list bg-white">
                    <div className={"right-left-part " + (showRightPart === true ? "show-right-left-panel" : "")}>
                        <span onClick={() => {
                            showLeftPartFx();
                        }} className={"bg-primary show-left-part text-white d-block d-lg-none " + (showLeftPart === true ? "left-part-open" : "")}><i
                            className={(showLeftPart === true ? "fas fa-chevron-left" : "fas fa-chevron-right")}></i></span>
                        <ListiniSearch/>
                        <ListiniList showRightPart={() => {
                            showRightPartFx();
                        }}/>
                    </div>
                    <div className={"right-right-part " + (showRightPart === true ? "show-right-right-panel" : "")}>
                        <span onClick={() => {
                            showRightPartFx();
                        }} className={"hide-right-right-part d-block d-md-none " + (showRightPart === true ? "right-right-part-open" : "")}><i
                            className="fas fa-times"></i></span>
                        <ListinoDetails/>
                    </div>
                </div>
            </div>
        </Fragment>
    );

}

const mapDispatchToProps = {
    listiniFetch
}
export default connect(null, mapDispatchToProps)(Listini_pack);
