import {all, call, put, takeEvery,takeLatest} from 'redux-saga/effects'
import Api from "../../../rest";
import {SESSION_FETCHING, SESSION_FETCHING_ERROR} from "../../../redux/constants";
import {
    DELETE_FATTURA,
    FATTURE_CREAZIONE,
    FATTURE_FILTRO_PAGINATION_COUNT,
    FATTURE_RESET_LISTA,
    SAGA_FATTURE_CREATE, SAGA_FATTURE_DELETE, SAGA_FATTURE_DETTAGLI,
    SAGA_FATTURE_SEARCH,
    SAGA_FATTURE_UPDATE, UPDATE_FATTURA
} from "../redux/fatture_redux_types";


export function* searchFattureSaga(action) {
    let filtro = action.payload.filtro;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.fatture.search, filtro)

        if(result.count!==filtro.pagination_count) yield put({type: FATTURE_FILTRO_PAGINATION_COUNT, count: result.count});
        yield put({type: FATTURE_RESET_LISTA, fatture: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});





    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _creaFattura(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        console.log("rest api creazione fattura", action.payload.fattura)
        const result = yield call(Api.fatture.create, action.payload.fattura);
        console.log("result api creazione fattura", result)
        //TODOGP pushare la creazione della nuova fattura?
        yield put({type: SESSION_FETCHING, fetching: false});
    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* _updateFattura(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api update fattura", action.payload.fattura)
        const result = yield call(Api.fatture.update, action.payload.fattura);

        console.log("result api update fattura", result)
        yield put({type: UPDATE_FATTURA, fattura: action.payload.fattura});
        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        // else yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _deleteFattura(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api update fattura", action.payload.fattura_id)
        const result = yield call(Api.fatture.elimina, action.payload.fattura_id);

        console.log("result api update fattura", result)
        yield put({type: DELETE_FATTURA, fattura_id: action.payload.fattura_id});
        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        // else yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}




export function* dettagliFattureSaga(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api dettagli fattura", action.payload.fattura)
        const result = yield call(Api.fatture.dettaglio, action.payload.fattura);

        console.log("result api dettaglio fattura", result)
        yield put({type: UPDATE_FATTURA, fattura: result.fattura});

        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* fattureSaga() {
    yield all([
        takeEvery(SAGA_FATTURE_SEARCH, searchFattureSaga),
        takeEvery(SAGA_FATTURE_DETTAGLI, dettagliFattureSaga),
        takeLatest(SAGA_FATTURE_CREATE, _creaFattura),
        takeLatest(SAGA_FATTURE_UPDATE, _updateFattura),
        takeLatest(SAGA_FATTURE_DELETE, _deleteFattura),
        // takeEvery(SAGA_CORRIERI_GET_SPEDIZIONI, _getSpedizioni),
    ])
}
