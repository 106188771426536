import React from 'react';

const commit_tag = window.COMMIT_TAG || '';
const commit_branch = window.COMMIT_BRANCH || '';
const commit_sha = window.COMMIT_SHORT_SHA || '';

let versione = "---";
if(commit_tag!=='') versione = commit_tag;
else if(commit_branch!=='') versione= commit_branch + " (" + commit_sha + ")";


class Footer extends React.Component {
    render() {
        return (
            <footer className="footer text-center">
                bmclient v.: {versione} - realizzato da <a href="https://gpmultimedia.net" target="_blank">GpMultimedia</a>.
            </footer>
        );
    }
}

export default Footer;
