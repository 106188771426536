import React, {useEffect, useState} from "react";
import {searchContatti, setFiltroSearch} from "../../redux/action";
import {connect} from "react-redux";
import Select from "react-select";


const ContattiAjaxSelect = ({
                                filtro, contatti,
                                searchContatti, setFiltroSearch,
                                onChange,
                                ...props
                            }) => {

    const [contattoSearch, setContattoSearch] = useState('');
    const [contattiSelect, setContattiSelect] = useState([]);


    useEffect(() => {
        if (contattoSearch.length < 3) return;
        else setFiltroSearch(contattoSearch);
    }, [contattoSearch]);

    useEffect(() => {
        searchContatti();
    }, [JSON.stringify(filtro)]);


    useEffect(() => {
        if (contattoSearch.length < 3) {
            setContattiSelect([]);
            return;
        }
        setContattiSelect(contatti);
    }, [JSON.stringify(contatti)]);


    return <Select
        isClearable
        noOptionsMessage={() => (contattoSearch.length < 3) ? 'digita almeno 3 caratteri' : "nessun cliente trovato"}
        getOptionLabel={(option) => `${option.nome} ${option.cognome} ${option.rag_sociale}`}
        getOptionValue={(option) => option.id}
        options={contattiSelect}
        onInputChange={setContattoSearch}
        onChange={(contatto) => {
            if (onChange) onChange((contatto) ? contatto.id : null);
        }}
        styles={customStyles}
        placeholder={"Cliente..."}

    />;


}


const customStyles = {
    clearIndicator: ()=>({
        margin: '0px'
    }),
    menu: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted pink',
        margin: 0,
    }),
    // menu:()=>({
    //     zIndex:9999999
    // })
}


const mapStateToProps = (state, ownProps) => ({
    filtro: state.contactReducer.filtro,
    contatti: state.contactReducer.contacts
})
const mapDispatchToProps = {
    searchContatti,
    setFiltroSearch
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContattiAjaxSelect)

