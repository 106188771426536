import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Button, Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, ModalBody, ModalFooter, Row} from "reactstrap";
import Select from "react-select";
import { addIndirizzo} from "../../redux/action";

function getContattoSelezionato(_contatti, _contact_id) {
    let _Contatto = undefined;
    _contatti.forEach(_c => {
        if (_c.id == _contact_id) _Contatto = _c;
    });
    return _Contatto;
}


const AddIndirizzoToContact = ({contatto, loadIndirizzi, addIndirizzo, onRequestComplete, ...props}) => {

    //ERRORI
    const [errorComune, setErrorComune] = useState(false);
    const [errorIndirizzo, setErrorIndirizzo] = useState(false);

    const [comune, setComune] = useState('');
    const [indirizzo, setIndirizzo] = useState('');

    useEffect(() => {
    }, [contatto]);



    const onSubmit = (e) => {
        e.preventDefault();


        setErrorComune(comune === "");
        setErrorIndirizzo(indirizzo === "");

        //controllo i dati sul credito

        if(!errorComune && !errorIndirizzo){
            const new_indirizzo = {
                contatto_ref: contatto.id,
                comune,
                indirizzo
            }

            console.log("indirizzo",new_indirizzo)
            addIndirizzo(new_indirizzo, true)

        }

        // if (packScelto.value!==0 && extraScelto.value!==0) {
        //     const _pack = {
        //         contatto_ref: contatto.id,
        //         listino_pack_ref: packScelto.value,
        //         listino_extra_ref: extraScelto.value,
        //         postpagato,
        //         importo_accreditato: indirizzo,
        //         importo_pagato: importoDaPagare,
        //         importo_saldato: importoSaldato
        //     }
        //     // salvo i dati
        //
        //     addAcquisto(_pack)
        //     console.log("acquisto",_pack)
        //
        //
        // }

    }


    return <React.Fragment>

        <Form
            onSubmit={onSubmit}
        >
            <ModalBody>

                <Row className={""}>
                    <Col md={6}>
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-book"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="comune"
                                    id="comune"
                                    // defaultValue={creditoErogato}
                                    value={comune}
                                    // ref={node => (credito_erogato = node)}
                                    placeholder="Comune"
                                    onChange={(e) => {
                                        setComune(e.target.value)
                                    }}
                                    // onBlur={this.validationCheck}
                                /> </InputGroup>
                            <p className="text-center mb-3 ">
                                <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Comune</small>
                                {(errorComune) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>

                    </Col>
                </Row>
                <Row className={""}>
                    <Col md={12}>
                        {/*credito erogato*/}
                        <FormGroup>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fas fa-book"/>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <input
                                    className="form-control"
                                    type="text"
                                    name="indirizzo"
                                    id="indirizzo"
                                    // defaultValue={creditoErogato}
                                    value={indirizzo}
                                    // ref={node => (credito_erogato = node)}
                                    placeholder="Indirizzo"
                                    onChange={(e) => {
                                        setIndirizzo(e.target.value)
                                    }}
                                    // onBlur={this.validationCheck}
                                /> </InputGroup>
                            <p className="text-center mb-3 ">
                                <small className=" mt-1 badge badge-default badge-dark text-whites float-left">Indirizzo</small>
                                {(errorIndirizzo) ?
                                    <small className=" badge badge-default badge-danger text-white">Compila il campo!!!</small> : ""}
                                <small className=" badge badge-default badge-light text-whites float-right">Obbligatorio</small>
                            </p>
                        </FormGroup>

                    </Col>
                </Row>


            </ModalBody>
            <ModalFooter>
                <Button color="primary" type="submit">
                    Aggiungi indirizzo
                </Button>
            </ModalFooter>
        </Form>


    </React.Fragment>
}

const mapStateToProps = state => ({
    // contatto: getContattoSelezionato(state.contactReducer.contacts, state.contactReducer.contactsDetails),
});

const mapDispatchToProps = {
    addIndirizzo
}

export default connect(mapStateToProps, mapDispatchToProps)(AddIndirizzoToContact);
