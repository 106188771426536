export const SAGA_FATTURE_SEARCH = "SAGA_FATTURE_SEARCH";
export const SAGA_FATTURE_CREATE = "SAGA_FATTURE_CREATE";
export const SAGA_FATTURE_UPDATE = "SAGA_FATTURE_UPDATE";
export const SAGA_FATTURE_DELETE = "SAGA_FATTURE_DELETE";
export const SAGA_FATTURE_DETTAGLI = "SAGA_FATTURE_DETTAGLI";

export const ADD_FATTURA = "ADD_FATTURA";
export const DELETE_FATTURA = "DELETE_FATTURA";
export const FATTURA_SELEZIONATA = "FATTURA_SELEZIONATA";
export const UPDATE_FATTURA = "UPDATE_FATTURA";
export const FATTURE_LAZY_LOADING = "FATTURE_LAZY_LOADING";
export const FATTURE_RESET_LISTA = "FATTURE_RESET_LISTA";
export const FATTURE_AGGIUNGIA_ALLA_LISTA = "FATTURE_AGGIUNGIA_ALLA_LISTA";


export const FATTURE_FILTRO_RESET = "FATTURE_FILTRO_RESET";
export const FATTURE_FILTRO_DATA = "FATTURE_FILTRO_DATA";
export const FATTURE_FILTRO_STATO = "FATTURE_FILTRO_STATO";
export const FATTURE_FILTRO_TIPO = "FATTURE_FILTRO_TIPO";
export const FATTURE_FILTRO_PAGAMENTO = "FATTURE_FILTRO_PAGAMENTO";
export const FATTURE_FILTRO_CONTATTO = "FATTURE_FILTRO_CONTATTO";
export const FATTURE_FILTRO_LOAD_PAGE = "FATTURE_FILTRO_LOAD_PAGE";
export const FATTURE_FILTRO_LIMIT = "FATTURE_FILTRO_LIMIT";
export const FATTURE_FILTRO_PAGINATION_COUNT = "FATTURE_FILTRO_PAGINATION_COUNT";


export const FATTURE_FILTRO_STATO_ENUM = {
    ALL: "FATTURE_STATO_ENUM_ALL___999",
    CREATE: "FATTURE_STATO_ENUM_APERTE___0",
    ANNULLATE: "FATTURE_STATO_ENUM_ANNULLATA___1",
    DA_CONTROLLARE: "FATTURE_STATO_ENUM_DA_CONTROLLARE___2",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'ok',
                    code: 'OK',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'annullata',
                    code: 'AN',
                    color: 'secondary'
                };
            case 2:
                return {
                    val: 2,
                    label: 'da controllare',
                    code: 'DC',
                    color: 'dark'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};

export const FATTURE_FILTRO_TIPO_ENUM = {
    ALL: "FATTURE_FILTRO_TIPO_ENUM_ALL___999",
    FATTURA: "FATTURE_FILTRO_TIPO_ENUM_FATTURA___0",
    NOTA_CREDITO: "FATTURE_FILTRO_TIPO_ENUM_NOTA_CREDITO___1",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'fattura',
                    code: 'FT',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'nota credito',
                    code: 'NC',
                    color: 'secondary'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};

export const FATTURE_FILTRO_PAGAMENTO_ENUM = {
    ALL: "FATTURE_PAGAMENTO_ENUM_ALL___999",
    NON_PAGATE: "FATTURE_PAGAMENTO_ENUM_NON_PAGATE___0",
    PAGATE: "FATTURE_PAGAMENTO_ENUM_PAGATE___1",
    getStato: (stato) => {
        switch (stato) {
            case 0:
                return {
                    val: 0,
                    label: 'non pagate',
                    code: 'NP',
                    color: 'light'
                };
            case 1:
                return {
                    val: 1,
                    label: 'pagate',
                    code: 'PA',
                    color: 'light'
                };
            default:
                return {
                    val: 999,
                    label: 'non definito',
                    code: 'ND',
                    color: 'inverse'
                };
        }

    },
};



export const FATTURE_FILTRO_DATA_ENUM = {
    ALL: "FATTURE_DATA_ENUM_ALL",
    RANGE: "FATTURE_DATA_ENUM_RANGE",
};
