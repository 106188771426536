import {CORRIERE_DETAILS, EDIT_CORRIERE, UPDATE_CORRIERE, FILTER_CORRIERE, DELETE_CORRIERE} from '../../../redux/constants';
import {CORRIERI_LAZY_LOADING, SAGA_CORRIERI_CREATE, SAGA_CORRIERI_GET_SPEDIZIONI, SAGA_CORRIERI_SEARCH} from "./corrieri_redux_types";


export const resetCorriere = () => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: CORRIERE_DETAILS,
            id: ""
        })

    }

}


export const getSpedizioni = (from, to) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        const corriere_ref = state.corrieri.corriereDetails;

        dispatch({
            type: SAGA_CORRIERI_GET_SPEDIZIONI,
            payload: {
                corriere_ref,
                from,
                to
            }
        })


    }


}



export const getDettagliCorriere = (id) => {
    console.log("dettagli corriere id:", id);
    return (dispatch, getState, {getFirestore}) => {
        let state = getState();


    }
}


export const fetchCorrieri = (lazy = false) => {
    return (dispatch, getState, {getFirestore}) => {
        const state = getState();

        //SE lazy e' tru devo aumentare l'offset per la queri e fare il dispatch dell'aggiunta e non il repalce dei contatti
        if (lazy) {
            dispatch({
                type: CORRIERI_LAZY_LOADING,
            });
        } else {
            dispatch({
                type: CORRIERI_LAZY_LOADING,
                reset: true
            })
        }
        let l = (lazy) ? state.corrieri.lazy + 1 : state.corrieri.lazy;

        //BECCATO DA SAGA
        dispatch({
            type: SAGA_CORRIERI_SEARCH,
            payload: {
                search: state.corrieri.corrieriSearch,
                lazy: l,
            }
        })

    }
}



export const creaCorriere = (corriere) => {
    return (dispatch, getState, {getFirestore, getFirebase}) => {

        dispatch({
            type: SAGA_CORRIERI_CREATE,
            payload: {
                corriere,
            }
        });

    }


}


export const aggiornaCorriere = (id, values, flag) => {
    // console.log(values)

    return (dispatch, getState, {getFirestore}) => {




        dispatch({
            type: EDIT_CORRIERE,
            flag
        });

    }

};


export const updateCorriere = (id, field, value) => ({
    type: UPDATE_CORRIERE,
    id: id,
    field: field,
    value: value
});

export const updateCorriere2 = (id, field, value) => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: UPDATE_CORRIERE,
            id: id,
            field: field,
            value: value
        });

    };
}

export const corriereDetails = id => ({
    type: CORRIERE_DETAILS,
    id
});

export const setEditCorriereFlag = flag => ({
    type: EDIT_CORRIERE,
    flag
});
export const setEditCorriereFlag2 = flag => {
    return (dispatch, getState, {getFirestore}) => {
        dispatch({
            type: EDIT_CORRIERE,
            flag
        })
    }
};




export const corrieriSearch = searchTerm => {
    return (dispatch, getState, {getFirestore}) => {

        dispatch({
            type: FILTER_CORRIERE,
            payload: searchTerm
        })
    }
};


export const deleteCorriere = id => ({
    type: DELETE_CORRIERE,
    id
});
