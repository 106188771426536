import React, {Fragment, useEffect, useState} from "react";


export const ExtraMenuItem = ({item, selected, onMinusClick, onBtnClick, old, ...props}) => {
    const [qt, setQt] = useState((old && old.qt)?old.qt:0);


    return <div className={` align-items-center menu-item-extra ${selected ? 'active' : ''}`}>


        <div className="d-flex align-items-center">
            <div>
                <h4 className="font-medium mb-0">{item.nome} </h4>
                <span>{item.prezzo}€</span>
            </div>

        </div>


        <div className="d-flex align-items-center">
            <a className="btn-circle btn btn-sm btn-outline-warning" href="#" onClick={() => {
                if (qt > 0) {
                    setQt(qt - 1);
                    if (onBtnClick) onBtnClick(item, qt - 1)
                }
            }}>-</a>
            <h3><span className="badge badge-secondary">{qt}</span></h3>
            <a className="btn-circle btn btn-sm btn-outline-success" href="#"
               onClick={() => {
                   setQt(qt + 1);
                   if (onBtnClick) onBtnClick(item, qt + 1)
               }}
            >+</a>


        </div>


        <br/>
    </div>;
};


