import {EDIT_LISTINO, LISTINO_DETAILS} from "./_constant";

const EditContact = (state = false, action) => {
	switch (action.type) {
		case EDIT_LISTINO:
			return !state ? true : false;
		case LISTINO_DETAILS:
			return state = false;
		default:
			return state;
	}
};

export default EditContact;
