import React, {Fragment, useEffect, useState} from "react";
import {connect} from "react-redux";
import {fetching} from "../../../redux/session/action";
import Api from "../../../rest";
import {Button, Col, CustomInput, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Row} from "reactstrap";
import Select from "react-select";

const SettingsMailer = ({fetching, ...props}) => {

    const [modifica, setModifica] = useState(false);
    const [testing, setTesting] = useState(false);
    const [testSuccess, setTestSuccess] = useState(false);
    const [testError, setTestError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');


    const [settings, setSettings] = useState({});

    const [email, setEmail] = useState("");
    const [host, setHost] = useState("");
    const [port, setPort] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [tls, setTls] = useState(true);
    const [allowSelfSignedCert, setAllowSelfSignedCert] = useState(true);
    const [ignoreTls, setIgnoreTls] = useState(false);
    const [requireTls, setRequireTls] = useState(false);


    useEffect(() => {
        fetching(true)
        Api.settings.get('mailer').then((r) => {
            setSettings(r);
            setEmail(r.email ? r.email : '');
            setHost(r.host ? r.host : '');
            setPort(r.port ? r.port : '');
            setUsername(r.username ? r.username : '');
            setPassword(r.password ? r.password : '');
            setTls(r.tls ? r.tls : true);
            setAllowSelfSignedCert(r.allow_self_signed_cert ? r.allow_self_signed_cert : true);
            setIgnoreTls(r.ignore_tls ? r.ignore_tls : false);
            setRequireTls(r.require_tls ? r.require_tls : false);

            fetching(false);
        }).catch((e) => {
            fetching(false, true, 'impossibile caricare impostazioni')
        });


    }, [])


    const testImpostazioni = () => {

        setTestSuccess(false)
        setTestError(false)
        setTesting(true);
        Api.settings.mailer_test( {
            email, host, port,
            username, password: password,
            tls,
            allow_self_signed_cert: allowSelfSignedCert,
            ignore_tls: ignoreTls,
            require_tls: requireTls
        }).then((r) => {
            setTesting(false);
            if(r.success) {
                setTestSuccess(true);
                setTestError(false);
                setErrorMsg('');
                setTimeout(()=>{
                    setTestSuccess(false)
                },10*1000);


            }
            else {
                setTestSuccess(false);
                setTestError(true);
                setErrorMsg(`errore nella connessione: ${r.error.errno} ${r.error.code}`);
                setTimeout(()=>{
                    setTestError(false)
                },10*1000);
            }



            console.log("result test", r)

        }).catch((e) => {
            setTesting(false);

        })


    }
    const resetImpostazioni = () => {
        setUsername(settings.username ? settings.username : '')
        setPassword(settings.password ? settings.password : '')
        setModifica(false);
    }
    const salvaImpostazioni = () => {
        const settings = {
            email, host, port,
            username, password,
            tls,
            allow_self_signed_cert: allowSelfSignedCert,
            ignore_tls: ignoreTls,
            require_tls: requireTls
        }

        fetching(true)
        Api.settings.set('mailer', settings).then((r) => {
            fetching(false);
            setSettings(settings);
        }).catch((e) => {
            fetching(false, true, 'impossibile caricare impostazioni')
        });

        setModifica(false);
    }

    return <Fragment>
        <h2>Impostazioni Mailer {!modifica ? <Button onClick={() => {
            setModifica(true)
        }} className="btn" outline color="primary">Modifica</Button> : ''}
        </h2>
        <hr/>

        <Row className={"mb-3"}>
            <Col md={4}>
                <FormGroup className="mb-3">
                    {modifica ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-book"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            placeholder="Email"
                            required
                        /> </InputGroup> : <h3>{email.length > 0 ? email : <span className={"text-danger"}>------</span>}</h3>}
                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Email</small>
                    <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                </FormGroup>

            </Col>
            <Col md={4}>
                <FormGroup className="mb-3">
                    {modifica ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-book"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="text"
                            value={host}
                            onChange={(e) => {
                                setHost(e.target.value);
                            }}
                            required
                        /> </InputGroup> : <h3>{host.length > 0 ? host : <span className={"text-danger"}>------</span>}</h3>}

                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Host</small>
                    <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                </FormGroup>

            </Col>
            <Col md={4}>
                <FormGroup className="mb-3">
                    {modifica ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-book"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="number"
                            value={port}
                            onChange={(e) => {
                                setPort(e.target.value);
                            }}
                            required
                        /> </InputGroup> : <h3>{port.length > 0 ? port : <span className={"text-danger"}>---</span>}</h3>}

                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Port</small>
                    <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                </FormGroup>

            </Col>
        </Row>
        <Row className={"mb-3"}>
            <Col md={6}>
                <FormGroup className="mb-3">
                    {modifica ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-book"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="text"
                            name="nome"
                            id="nome"
                            value={username}
                            onChange={(e) => {
                                setUsername(e.target.value);
                            }}
                            placeholder="Nome listino"
                            required
                        /> </InputGroup> : <h3>{username}</h3>}

                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Username</small>
                    <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                </FormGroup>

            </Col>
            <Col md={6}>
                <FormGroup className="mb-3">
                    {modifica ? <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                                <i className="fas fa-book"/>
                            </InputGroupText>
                        </InputGroupAddon>
                        <input
                            className="form-control"
                            type="password"
                            name="password"
                            value={password}
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            required
                        /> </InputGroup> : <h3>{password.length > 0 ? '**********' : <span className={"text-danger"}>------</span>}</h3>}

                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Password</small>
                    <small className="mt-1 mb-4 badge badge-default badge-light text-dark float-right">Obbligatorio</small>
                </FormGroup>

            </Col>
        </Row>

        <Row className={"mb-3"}>
            <Col md={3}>
                <FormGroup className="mb-3">
                    <InputGroup>
                        <CustomInput type="checkbox"
                                     disabled={(!modifica ? 'disabled' : '')} checked={tls}
                                     id="tls" label="tls"
                                     onChange={() => {
                                         setTls(!tls);
                                     }}
                        />
                    </InputGroup>
                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">Ssl/Tls</small>
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup className="mb-3">
                    <InputGroup>
                        <CustomInput type="checkbox"
                                     disabled={(!modifica ? 'disabled' : '')} checked={allowSelfSignedCert}
                                     id="allowSelfSignedCert" label="allowSelfSignedCert"
                                     onChange={() => {
                                         setAllowSelfSignedCert(!allowSelfSignedCert);
                                     }}
                        />
                    </InputGroup>
                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">allowSelfSignedCert</small>
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup className="mb-3">
                    <InputGroup>
                        <CustomInput type="checkbox"
                                     disabled={(!modifica ? 'disabled' : '')} checked={ignoreTls}
                                     id="ignoreTls" label="ignoreTls"
                                     onChange={() => {
                                         setIgnoreTls(!ignoreTls);
                                     }}
                        />
                    </InputGroup>
                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">ignoreTls</small>
                </FormGroup>

            </Col>
            <Col md={3}>
                <FormGroup className="mb-3">
                    <InputGroup>
                        <CustomInput type="checkbox"
                                     disabled={(!modifica ? 'disabled' : '')} checked={requireTls}
                                     id="requireTls" label="requireTls" onChange={() => {
                            setRequireTls(!setRequireTls)
                        }}/>
                    </InputGroup>
                    <small className="mt-1 mb-4 badge badge-default badge-dark text-white float-left">setRequireTls</small>
                </FormGroup>

            </Col>
        </Row>


        <Row>
            <Col md={12}>
                <Button onClick={testImpostazioni} className="btn" outline color="primary">Test</Button>&nbsp;
                {(testing) ? <i className={"fas fa-circle-notch fa-spin fa-2x mb-3"}></i> : ''}
                {testSuccess?<span className={"text-success"}>Connessione ok!</span>:''}
                {testError?<span className={"text-danger"}>{errorMsg}</span>:''}
            </Col>
        </Row>

        {modifica ? <Fragment>
            <Button onClick={resetImpostazioni} className="btn" outline color="default">Annulla</Button>&nbsp;
            <Button onClick={salvaImpostazioni} className="btn" outline color="success">Salva</Button>
        </Fragment> : ''}


    </Fragment>
}


const mapStateToProps = (state, ownProps) => ({});


const mapDispatchToProps = {
    fetching
}
export default connect(mapStateToProps, mapDispatchToProps)(SettingsMailer);
