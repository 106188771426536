import {LIST_EXTRA_DETAILS} from "./_constant";

const ListinoDetails = (state = '', action) => {
	switch (action.type) {
		case LIST_EXTRA_DETAILS:
			return action.id;
		default:
			return state;
	}
};

export default ListinoDetails;
