import { connect } from "react-redux";
import listiniList from "../components/listiniList";
import React from "react";
import {deleteListino, listinoDetails, toggleStarredListino} from "../../redux/actions";



const mapStateToProps = (state, ownProps) => ({
   // listinis: state.listini.listini,
   active: state.listini.listinoDetails,
   ...state
});

const mapDispatchToProps = (dispatch, ownProps) => ({
   toggleStarredListino: id => dispatch(toggleStarredListino(id)),
   deleteListino: id => dispatch(deleteListino(id)),
   listinoClick: id => dispatch(listinoDetails(id)),
   // getContacts
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(listiniList);
