import {all, call, put, takeEvery} from 'redux-saga/effects'
import Api from "../../../rest";
import {
    SAGA_SPEDIZIONE_UPDATE,
    SAGA_SPEDIZIONI_CREATE,
    SAGA_SPEDIZIONI_SEARCH,
    SPEDIZIONI_AGGIUNGIA_ALLA_LISTA, SPEDIZIONI_FILTRO_PAGINATION_COUNT,
    SPEDIZIONI_RESET_LISTA, UPDATE_SPEDIZIONE
} from "../redux/spedizioni_redux_types";
import {takeLatest} from "@redux-saga/core/effects";
import {SESSION_FETCHING, SESSION_FETCHING_ERROR} from "../../../redux/constants";


export function* searchSpedizioni(action) {
    let filtro = action.payload.filtro;
    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        const result = yield call(Api.spedizioni.search, filtro)

        if(result.count!==filtro.pagination_count) yield put({type: SPEDIZIONI_FILTRO_PAGINATION_COUNT, count: result.count});
        yield put({type: SPEDIZIONI_RESET_LISTA, spedizioni: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});





    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}

export function* _creaSpedizione(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {
        console.log("rest api creazione spedizione", action.payload.spedizione)
        const result = yield call(Api.spedizioni.create, action.payload.spedizione);

        console.log("result api creazione spedizione", result)
        //TODOGP pushare la creazione della nuova spedizione?
        yield put({type: SESSION_FETCHING, fetching: false});

        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        // else yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: result.rows});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* _updateSpedizione(action) {

    yield put({type: SESSION_FETCHING, fetching: true});

    try {

        console.log("rest api update spedizione", action.payload.spedizione)
        const result = yield call(Api.spedizioni.update, action.payload.spedizione);

        console.log("result api update spedizione", result)
        yield put({type: UPDATE_SPEDIZIONE, spedizione: action.payload.spedizione});
        // if (lazy === 1) yield put({type: CONTATTI_RESET_LISTA, contacts: result.rows});
        // else yield put({type: CONTATTI_AGGIUNGIA_ALLA_LISTA, contacts: result.rows});
        yield put({type: SESSION_FETCHING, fetching: false});

    } catch (error) {
        yield put({type: SESSION_FETCHING_ERROR, error})
    }
}


export function* spedizioniSaga() {
    yield all([
        takeEvery(SAGA_SPEDIZIONI_SEARCH, searchSpedizioni),
        takeLatest(SAGA_SPEDIZIONI_CREATE, _creaSpedizione),
        takeLatest(SAGA_SPEDIZIONE_UPDATE, _updateSpedizione),
        // takeEvery(SAGA_CORRIERI_GET_SPEDIZIONI, _getSpedizioni),
    ])
}
