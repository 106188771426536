import { connect } from "react-redux";
import React from "react";
import ListExtraListComponent from "../components/ListExtraListComponent";
import {deleteListino, listinoDetails, toggleStarredListino} from "../../redux/actions";



const mapStateToProps = (state, ownProps) => ({
   // listinis: state.listini.listini,
   active: state.listextra.listinoDetails,
   ...state
});

const mapDispatchToProps = (dispatch, ownProps) => ({
   toggleStarredListino: id => dispatch(toggleStarredListino(id)),
   deleteListino: id => dispatch(deleteListino(id)),
   listinoDetails: id => dispatch(listinoDetails(id)),
   // getContacts
});

export default connect(
   mapStateToProps,
   mapDispatchToProps
)(ListExtraListComponent);
