import {ADD_CORRIERE, UPDATE_CORRIERE, DELETE_CORRIERE} from '../../../redux/constants';
import {CORRIERI_AGGIUNGIA_ALLA_LISTA, CORRIERI_GET_LISTA_SPEDIZIONI, CORRIERI_LAZY_LOADING, CORRIERI_RESET_LISTA} from "./corrieri_redux_types";


const corrieri = (state = [], action) => {
    switch (action.type) {
        case CORRIERI_RESET_LISTA:
            return action.corrieri;
        case CORRIERI_AGGIUNGIA_ALLA_LISTA:
            return [...state.concat(action.corrieri)];
        case ADD_CORRIERE:
            return [
                ...state,
                {
                    id: action.contatto.id,
                    firstname: action.contatto.firstname,
                    lastname: action.contatto.lastname,
                    image: action.contatto.image,
                    department: action.contatto.department,
                    company: action.contatto.company,
                    phone: action.contatto.phone,
                    email: action.contatto.email,
                    address: action.contatto.address,
                    notes: action.contatto.notes,
                    frequentlycontacted: false,
                    starred: false,
                    deleted: false
                }
            ];

        case UPDATE_CORRIERE:
            return state.map(corriere => (corriere.id === action.id ? {...corriere, [action.field]: action.value} : corriere));


        case DELETE_CORRIERE:
            return state.map(corriere => (corriere.id === action.id ? {...corriere, deleted: !corriere.deleted} : corriere));

        default:
            return state;
    }
};

const lazy = (state = 1, action) => {
    switch (action.type) {
        case CORRIERI_LAZY_LOADING:
            if (action.reset) return 1;
            return state + 1;//questa rappresenta la pagina
        default:
            return state;
    }

}







export {corrieri, lazy};
