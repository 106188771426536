export const SAGA_LISTINI_EXTRA_SEARCH = "SAGA_LISTINI_EXTRA_SEARCH";
export const SAGA_LISTINI_EXTRA_JSON = "SAGA_LISTINI_EXTRA_JSON";
export const SAGA_LISTINI_EXTRA_ADD = "SAGA_LISTINI_EXTRA_ADD";
export const SAGA_LISTINI_EXTRA_TIPOLOGIE_GET = "SAGA_LISTINI_EXTRA_TIPOLOGIE_GET";
export const SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE = "SAGA_LISTINI_EXTRA_TIPOLOGIE_CREATE";
export const SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE = "SAGA_LISTINI_EXTRA_TIPOLOGIE_UPDATE";
export const SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA = "SAGA_LISTINI_EXTRA_TIPOLOGIE_RIORDINA";


export const LISTINI_EXTRA_LAZY_LOADING = "LISTINI_EXTRA_LAZY_LOADING";
export const LISTINI_EXTRA_RESET_LISTA = "LISTINI_EXTRA_RESET_LISTA";
export const LISTINI_EXTRA_JSON = "LISTINI_EXTRA_JSON";
export const LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA = "LISTINI_EXTRA_AGGIUNGIA_ALLA_LISTA";


