import {
    SESSION_FETCHING, SESSION_FETCHING_ERROR,
    SESSION_LOADING,
    SESSION_LOGGED, SESSION_LOGGED_OUT, SESSION_RESTORED,
} from '../constants/';

const INIT_STATE = {
    user: null,
    token: null,
    loading: true,
    restored: false,
    azienda: null,
    fetching_sessions: 0,
    fetching_error: false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SESSION_FETCHING:
            let sessioni = (action.fetching) ? state.fetching_sessions + 1 : state.fetching_sessions - 1;
            if (sessioni < 0) sessioni = 0;
            return {
                ...state,
                fetching_sessions: sessioni
            };
        case SESSION_FETCHING_ERROR:
            let _sessioni =  state.fetching_sessions - 1;
            if (_sessioni < 0) _sessioni = 0;
            return {
                ...state,
                fetching_sessions: _sessioni,
                fetching_error: action.fetching_error
            };
        case SESSION_LOADING:
            return {
                ...state,
                loading: action.loading
            };
        case SESSION_LOGGED:
            return {
                ...state,
                user: action.user,
                token: action.token
            };
        case SESSION_LOGGED_OUT:
            return {
                ...state,
                user: null,
                token: null
            };
        case SESSION_RESTORED:
            return {
                ...state,
                restored: true,
                azienda: {
                    id: action.data.id_azienda
                }
            };
        default:
            return state;
    }
};
