import {connect} from "react-redux";
import React, {Fragment, useEffect, useState} from "react";
import user1 from "../../../../assets/images/users/1.jpg";
import API from "../../../../rest";
import {NavLink} from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardTitle,
    Col, CustomInput,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Modal,
    ModalHeader,
    Row,
    Table
} from "reactstrap";
import RowAcquistoPack from "../components/rowAcquistoPack";
import AddPackToContact from "../components/addPackToContact";
import PropTypes from "prop-types";
import {updateContatto,deleteContatto, getAcquisti} from "../../redux/action";
import NuovaFattura from "../../../fatture/NuovaFattura/NuovaFattura";
import {setFiltroContattoPerSpedizioni} from "../../../spedizioni/redux/action";


const ContattoDettaglioPreview = ({contatto, setFiltroContattoPerSpedizioni, acquisti, getAcquisti, deleteContatto,updateContatto, ...props}) => {
    const [modalNewFatturaOpened, setModalNewFatturaOpened] = useState(false);

    const [modifica, setModifica] = useState(false);

    const [azienda, setAzienda] = useState(false);
    const [nome, setNome] = useState('');
    const [cognome, setCognome] = useState('');
    const [ragSociale, setRagSociale] = useState('');
    const [piva, setPiva] = useState('');
    const [codfisc, setCodfisc] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [citta, setCitta] = useState('');
    const [indirizzo, setIndirizzo] = useState('');

    //errori
    const [erroreRagSociale, setErroreRagSociale] = useState(false);
    const [errorePiva, setErrorePiva] = useState(false);
    const [erroreNome, setErroreNome] = useState(false);
    const [erroreCognome, setErroreCognome] = useState(false);
    const [erroreCitta, setErroreCitta] = useState(false);
    const [erroreEmail, setErroreEmail] = useState(false);


    const [immagineProfilo, setImmagineProfilo] = useState(user1);
    const [modalOpen, setModalOpen] = useState(false);

    function f(id) {
        let _values = {
            nome,
            cognome
        }
        // props.aggiornaContatto(id, _values);
    }


    const toggleModal = () => {
        setModalOpen(!modalOpen);
    }


    useEffect(() => {
        // if (!fetchedImgs) {
        setImmagineProfilo(user1)

        setModifica(false);
        if (contatto) {
            setAzienda(contatto.azienda);
            setRagSociale(contatto.rag_sociale);
            setNome(contatto.nome);
            setCognome(contatto.cognome);
            setPiva(contatto.piva);
            setCodfisc(contatto.codfisc);
            setEmail(contatto.email);
            setTelefono(contatto.phone);
            setCitta(contatto.citta);
            setIndirizzo(contatto.indirizzo);
        }


        if (contatto && contatto.image && contatto.image != null) {
            API.contatti.getImageProfilo(contatto.id, contatto.image).then((r) => {
                if (r) setImmagineProfilo(r)
            }).catch((e) => {
                // cons
                setImmagineProfilo(user1)
            });
        } else {
        }

        getAcquisti(contatto.id, true)

        // }
    }, [contatto]); // passing an empty array as

    const resetContatto = ()=>{
        if (contatto) {
            setAzienda(contatto.azienda);
            setRagSociale(contatto.rag_sociale);
            setNome(contatto.nome);
            setCognome(contatto.cognome);
            setPiva(contatto.piva);
            setCodfisc(contatto.codfisc);
            setEmail(contatto.email);
            setTelefono(contatto.phone);
            setCitta(contatto.citta);
            setIndirizzo(contatto.indirizzo);
        }

    }

    ////CON QUESTA PROCEDURA PRENDO LO URL DELL'IMMAGINE FACENDOLO RESTITUIRE A STORAGE
    // const [immagineProfilo, setImmagineProfilo] = useState(user1);
    // const fstore = getFirebase();
    // const session = useSelector(state => state.session)
    // if(selectedContacts!==undefined)
    // fstore.storage().ref("aziende/" + session.azienda.id + "/contatti/" + selectedContacts.id + "/")
    // 	.child(selectedContacts.image)
    // 	.getDownloadURL().then((url) => {
    // 	setImmagineProfilo(url);
    // })


    const submit = () => {
        setErroreRagSociale(false);
        setErrorePiva(false);
        setErroreNome(false);
        setErroreCognome(false);
        setErroreCitta(false);
        setErroreEmail(false);

        let errore = false;

        if ((azienda && ragSociale === '')) {
            setErroreRagSociale(true);
            errore = true;
        }
        if ((azienda && piva === '')) {
            setErrorePiva(true);
            errore = true;
        }
        if ((!azienda && nome === '')) {
            setErroreNome(true);
            errore = true;
        }
        if ((!azienda && cognome === '')) {
            setErroreCognome(true);
            errore = true;
        }
        if (citta === '') {
            setErroreCitta(true);
            errore = true;
        }
        if (email === '') {
            setErroreEmail(true);
            errore = true;
        }



        if(!errore){
            //salvo in remoto (action con dispatch dell'update)
            setModifica(false);
            const _contatto= {
                id: contatto.id,
                rag_sociale:azienda?ragSociale:'',
                piva:azienda?piva:'',
                codfisc:!azienda?codfisc:'',
                nome:!azienda?nome:'',
                cognome:!azienda?cognome:'',
                azienda,
                citta,
                indirizzo,
                email,
                phone:telefono,
            }
            updateContatto(_contatto);


        }

    }
    // if(selectedContacts!==undefined && selectedContacts.image!=="") immagineProfilo = selectedContacts.image;


    const nome_completo = `${contatto.rag_sociale} ${contatto.nome} ${contatto.cognome} ${contatto.citta}`;
    const n = nome_completo.replace(/[^a-z0-9áéíóúñü \.,_-]/gim, "").trim().toLowerCase().replace(/[ ]/gim, "-");
    return <Fragment>
        {contatto ? (
            <div className="p-3">


                <div className="d-flex align-items-center p-4 border-bottom">
                    <div className="mr-3">
                        <img src={immagineProfilo} alt="user" className="rounded-circle" width="50" height="50"/>
                    </div>
                    <div>
                        <h5 className="message-title mb-0">{contatto.rag_sociale} {contatto.cognome} {contatto.nome}</h5>
                        <p className="mb-0">{contatto.citta}</p>
                        {!modifica ? <Fragment>
                            <NavLink outline to={`/cliente/${contatto.id}/${n}`}>
                                <Button className="btn" size={"sm"} outline color="success">Dettagli</Button>
                            </NavLink>
                            <Button size={"sm"} onClick={() => {
                                setModifica(true)
                            }} className="btn" outline color="warning">Modifica</Button>
                            {(!contatto.deletedAt) ? <Button size={"sm"} onClick={() => {
                                if (window.confirm('Delete the item?')) deleteContatto(contatto)
                            }} className="btn" outline color="danger">Disattiva</Button> : ''}
                        </Fragment> : <Fragment>
                            <Button size={"sm"} onClick={() => {
                                setModifica(false);
                                resetContatto();
                            }} className="btn" outline color="default">Annulla</Button>
                            <Button size={"sm"} onClick={() => {
                                submit();
                            }} className="btn" outline color="success">Salva</Button>
                        </Fragment>}

                    </div>
                </div>

                {!modifica?<div className={"btn btn-danger "} onClick={() => {
                    setFiltroContattoPerSpedizioni(contatto.id);
                    setModalNewFatturaOpened(true)
                }}>Crea fattura
                </div>:''}


                <div className="details-table px-4 mt-3">

                    {/* rag_sociale */}
                    {(azienda) ? <Row className={"mb-1"}>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={ragSociale}
                                        onChange={(e) => {
                                            setRagSociale(e.target.value);
                                        }}
                                        placeholder="Ragione Sociale"
                                        required
                                    /> </InputGroup> : <h4>{ragSociale}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Ragione Sociale</small>
                                    {erroreRagSociale ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatoria</small> : ''}
                                </p>
                            </FormGroup>

                        </Col>
                    </Row> : ''}

                    {/* congome - nome*/}
                    {(!azienda) ? <Row className={"mb-1"}>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={cognome}
                                        onChange={(e) => {
                                            setCognome(e.target.value);
                                        }}
                                        placeholder="Cognome"
                                        required
                                    /> </InputGroup> : <h4>{cognome}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Cognome</small>
                                    {erroreCognome ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatorio</small> : ''}
                                </p>

                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={nome}
                                        onChange={(e) => {
                                            setNome(e.target.value);
                                        }}
                                        placeholder="Nome"
                                        required
                                    /> </InputGroup> : <h4>{nome}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Nome</small>
                                    {erroreNome ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatorio</small> : ''}
                                </p>
                            </FormGroup>

                        </Col>
                    </Row> : ''}

                    {/* azienda - piva | codfisc */}
                    <Row className={"mb-1"}>
                        <Col md={2}>
                            <FormGroup className="mb-3">
                                <InputGroup>
                                    <CustomInput type="checkbox"
                                                 disabled={(!modifica ? 'disabled' : '')} checked={azienda}
                                                 id="tls" label="Azienda"
                                                 onChange={() => {
                                                     setAzienda(!azienda);
                                                 }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>

                        {(azienda) ? <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={piva}
                                        onChange={(e) => {
                                            setPiva(e.target.value);
                                        }}
                                        placeholder="P.Iva"
                                        required
                                    /> </InputGroup> : <h4>{piva === '' ? '---' : piva}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Partita Iva</small>
                                    {errorePiva ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatoria</small> : ''}
                                </p>

                            </FormGroup>

                        </Col> : ''}
                        {(!azienda) ? <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={codfisc}
                                        onChange={(e) => {
                                            setCodfisc(e.target.value);
                                        }}
                                    /> </InputGroup> : <h4>{codfisc === '' ? '---' : codfisc}</h4>}

                                <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Cod.Fiscale</small>
                            </FormGroup>
                        </Col> : ''}

                    </Row>

                    {/* citta - indirizzo*/}
                    <Row className={"mb-1"}>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={citta}
                                        onChange={(e) => {
                                            setCitta(e.target.value);
                                        }}
                                        placeholder="Citta"
                                        required
                                    /> </InputGroup> : <h4>{citta}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Citta'</small>
                                    {erroreCitta ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatoria</small> : ''}
                                </p>
                            </FormGroup>

                        </Col>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={indirizzo}
                                        onChange={(e) => {
                                            setIndirizzo(e.target.value);
                                        }}
                                    /> </InputGroup> : <h4>{indirizzo === '' ? ' ' : indirizzo}</h4>}

                                <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Indirizzo</small>
                            </FormGroup>

                        </Col>
                    </Row>

                    {/* telefono - email */}
                    <Row className={"mb-1"}>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={telefono}
                                        onChange={(e) => {
                                            setTelefono(e.target.value);
                                        }}
                                        placeholder="Telefono"
                                    /> </InputGroup> : <h4>{telefono === '' ? '---' : telefono}</h4>}
                                <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Telefono</small>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup className="mb-1">
                                {modifica ? <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="fas fa-book"/>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                    /> </InputGroup> : <h4>{email === '' ? '---' : email}</h4>}
                                <p className="text-center ">
                                    <small className="mt-0 mb-2 badge badge-default badge-dark text-white float-left">Email</small>
                                    {erroreEmail ? <small className="mt-0 mb-2 badge badge-default badge-danger text-white ">Compila il campo!</small> : ''}
                                    {modifica ? <small className="mt-0 mb-2 badge badge-default badge-light text-dark float-right">Obbligatorio</small> : ''}
                                </p>

                            </FormGroup>
                        </Col>
                    </Row>


                </div>



                {!modifica?<Card>
                    <CardBody>
                        <div className="d-md-flex align-items-center">
                            <div>
                                <CardTitle>Pacchetti Attivi</CardTitle>
                                {/*<CardSubtitle>Post</CardSubtitle>*/}
                            </div>
                            <div className="ml-auto d-flex no-block align-items-center">
                                <div className="dl">
                                    <Button className="btn" outline color="primary" size="sm" onClick={toggleModal}>
                                        Aggiungi
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <Table className="no-wrap v-middle" responsive>
                            <thead>
                            <tr className="border-0">
                                <th className="border-0">Tipo</th>
                                <th className="border-0">Data</th>
                                <th className="border-0">Status</th>
                                {/*<th className="border-0">Budget</th>*/}
                            </tr>
                            </thead>
                            <tbody>
                            {acquisti.map(o => <RowAcquistoPack key={o.id} pack={o}/>)}

                            </tbody>
                        </Table>
                    </CardBody>
                </Card>:''}


                <Modal isOpen={modalOpen} toggle={toggleModal} className={""} size="lg" fade={true}>
                    <ModalHeader toggle={toggleModal}>Aggiungi Pack</ModalHeader>
                    <AddPackToContact contatto={contatto}/>
                </Modal>

                <Modal isOpen={modalNewFatturaOpened} toggle={() => {
                    setFiltroContattoPerSpedizioni(null);
                    setModalNewFatturaOpened(!modalNewFatturaOpened);
                }} size="lg">
                    <ModalHeader toggle={() => {
                        setFiltroContattoPerSpedizioni(null);
                        setModalNewFatturaOpened(!modalNewFatturaOpened);
                    }}>Crea Fattura</ModalHeader>
                    <NuovaFattura contatto={contatto}/>
                </Modal>


            </div>
        ) : (
            <div className="d-flex h-100 p-4 flex-column align-items-center justify-content-center">
                <i className="far fa-address-card display-5"></i>
                <h4 className="mt-2">Clicca su un contatto per visualizzarne i dettagli.</h4>
            </div>
        )}
    </Fragment>
        ;
}

ContattoDettaglioPreview.prototype = {
    contatto: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            image: PropTypes.string,
            codfisc: PropTypes.string,
            citta: PropTypes.string,
            phone: PropTypes.number.isRequired,
            email: PropTypes.string.isRequired,
            address: PropTypes.string,
            notes: PropTypes.string,
            frequentlycontacted: PropTypes.bool.isRequired,
            starred: PropTypes.bool.isRequired,
            deleted: PropTypes.bool.isRequired
        }).isRequired
    ).isRequired
};


const mapStateToProps = (state, ownProps) => ({
    acquisti: state.contactReducer.acquisti
});

const mapDispatchToProps = {
    // onEditClick: () => dispatch(setEditContactFlag()),
    getAcquisti,
    deleteContatto,
    setFiltroContattoPerSpedizioni,
    updateContatto
    // onChange: (id, field, value) => dispatch(updateContact(id, field, value))
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ContattoDettaglioPreview);
